// ** hooks **
import { AxiosRequestConfig } from "axios";
import { useAxiosGet, useAxiosPost, useAxiosPut } from "hooks/useaxios";

const COST_CALCULATOR_API_BASE_PATH = "/costcalculator";


// ---------------------------- Industry -----------------------

export const useIndustryListForFormAPI = () => {
  // ** Custom Hooks **
  const [callApi, { isLoading, isError, isSuccess }] = useAxiosGet();

  const getIndustryListApi = async (data: object,) => {
    return callApi(`/costcalculator/industry/get_all`, data);
  };

  return { getIndustryListApi, isLoading, isError, isSuccess };
};


// ---------------------------- Market -----------------------

export const useMarketListForFormAPI = () => {
  // ** Custom Hooks **
  const [callApi, { isLoading, isError, isSuccess }] = useAxiosGet();

  const getMarketListApi = async () => {
    return callApi(`/costcalculator/market/get_all`);
  };

  return { getMarketListApi, isLoading, isError, isSuccess };
};

// ---------------------------- Services -----------------------

export const useServicesListForFormAPI = () => {
  // ** Custom Hooks **
  const [callApi, { isLoading, isError, isSuccess }] = useAxiosGet();

  const getServicesListApi = async (data: object,) => {
    return callApi(`/costcalculator/services/get_all`, data);
  };

  return { getServicesListApi, isLoading, isError, isSuccess };
};


// ------------------------- Form Services APIs ----------------------------


export const useAddFormServicesAPI = () => {
  const [callApi, { isLoading, isError, isSuccess }] = useAxiosPost();
  const addFormServicesAPI = async (
    data: object,
    config: AxiosRequestConfig<object> = {}
  ) => {
    return callApi(`/costcalculator/formservice/add`, data, config);
  };
  return { addFormServicesAPI, isLoading, isError, isSuccess };
};

export const useBulkUpdateFormServicesAPI = () => {
  const [callApi, { isLoading, isError, isSuccess }] = useAxiosPost();
  const updateBulkFormServices = async (
    data: object,
    config: AxiosRequestConfig<object> = {}
  ) => {
    return callApi(`/costcalculator/formservice/bulk-update`, data, config);
  };
  return { updateBulkFormServices, isLoading, isError, isSuccess };
};

export const useUpdateFormServicesAPI = () => {
  const [callApi, { isLoading, isError, isSuccess }] = useAxiosPut();
    const updateFormServicesAPI = async (
      data: object,
      id: number | string,
      config: AxiosRequestConfig<object> = {}
    ) => {
      return callApi(`/costcalculator/formservice/update/${id}`, data, config);
    };
  return { updateFormServicesAPI, isLoading, isError, isSuccess };
};

export const useDeleteFormServicesAPI = () => {
  const [callApi, { isLoading, isError, isSuccess }] = useAxiosPut();
  const deleteFormServicesApi = async (id: number | string) => {
    const response = await callApi(`/costcalculator/formservice/delete/${id}`,{});
    return response;
  };
  return { deleteFormServicesApi, isLoading, isError, isSuccess };
};



// --------------------- Questions -----------------------------------

export const useGetQuestionAPI = () => {
  const [callApi, { isLoading, isError, isSuccess }] = useAxiosGet();

  const getQuestionAPI = async (id: number | string) => {
    return callApi(`/costcalculator/questions/get/${id}`);
  };
  
  return { getQuestionAPI, isLoading, isError, isSuccess };
};


export const useAddQuestionsAPI = () => {
  const [callApi, { isLoading, isError, isSuccess }] = useAxiosPost();
  const addQuestionsApi = async (
    data: object,
    config: AxiosRequestConfig<object> = {}
  ) => {
    return callApi(`/costcalculator/questions/add`, data, config);
  };
  return { addQuestionsApi, isLoading, isError, isSuccess };
};

export const useUpdateQuestionAPI = () => {
  const [callApi, { isLoading, isError, isSuccess }] = useAxiosPut();
    const updateQuestionsAPI = async (
      data: object,
      id: number | string,
      config: AxiosRequestConfig<object> = {}
    ) => {
      return callApi(`/costcalculator/questions/update/${id}`, data, config);
    };
  return { updateQuestionsAPI, isLoading, isError, isSuccess };
};

export const useDeleteQuestionAPI = () => {
  const [callApi, { isLoading, isError, isSuccess }] = useAxiosPut();
  const deleteQuestionsApi = async (id: number | string) => {
    const response = await callApi(`/costcalculator/questions/delete/${id}`,{});
    return response;
  };
  return { deleteQuestionsApi, isLoading, isError, isSuccess };
};

export const useQuestionsMoveAPI = () => {
  const [callApi, { isLoading, isError, isSuccess }] = useAxiosPost();
  const questionsMoveApi = async (
    data: object,
    config: AxiosRequestConfig<object> = {}
  ) => {
    return callApi(`/costcalculator/question-order/swap`, data, config);
  };
  return { questionsMoveApi, isLoading, isError, isSuccess };
};

export const useGetUserInputQuestionsAPI = () => {
  const [callApi, { isLoading, isError, isSuccess }] = useAxiosGet();

  const getUserInputQuestionsAPI = async (
    data: object,
    config: AxiosRequestConfig<object> = {}
  ) => {
    return callApi(`/costcalculator/userinput-questions/get/`, data);
  };
  return { getUserInputQuestionsAPI, isLoading, isError, isSuccess };
};


// --------------------- Answers ( Options )-----------------------------------
export const useAddOptionsAPI = () => {
  const [callApi, { isLoading, isError, isSuccess }] = useAxiosPost();
  const addOptionsApi = async (
    data: object,
    config: AxiosRequestConfig<object> = {}
  ) => {
    return callApi(`/costcalculator/options/add`, data, config);
  };
  return { addOptionsApi, isLoading, isError, isSuccess };
};

export const useUpdateOptionsAPI = () => {
  const [callApi, { isLoading, isError, isSuccess }] = useAxiosPut();
    const updateOptionsAPI = async (
      data: object,
      id: number | string,
      config: AxiosRequestConfig<object> = {}
    ) => {
      return callApi(`/costcalculator/options/update/${id}`, data, config);
    };
  return { updateOptionsAPI, isLoading, isError, isSuccess };
};

export const useDeleteOptionsAPI = () => {
  const [callApi, { isLoading, isError, isSuccess }] = useAxiosPut();
  const deleteOptionsApi = async (id: number | string) => {
    const response = await callApi(`/costcalculator/options/delete/${id}`,{});
    return response;
  };
  return { deleteOptionsApi, isLoading, isError, isSuccess };
};


// -------------------------------- Forms API -----------------------------------

export const useGetCostCalculatorFormsListAPI = () => {
  // ** Custom Hooks **
  const [callApi, { isLoading, isError, isSuccess }] = useAxiosGet();
  const getCostCalculatorFormListApi = async (
    data: object,
  ) => {
    return callApi(`/costcalculator/forms/get_all`, data);
  };
  return { getCostCalculatorFormListApi, isLoading, isError, isSuccess };
};


export const useAddCostFormAPI = () => {
  const [callApi, { isLoading, isError, isSuccess }] = useAxiosPost();
  const addCostFormAPI = async (
    data: object,
    config: AxiosRequestConfig<object> = {}
  ) => {
    return callApi(`/costcalculator/forms/add`, data, config);
  };
  return { addCostFormAPI, isLoading, isError, isSuccess };
};

export const useUpdateCostFormAPI = () => {
  const [callApi, { isLoading, isError, isSuccess }] = useAxiosPut();
    const updateCostFormAPI = async (
      data: object,
      id: number | string,
      config: AxiosRequestConfig<object> = {}
    ) => {
      return callApi(`/costcalculator/forms/update/${id}`, data, config);
    };
  return { updateCostFormAPI, isLoading, isError, isSuccess };
};

export const useDeleteCostFormAPI = () => {
  const [callApi, { isLoading, isError, isSuccess }] = useAxiosPut();
  const deleteCostFormApi = async (id: number | string) => {
    const response = await callApi(`/costcalculator/forms/delete/${id}`,{});
    return response;
  };
  return { deleteCostFormApi, isLoading, isError, isSuccess };
};


export const useGetCostCalculatorFormIdAPI = () => {
  // ** Custom Hooks **
  const [callApi, { isLoading, isError, isSuccess }] = useAxiosGet();

  const getCostCalculatorFormIdApi = async (id: number | string) => {
    return callApi(`/costcalculator/forms/get/${id}`);
  };

  return { getCostCalculatorFormIdApi, isLoading, isError, isSuccess };
};


export const useCopyTemplateToCostFormAPI = () => {
  const [callApi, { isLoading, isError, isSuccess }] = useAxiosPost();
  const copyTemplateToCostFormAPI = async (
    data: object,
    config: AxiosRequestConfig<object> = {}
  ) => {
    return callApi(`/costcalculator/form/copy`, data, config);
  };
  return { copyTemplateToCostFormAPI, isLoading, isError, isSuccess };
};



// --------------------------------Public Forms API -----------------------------------

// export const useGetPublicFormAPI = () => {
//   // ** Custom Hooks **
//   const [callApi, { isLoading, isError, isSuccess }] = useAxiosGet();

//   const getPublicFormApi = async (id: number | string) => {
//     return callApi(`/costcalculator/forms/public/get/${id}`);
//   };
  
//   return { getPublicFormApi, isLoading, isError, isSuccess };
// };

export const useGetPublicFormAPI = () => {
  // ** Custom Hooks **
  const [callApi, { isLoading, isError, isSuccess }] = useAxiosGet();

  const getPublicFormApi = async (code: string) => {
    return callApi(`/costcalculator/forms/public/${code}`);
  };
  
  return { getPublicFormApi, isLoading, isError, isSuccess };
};


// --------------------------------Public Forms Response API -----------------------------------

export const useAddUserCostFormResponseAPI = () => {
  const [callApi, { isLoading, isError, isSuccess }] = useAxiosPost();
  const addUserCostFormAPI = async (
    data: object,
    config: AxiosRequestConfig<object> = {}
  ) => {
    return callApi(`/costcalculator/user_response/add`, data, config);
  };
  return { addUserCostFormAPI, isLoading, isError, isSuccess };
};


export const useGetUserResponseFormAPI = () => {
  const [callApi, { isLoading, isError, isSuccess }] = useAxiosGet();

  const getUserResponseFormAPI = async (id: number | string) => {
    return callApi(`/costcalculator/user_response/get/${id}`);
  };
  
  return { getUserResponseFormAPI, isLoading, isError, isSuccess };
};

export const useUpdateUserResponseAPI = () => {
  const [callApi, { isLoading, isError, isSuccess }] = useAxiosPut();
    const updateUserResponseAPI = async (
      data: object,
      id: number | string,
      config: AxiosRequestConfig<object> = {}
    ) => {
      return callApi(`/costcalculator/user_response/update/${id}`, data, config);
    };
  return { updateUserResponseAPI, isLoading, isError, isSuccess };
};

// ------------- Public Api For User Response Get ----------

export const useGetUserResponsePublicFormAPI = () => {
  const [callApi, { isLoading, isError, isSuccess }] = useAxiosGet();

  const getUserResponsePublicFormAPI = async (code: string) => {
    return callApi(`/costcalculator/user_response/get/${code}`);
  };
  
  return { getUserResponsePublicFormAPI, isLoading, isError, isSuccess };
};


// -------------------------------- User Forms Question Options --------------------------

export const useUpdateUserQuestionAnswerAPI = () => {
  const [callApi, { isLoading, isError, isSuccess }] = useAxiosPut();
    const updateUserQuestionAnswerAPI = async (
      data: object,
      id: number | string,
      config: AxiosRequestConfig<object> = {}
    ) => {
      return callApi(`/costcalculator/user_question_answer/update/${id}`, data, config);
    };
  return { updateUserQuestionAnswerAPI, isLoading, isError, isSuccess };
};

export const useSendUserResponseEmailAPI = () => {
  const [callApi, { isLoading, isError, isSuccess }] = useAxiosPost();
  const sendUserResponseEmailAPI = async (
    data: object,
    config: AxiosRequestConfig<object> = {}
  ) => {
    return callApi(`/costcalculator/user_response/send-email`, data, config);
  };
  return { sendUserResponseEmailAPI, isLoading, isError, isSuccess };
};

// ------------------------------ Update theme Settings -------------------------------------------

export const useUpdateDesignOptionsAPI = () => {
  const [callApi, { isLoading, isError, isSuccess }] = useAxiosPut();
    const updateDesignOptionsAPI = async (
      data: object,
      config: AxiosRequestConfig<object> = {}
    ) => {
      return callApi(`/costcalculator/theme-settings`, data, config);
    };
  return { updateDesignOptionsAPI, isLoading, isError, isSuccess };
};


export const useGetDesignOptionsAPI = () => {
  const [callApi, { isLoading, isError, isSuccess }] = useAxiosGet();

  const getDesignOptionsAPI = async () => {
    return callApi(`/costcalculator/theme-settings/get`);
  };
  
  return { getDesignOptionsAPI, isLoading, isError, isSuccess };
};
