import React, { useEffect, useState } from "react";
import * as Icon from "react-feather";
import OptionCard from "../OptionCard";
import { costFormDataType, formServicesDataType, optionsDataType, questionsDataType, servicesDataType } from "modules/CostCalculator/types";
import SelectField from "components/FormField/common/SelectField";
import InputField from "components/FormField/common/inputField";
import { useAddOptionsAPI, useDeleteOptionsAPI, useQuestionsMoveAPI, useUpdateQuestionAPI } from "modules/CostCalculator/services";
import AlertModal from "components/modal/AlertModal";
import { isOpenStateType } from "../QuestionSetting/types";
import QuestionSettingForm from "../QuestionSetting/QuestionSettingForm";

interface QuestionCardProps {
    index?: number;
    questionList?: questionsDataType[];
    questionItem?: questionsDataType;
    selectedFormData?: costFormDataType | null;
    setSelectedFormData?: any;
    questionDeleteHandler?: any;
    handleMoveQuestion?: any;
}


const QuestionsCard: React.FC<QuestionCardProps> = ({
    index,
    questionList,
    questionItem,
    selectedFormData,
    setSelectedFormData,
    questionDeleteHandler,
    handleMoveQuestion
}) => {

    const addOptionApi = useAddOptionsAPI();
    const deleteOptionsApi = useDeleteOptionsAPI();

    const updateQuestionsAPI = useUpdateQuestionAPI();
    const moveQuestionsAPI = useQuestionsMoveAPI();
    
    const [addNewOption, setAddNewOption] = useState<boolean>(false);
    
    const [editQuestion, setEditQuestion] = useState<boolean>(false);
    const [alertModelVisible, setAlertModelVisible] = useState<boolean>(false);
    
    // ---------- Questions Forms Fields --------------------
    const [questionName, setQuestionName] = useState<string | any>(questionItem?.question_name);
    const [isOpen, setIsOpen] = useState<isOpenStateType>({
        open: false,
        id: null,
        isDefault: false,
        viewOnly: true,
    });

    // ---------- Option Forms Filds -------------------- 
    const [optionName, setOptionName] = useState<string>("");
    const [selectOptionName, setSelectOptionName] = useState<any>({label: "Range", value: "Range"});
    const [minValue, setMinValue] = useState<string>();
    const [maxValue, setMaxValue] = useState<string>();
    const [otherValue, setOtherValue] = useState<number>(0);
    const [singularValue, setSingularValue] = useState<string | null | undefined>(questionItem?.singular_value);
    const [pluralValue, setPluralValue] = useState<string | null | undefined>(questionItem?.plural_value);

    const optionSelectionList = [
        {label: "Range", value: "Range"},
        // {label: "Percentage", value: "Percentage"},
        {label: "Cost", value: "Cost"},
    ]

    // ============================== Options Functionality =========================================

    const addOption = (questionId: Number | any, newOption:any) => {
        setSelectedFormData((selectedFormData: costFormDataType) => {
            return {
                ...selectedFormData,
                services: selectedFormData.services.map((service: any) => ({
                    ...service,
                    questions: service?.questions.map((question:any) =>
                        question.id === questionId
                            ? { ...question, options: [...question.options, newOption] }
                            : question
                    )
                }))
            };
        });
    };

    const removeOption = (questionId: number, optionId: number) => {
        setSelectedFormData((selectedFormData: costFormDataType) => {
            return {
                ...selectedFormData,
                services: selectedFormData.services.map((service:any) => ({
                    ...service,
                    questions: service.questions.map((question: any) =>
                        question.id === questionId
                            ? {
                                  ...question,
                                  options: question.options.filter(
                                      (option: any) => option.id !== optionId
                                  )
                              }
                            : question
                    )
                }))
            };
        });
    };

    const optionSubmitHandle = async () => {
        const formData = new FormData();
        if (questionItem?.id) {
            if (optionName) {
                formData.append('option_name', optionName)
            }
            if (selectOptionName) {
                formData.append('question_value_type', selectOptionName?.value)
                if (selectOptionName?.value === "Range") {
                    minValue && formData.append("min_value", minValue)
                    maxValue && formData.append("max_value", maxValue)
                } else {
                    otherValue && formData.append("other_value", String(otherValue))
                }
            }
            if (optionName !== "") {
                formData.append('question_id', String(questionItem.id))
                const { data, error } = await addOptionApi.addOptionsApi(formData);
                addOption(questionItem?.id, data)
                setAddNewOption(false)
                setOptionName("")
                setMinValue("")
                setMaxValue("")
                setOtherValue(0)
            }
        } 
    }

    const optionDeleteHandler = async (option:optionsDataType | any) => {        
        if (option?.id) {
            const { data, error } = await deleteOptionsApi.deleteOptionsApi(option?.id);
            removeOption(option?.question_id, option?.id)
        }
    }

    // ============================== Questions Functionality =========================================

    const updateQuestion = (questionId: number | any, updatedData: optionsDataType) => {
            setSelectedFormData((selectedFormData: costFormDataType) => {
                return {
                    ...selectedFormData,
                    services: selectedFormData.services.map((service:any) => ({
                        ...service,
                        questions: service.questions.map((question:any) =>
                            question.id === questionId
                                ? { ...question, ...updatedData } // Update question data
                                : question
                        )
                    }))
                };
            });
        };
        
    
    const updateQuestionHandler = async (questionItem: questionsDataType | any) => {
        const formData = new FormData();
        if (questionItem?.id) {
            if (questionName !== questionItem?.question_name) {
                formData.append('question_name', questionName)
            }
            if (singularValue !== questionItem?.singular_value) {
                formData.append('singular_value', String(singularValue))
            }
            if (pluralValue !== questionItem?.plural_value) {
                formData.append('plural_value', String(pluralValue))
            }
            console.log(formData, "--------Form Data---------------")
            const { data, error } = await updateQuestionsAPI.updateQuestionsAPI(
                formData,
                String(questionItem?.id)
            );
            updateQuestion(questionItem?.id, data)
            setEditQuestion(false)
        } 
    }

    const alertSubmit = () => {
        questionDeleteHandler(questionItem)
        setAlertModelVisible(false)
    }

    return (
        <>
            <div className="w-full rounded border-[2px] border-[#6558F5] flex items-center">
                <div className="border-r-[2px] border-[#6558f56e] h-full flex flex-col justify-between">
                    {index !== 0 ? (
                        <>
                            <button 
                                className="border-0 shrink-0 py-3 w-10 flex flex-col items-center justify-center cursor-pointer"
                                onClick={() => handleMoveQuestion(questionItem?.id, "up")}
                            >
                                <Icon.ChevronUp className="size-4" />
                            </button>
                        </>
                    ) : (<div className="w-10"></div>) }
                    {questionList && (index !== questionList?.length - 1) ? (
                    <button 
                        className="border-0 shrink-0 py-3 w-10 flex flex-col items-center justify-center cursor-pointer"
                        onClick={() => handleMoveQuestion(questionItem?.id, "down")}
                    >
                        <Icon.ChevronDown className="size-4" />
                    </button>
                    ) : (<div className="w-10"></div>) }
                </div>
                <div className="w-full flex flex-col p-2 gap-3 max-w-[calc(100%_-_41px)]">
                    <div className="w-full flex items-center justify-between gap-2 px-2 border-b-[2px] border-[#6558f5a6] pb-2">
                        <div className="w-full text-base font-medium max-w-[calc(100%_-_102px)]">
                            
                            <InputField
                                fieldWrapperClassName="mb-0"
                                wrapperClass={`w-full shrink-0 ${editQuestion ? "" : "no-edit"}`}
                                name="question_name"
                                value={questionName}
                                onChange={(e) => setQuestionName(e.target.value)}
                                // label="Option Name"
                                placeholder="Question Name"
                                // type="number"
                                disabled={!editQuestion}
                            />
                        </div>
                        <div className="flex gap-2">
                        {editQuestion ? (
                            <>
                                <button
                                    title="Save"
                                    className="size-8 flex items-center justify-center shrink-0 border border-indigo-700 text-white bg-indigo-600 rounded"
                                    onClick={() => updateQuestionHandler(questionItem)}
                                >
                                    <Icon.Save className="size-4" />
                                </button>
                            </>
                        ) : (
                            <button
                                title="Edit"
                                className="border border-gray-500 flex hover:bg-indigo-600 hover:border-indigo-700 hover:text-white items-center justify-center rounded shrink-0 size-8 text-gray-500"
                                onClick={() => setEditQuestion(true)}
                            >
                                <Icon.Edit className="size-4" />
                            </button>    
                        )}
                        <button
                            title="Setting"
                            className="border border-gray-500 flex hover:bg-indigo-600 hover:border-indigo-700 hover:text-white items-center justify-center rounded shrink-0 size-8 text-gray-500"
                            onClick={() =>
                                setIsOpen({
                                  open: true,
                                  id: questionItem?.id,
                                  isDefault: false,
                                  viewOnly: false,
                                })
                              }
                        >
                            <Icon.Settings className="size-4" />
                        </button>
                        <button
                            title="Delete"
                            className="size-8 flex items-center justify-center shrink-0 bg-[#ee5555] text-white rounded"
                            // onClick={() => questionDeleteHandler(questionItem)}
                            onClick={() => setAlertModelVisible(true)}
                        >
                            <svg
                                className="size-4"
                                width="24"
                                height="24"
                                viewBox="0 0 15 15"
                                fill="currentColor"
                            >
                                <path d="M2.64 1.27 7.5 6.13l4.84-4.84A.92.92 0 0 1 13 1a1 1 0 0 1 1 1 .9.9 0 0 1-.27.66L8.84 7.5l4.89 4.89A.9.9 0 0 1 14 13a1 1 0 0 1-1 1 .92.92 0 0 1-.69-.27L7.5 8.87l-4.85 4.85A.92.92 0 0 1 2 14a1 1 0 0 1-1-1 .9.9 0 0 1 .27-.66L6.16 7.5 1.27 2.61A.9.9 0 0 1 1 2a1 1 0 0 1 1-1c.24.003.47.1.64.27" />
                            </svg>
                        </button>
                        </div>
                    </div>
                    {questionItem?.is_number_field ? (
                        <>
                        <div className="w-full flex flex-col gap-2">
                            <span className="font-medium text-sm">Number</span>
                            <div className="text-gray-600 px-2 text-sm">Add a label to describe the number, which will appear in the user's estimate, e.g. sqft, room, etc.</div>
                            <div className="w-full px-2 py-2 gap-2 xl:gap-4 rounded flex items-center xl:items-start">
                                <div className="w-full flex xl:flex-col flex-row gap-20 items-center xl:items-stretch max-w-[calc(100%_-_88px)]">
                                    <div className="w-full flex font-medium text-sm items-center">
                                        <div className="font-medium text-sm pr-2">
                                            Singular:
                                        </div>
                                        <InputField
                                            fieldWrapperClassName="mb-0"
                                            wrapperClass={`w-full shrink-0 ${editQuestion ? "" : "no-edit"}`}
                                            name="singularValue"
                                            value={singularValue}
                                            onChange={(e) => setSingularValue(e.target.value)}
                                            placeholder="Type a unit label in singular"
                                            type="text"
                                            disabled={!editQuestion}
                                        />
                                    </div>
                                    <div className="w-full flex font-medium text-sm items-center">
                                        <div className="font-medium text-sm pr-2">
                                            Plural:
                                        </div>
                                        <InputField
                                            fieldWrapperClassName="mb-0"
                                            wrapperClass={`w-full shrink-0 ${editQuestion ? "" : "no-edit"}`}
                                            name="pluralValue"
                                            value={pluralValue}
                                            onChange={(e) => setPluralValue(e.target.value)}
                                            placeholder="Type a unit label in plural"
                                            type="text"
                                            disabled={!editQuestion}
                                        />
                                    </div>
                                    {/* <div className="w-full flex font-medium text-sm items-center">
                                        {editQuestion ? (
                                            <>
                                                <button
                                                    title="Save"
                                                    className="size-8 flex items-center justify-center shrink-0 border border-indigo-700 text-white bg-indigo-600 rounded"
                                                    onClick={() => updateQuestionHandler(questionItem)}
                                                >
                                                    <Icon.Save className="size-4" />
                                                </button>
                                            </>
                                        ) : (
                                            <button
                                                title="Edit"
                                                className="border border-gray-500 flex hover:bg-indigo-600 hover:border-indigo-700 hover:text-white items-center justify-center rounded shrink-0 size-8 text-gray-500"
                                                onClick={() => setEditQuestion(true)}
                                            >
                                                <Icon.Edit className="size-4" />
                                            </button>    
                                        )}
                                    </div> */}
                                </div>
                                
                            </div>
                        </div>
                        </>
                    ) : (
                        <>
                    <div className="w-full flex flex-col gap-2">
                        <span className="font-medium text-sm">Answers</span>
                        {questionItem?.options && questionItem?.options?.map((option: optionsDataType, index: number) => {
                            return (
                                <OptionCard 
                                    option={option}
                                    selectedFormData={selectedFormData}
                                    setSelectedFormData={setSelectedFormData}
                                    optionDeleteHandler={optionDeleteHandler}
                                />
                            )
                        })}
                    </div>
                    {addNewOption && (
                        <>
                            <div className="w-full flex flex-col gap-2">
                                <div className="w-full border-[2px] border-[#6558f531] px-2 py-2 gap-2 xl:gap-4 rounded flex items-center xl:items-start">
                                    <div className="w-full flex xl:flex-col flex-row gap-2 items-center xl:items-stretch max-w-[calc(100%_-_88px)]">
                                        <div className="w-full font-medium text-sm">
                                            <InputField
                                                fieldWrapperClassName="mb-0"
                                                wrapperClass="w-full shrink-0"
                                                name="option_name"
                                                value={optionName}
                                                onChange={(e) => setOptionName(e.target.value)}
                                                // label="Option Name"
                                                placeholder="Option Name"
                                                // type="number"
                                            />
                                        </div>
                                        <div className="w-fit flex gap-2">
                                            <SelectField
                                                name="defaultValSelect"
                                                fromGroupClass="!w-[150px] shrink-0 !mb-0 cost-select"
                                                placeholder="Select Value Type"
                                                options={optionSelectionList}
                                                value={selectOptionName}
                                                onChange={(e: any) => {
                                                    setSelectOptionName(e);
                                                }}
                                                // setInputValue={}
                                            />
                                            {selectOptionName && selectOptionName?.value === "Range" ? (
                                                <>
                                                <div className="w-fit flex gap-1 items-center">
                                                    <span className="font-medium text-sm">
                                                        Min
                                                    </span>
                                                    <InputField
                                                        fieldWrapperClassName="mb-0"
                                                        wrapperClass="w-[80px] shrink-0"
                                                        className="no-spinner"
                                                        name="min_value"
                                                        value={minValue}
                                                        onChange={(e) => setMinValue(e.target.value)}
                                                        placeholder="$0"
                                                        type="number"
                                                        onKeyDown={(e) => {
                                                            if (!/[0-9]/.test(e.key) && e.key !== "Backspace") {
                                                                e.preventDefault();
                                                            }
                                                        }}
                                                    />
                                                </div>
                                                <div className="w-fit flex gap-1 items-center">
                                                    <span className="font-medium text-sm">
                                                        Max
                                                    </span>
                                                    <InputField
                                                        fieldWrapperClassName="mb-0"
                                                        wrapperClass="w-[80px] shrink-0"
                                                        className="no-spinner"
                                                        name="max_value"
                                                        value={maxValue}
                                                        onChange={(e) => setMaxValue(e.target.value)}
                                                        placeholder="$100"
                                                        type="number"
                                                        onKeyDown={(e) => {
                                                            if (!/[0-9]/.test(e.key) && e.key !== "Backspace") {
                                                                e.preventDefault();
                                                            }
                                                        }}
                                                    />
                                                </div>
                                                </>
                                            ) : (
                                                <>
                                                <div className="w-fit flex gap-1 items-center">
                                                    <span className="font-medium text-sm">
                                                        Other
                                                    </span>
                                                    <InputField
                                                        fieldWrapperClassName="mb-0"
                                                        wrapperClass="w-[80px] shrink-0"
                                                        className="no-spinner"
                                                        name="other_value"
                                                        value={otherValue}
                                                        onChange={(e:any) => setOtherValue(e.target.value)}
                                                        placeholder="$100"
                                                        type="number"
                                                        onKeyDown={(e) => {
                                                            if (!/[0-9]/.test(e.key) && e.key !== "Backspace") {
                                                              e.preventDefault();
                                                            }
                                                          }}
                                                    />
                                                </div>
                                                </>
                                            )}
                                            
                                            
                                        </div>
                                    </div>
                                    <div className="w-fit flex items-center gap-2 xl:mt-1">
                                        <button
                                            title="Save"
                                            className="size-8 flex items-center justify-center shrink-0 border border-indigo-700 text-white bg-indigo-600 rounded"
                                            onClick={optionSubmitHandle}
                                        >
                                            <Icon.Save className="size-4" />
                                        </button>
                                        <button
                                            title="Delete"
                                            className="size-8 flex items-center justify-center shrink-0 bg-[#ee5555] text-white rounded"
                                            onClick={() => setAddNewOption(false)}
                                        >
                                            <svg
                                                className="size-4"
                                                width="24"
                                                height="24"
                                                viewBox="0 0 15 15"
                                                fill="currentColor"
                                            >
                                                <path d="M2.64 1.27 7.5 6.13l4.84-4.84A.92.92 0 0 1 13 1a1 1 0 0 1 1 1 .9.9 0 0 1-.27.66L8.84 7.5l4.89 4.89A.9.9 0 0 1 14 13a1 1 0 0 1-1 1 .92.92 0 0 1-.69-.27L7.5 8.87l-4.85 4.85A.92.92 0 0 1 2 14a1 1 0 0 1-1-1 .9.9 0 0 1 .27-.66L6.16 7.5 1.27 2.61A.9.9 0 0 1 1 2a1 1 0 0 1 1-1c.24.003.47.1.64.27" />
                                            </svg>
                                        </button>
                                    </div>
                                </div>
                            </div> 
                        </>
                    )}
                    <button
                        title=""
                        className="h-10 font-medium text-base w-full flex items-center justify-center shrink-0 border-[1px] border-[#6558f5a6] text-gray-500 rounded"
                        onClick={() => setAddNewOption(true)}
                    >
                        <Icon.Plus className="size-4" />
                        Add Option
                    </button>
                        </>
                    )} 
                </div>
            </div>

            <AlertModal
                visible={alertModelVisible}
                showCrossIcon
                onSubmit={alertSubmit}
                onCancel={() => setAlertModelVisible(false)}
                onClose={() => setAlertModelVisible(false)}
                submitButtonText="Yes"
                // secondaryLoading={updateServicesApi?.isLoading}
            >
                <p className="text-md mb-3 flex ">
                    <span>
                    Are you sure you want to delete this question ?
                    </span>
                </p>
            </AlertModal>

            <QuestionSettingForm
                isOpen={isOpen}
                setIsOpen={setIsOpen}
                selectedFormData={selectedFormData}
                setSelectedFormData={setSelectedFormData}
            />
        </>
    )
}

export default QuestionsCard