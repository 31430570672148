import React, { useEffect, useState } from "react";

import * as Icon from "react-feather";

import { useDeleteCostFormAPI, useGetCostCalculatorFormsListAPI } from "../../services";
import FormCard from "./components/FormCard";
import { useDispatch, useSelector } from "react-redux";
import { setCurrentFormType } from "redux/slices/formSlice";
import CostForm from "../CostForm";
import { costFormDataType } from "modules/CostCalculator/types";
import UserResponseListPage from "../UserResponse/components/UserResponseListPage";
import Tab from "components/Tab";
import FormTemplate from "./components/FormTemplate";
import { getAuth } from "redux/slices/authSlice";


const CostCalculatorDashboard = () => {

  const dispatch = useDispatch();
  const { user } = useSelector(getAuth);
  const is_superAdmin = user?.is_super_administrator;

  const { getCostCalculatorFormListApi, isLoading } = useGetCostCalculatorFormsListAPI();
  const deleteCostFormApi = useDeleteCostFormAPI();

  const [isFormOpen, setIsFormOpen] = useState<boolean>(false);
  const [costFormData, setCostFormData] = useState<costFormDataType[]>([]);
  const [templatesData, setTemplatesData] = useState<costFormDataType[]>([]);
  const [selectedFormData, setSelectedFormData] = useState<costFormDataType | null>(null);
  const [currentTab, setCurrentTab] = useState<string>("myforms");

  const tabs: any[] = [
    { name: "My Forms", value: "myforms", disabled: false },
    { name: "Templates", value: "templates", disabled: false},
  ];

  const handleNewFormCreate = () => {
    const draftCostForms = costFormData?.filter(item => item.is_draft === true)
    if (draftCostForms?.length >= 1) {
      setIsFormOpen(true);
      dispatch(setCurrentFormType("Edit"));
      setSelectedFormData(draftCostForms[0])
    } else {
      setIsFormOpen(true);
      dispatch(setCurrentFormType("New"));
    }
  };

  const getFormsListApiData = async () => {
      const { data, error } = await getCostCalculatorFormListApi({
        params: {
          tab: currentTab
        },
      });
      if (!error && data) {
        if (currentTab === "templates") {
          setTemplatesData(data?.data)
        } else {
          setCostFormData(data?.data);
        }
      }
  }; 

  useEffect(() => {
      getFormsListApiData()
  }, [isFormOpen, currentTab])

  const deleteFormHandler = async (formDataItem: costFormDataType) => {
    if (formDataItem?.id) {
        const { data, error } = await deleteCostFormApi.deleteCostFormApi(formDataItem?.id);
        if (!error) {
          const NewFormsData = costFormData.filter((item) => item.id !== formDataItem.id)
          setCostFormData(NewFormsData)
        }
    }
  }

  
  
  return (
    <>
      
      {isFormOpen ? (
        <CostForm 
          selectedFormData={selectedFormData}
          setSelectedFormData={setSelectedFormData}
          isFormOpen={isFormOpen}
          setIsFormOpen={setIsFormOpen}
        />
      ) : (
        <>
        <div className="size-full flex flex-col overflow-hidden md:py-5 py-10 md:px-0 px-10">
          {!is_superAdmin && (
          <div className="flex justify-end px-16">          
            <Tab
              tabs={tabs}
              currentTabValue={currentTab}
              onChangeTab={setCurrentTab}
            />
          </div>
          )}
        {currentTab === 'myforms' ? (
          <>
          <div className="w-full md:text-2xl text-3xl font-medium mb-4">
          Admin Dashboard
        </div>
        <div className="w-full md:text-sm text-base text-gray-500 mb-8">
          See your estimators below, or create a new one.
        </div>
        <div className="flex-grow flex flex-col gap-8 mx-auto w-full overflow-hidden">
          <div className="w-full max-h-full min-h-[284px] grid grid-cols-3 xl:grid-cols-2 md:grid-cols-1 gap-4 overflow-auto pr-3">
            {/* --------Create New form --------- */}
            <div
                onClick={handleNewFormCreate}
                className="w-full bg-white border border-indigo-600 rounded-md flex flex-col items-center justify-center cursor-pointer py-8 px-5 gap-4 hover:shadow-md lg:aspect-video"
              >
                <div className="w-full text-center text-indigo-600 text-2xl font-medium">
                  Create New Estimator
                </div>
                <div className="w-full flex items-center justify-center text-indigo-600">
                  <Icon.Plus />
                </div>
            </div>
            {/* ---------- List Of Form ---------- */}
            {costFormData && costFormData?.map((formDataItem: any, index: number) => {
            // {Array?.from({length: 5}).map((formItem: any, index: number) => {
              return (
                <>
                  <div key={index}>
                    <FormCard
                      formDataItem={formDataItem}
                      selectedFormData={selectedFormData}
                      setSelectedFormData={setSelectedFormData}
                      setIsFormOpen={setIsFormOpen}
                      deleteFormHandler={deleteFormHandler}
                    />
                  </div>
                </>
              )
            })}
          </div>
        </div>
          </>
        ) : (
          <>
          <div className="w-full md:text-2xl text-3xl font-medium mb-4">
            Templates
          </div>
          <div className="w-full md:text-sm text-base text-gray-500 mb-8">
            Use template below to create or modified a new estimator.
          </div>
          <div className="flex-grow flex flex-col gap-8 mx-auto w-full overflow-hidden">
            <div className="w-full max-h-full min-h-[284px] grid grid-cols-3 xl:grid-cols-2 md:grid-cols-1 gap-4 overflow-auto pr-3">
              {/* ---------- List Of Form Template---------- */}
              {templatesData && templatesData?.map((formDataItem: any, index: number) => {
              // {Array?.from({length: 5}).map((formItem: any, index: number) => {
                return (
                  <>
                    <div key={index}>
                      <FormTemplate
                        formDataItem={formDataItem}
                        // selectedFormData={selectedFormData}
                        setSelectedFormData={setSelectedFormData}
                        setIsFormOpen={setIsFormOpen}
                        setCurrentTab={setCurrentTab}
                      />
                    </div>
                  </>
                )
              })}
              {templatesData?.length === 0 && (
                <p>No Templates Available</p>
              )}
            </div>
          </div>
          </>
          )}
          
        </div>  
        </>
      )}
    </>
  );
};

export default CostCalculatorDashboard;
