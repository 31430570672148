// ** packages **
import { useEffect, useRef, useState } from "react";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import { Edit, Eye } from "react-feather";
import { CopyToClipboard } from "react-copy-to-clipboard";

// ** components **
import { Modal } from "components/Theme/Modal";
import SelectField from "components/FormField/common/SelectField";
import Label from "components/FormField/common/Label";
import InputField from "components/FormField/common/inputField";
import TextAreaField from "components/FormField/common/TextAreaField";
 
// ** services **
// import { useAddServicesAPI, useGetServicesAPI, useIndustryListForServicesAPI, useUpdateServicesAPI } from "../services";

// ** schema **
// import { createCustomPromptWithTypeSchema } from "modules/Patient/sub-modules/Summary/schema/createCustomPrompt.validation.schema";

// ** types **
// import { currentDraftStatusType, currentStateType, ServicesFormType } from "../types";

import PageLoader from "components/Loaders/PageLoader";

// ** hooks **
import usePermission from "hooks/usePermission";
import { PERMISSIONS } from "constants/Permissions.constant";
import { isEqual } from "lodash";

import { getAuth } from "redux/slices/authSlice";
import { useSelector } from "react-redux";
import { useGetCostCalculatorFormIdAPI, useUpdateCostFormAPI } from "modules/CostCalculator/services";
import { costFormDataType } from "modules/CostCalculator/types";
import { useNavigate } from "react-router-dom";
const { REACT_APP_FRONT_URL } = process.env;

// import { createServicesTypeSchema } from "../schema";
// import { useIndustryListAPI } from "../../Industry/services";

interface SharePopupProps {
    isOpen: boolean;
    setIsOpen: ((value: boolean) => void) | undefined | any;
    selectedFormData?: costFormDataType | null;
}


const SharePopup = (props: SharePopupProps) => {

  const navigate = useNavigate()
  const { hasPermission } = usePermission();
  const { user } = useSelector(getAuth);
  const is_superAdmin = user?.is_super_administrator;

  const customPromptUpdateRole = hasPermission(
    PERMISSIONS.CUSTOM_PROMPT.UPDATE
  );

  const { isOpen, setIsOpen, selectedFormData } = props;

  const [copied, setCopied] = useState(false);
  const [estimatorLinkCopied, setEstimatorLinkCopied] = useState(false);

  const [isPlaybookCreatePermission, setIsPlaybookCreatePermission] =
    useState<boolean>(false);


  useEffect(() => {
    user?.current_plan?.payment_data?.plan?.specification?.forEach(
      (spec: any) => {
        if (
          spec.name_type?.toLowerCase() === "custom prompt" &&
          spec.limit === "false"
        ) {
          setIsPlaybookCreatePermission(true);
        }
      }
    );
  }, []);


  const {
    register,
    handleSubmit,
    setValue,
    getValues,
    reset,
    control,
    formState: { errors },
  } = useForm<any>({
    mode: "all",
    reValidateMode: "onChange",
    // resolver: yupResolver(createServicesTypeSchema),
    defaultValues: { form_name: "" },
  });

    useEffect(() => {
      if (selectedFormData) {
        setValue('form_name', selectedFormData?.form_name)
      }
    }, [])

    const embedUrl = REACT_APP_FRONT_URL + "/cost/form/" +selectedFormData?.code
    const embedCode = `<iframe src="${embedUrl}" width="600" height="400" frameborder="0"></iframe>`;


    const onClose = () => {
      setIsOpen(false);
    };
    
  return (
    <div className="w-full ">
      {isOpen && (
        <Modal
          onSubmit={onClose}
          submitButtonName="Done"
          headerName={"Share Your Estimator"}
          modalWidth="800px"
        //   isLoading={updateServicesApi.isLoading}
          onClose={onClose}
          // onCancel={onClose}
        >
            <>
              <form>
                <InputField
                  name="form_name"
                  label="Form Name"
                  value={selectedFormData?.form_name}
                //   register={register}
                  placeholder="Enter Form Name"
                  required
                  type="text"
                  disabled={true}
                />
                {/* <p className="text-sm mb-3 text-center"> Need a little help ? <a className="underline underline-offset-4" href="#">link</a> </p> */}
                <div className="flex flex-wrap gap-3 justify-center items-center my-8">
                  <CopyToClipboard text={embedUrl} onCopy={() => setEstimatorLinkCopied(true)}>
                    <p className="bg-[#6558F5] border-[#6558F5] border hover:bg-transparent cursor-pointer hover:text-[#6558F5] text-white px-4 py-2 text-sm rounded-md">
                      {estimatorLinkCopied ? "Estimator Link Copied!" : "Copy Estimator Link"}
                    </p>
                  </CopyToClipboard>
                  <button 
                    className="border-[#6558F5] border bg-transparent text-[#6558F5] hover:bg-[#6558F5] hover:text-white px-4 py-2 text-sm rounded-md"
                    onClick={() => {
                      navigate(`/cost/form/${selectedFormData?.code}`)
                    }}
                  >
                    Preview
                  </button>
                </div>
                <div className="text-center px-4 py-3 text-md text-gray-800 border-t">
                  {`<> Embed Code`} 
                </div>
                <div className="text-sm mb-3 border-2 rounded-md">
                  <CopyToClipboard text={embedCode} onCopy={() => setCopied(true)}>
                    <p className="text-right px-4 py-2 text-md text-gray-800 rounded-md cursor-pointer">
                      {copied ? "Copied !" : "Copy Code"}
                    </p>
                  </CopyToClipboard>
                  <p className="bg-black border rounded-md m-2 p-4 text-white">
                    {`<iframe src="${embedUrl}" 
                    width="400" ${" "}
                    height="600" 
                    frameborder="0">
                    </iframe>`}
                  </p>
                </div>
                {/* <div className="flex flex-wrap gap-3 justify-center items-center"> */}
                  {/* <CopyToClipboard text={embedCode} onCopy={() => setCopied(true)}>
                    <button className="bg-[#6558F5] border-[#6558F5] border hover:bg-transparent hover:text-[#6558F5] text-white px-4 py-2 text-sm rounded-md">
                      {copied ? "Copied!" : "Copy Code"}
                    </button>
                  </CopyToClipboard> */}
                  {/* <button className="border-[#6558F5] border bg-transparent text-[#6558F5] hover:bg-[#6558F5] hover:text-white px-4 py-2 text-sm rounded-md">
                    Continue Editing
                  </button> */}
                  
                {/* </div> */}
              </form>
            </>
        </Modal>
      )}
    </div>
  );
};

export default SharePopup;
