import React, { useEffect, useState } from "react";
import { RootStateType } from "redux/store";
import {
    getCostFormData,
  } from "redux/slices/formSlice";
import { useSelector } from "react-redux";
import FormStep1 from "./FormStep1";
import FormStep2 from "./FormStep2";
import FormStep3 from "./FormStep3";
import FormStep4 from "./FormStep4";
import { costFormDataType } from "modules/CostCalculator/types";
import FormStep5 from "./FormStep5";

interface CostFormProps {
    selectedFormData?: costFormDataType | null;
    setSelectedFormData?: any;
    isFormOpen?: any;
    setIsFormOpen?: any;
}


const CostForm:React.FC<CostFormProps> = ({
    selectedFormData,
    setSelectedFormData,
    isFormOpen,
    setIsFormOpen
}) => {

    const currentFormData = useSelector(getCostFormData);
    const [currentFormStep, setCurrentFormStep] = useState<string>("");

    useEffect(() => {
        if (currentFormData?.currentFormType === "New") {
            setCurrentFormStep("Step 1") 
        } else if (currentFormData?.currentFormType === "Edit") {
            setCurrentFormStep("Step 1")
        }
    }, [])


    return (
        <>
            {currentFormStep === "Step 1" ? (
                <>
                    <FormStep1
                        currentFormStep={currentFormStep}
                        setCurrentFormStep={setCurrentFormStep}
                        selectedFormData={selectedFormData}
                        setSelectedFormData={setSelectedFormData}
                        isFormOpen={isFormOpen}
                        setIsFormOpen={setIsFormOpen}
                    />
                </>
            ) : currentFormStep === "Step 2" ? (
                <>
                    <FormStep2
                        currentFormStep={currentFormStep}
                        setCurrentFormStep={setCurrentFormStep}
                        selectedFormData={selectedFormData}
                        setSelectedFormData={setSelectedFormData}
                    />
                </>
            ) : currentFormStep === "Step 3" ? (
                <>
                    <FormStep3
                        currentFormStep={currentFormStep}
                        setCurrentFormStep={setCurrentFormStep}
                        selectedFormData={selectedFormData}
                        setSelectedFormData={setSelectedFormData}
                    />
                </>
            ) : currentFormStep === "Step 4" ? (
                <>
                    <FormStep4
                        currentFormStep={currentFormStep}
                        setCurrentFormStep={setCurrentFormStep}
                        selectedFormData={selectedFormData}
                        setSelectedFormData={setSelectedFormData}
                        setIsFormOpen={setIsFormOpen}
                    />
                </>
            ) : currentFormStep === "Step 5" ? (
                <>
                    <FormStep5
                        currentFormStep={currentFormStep}
                        setCurrentFormStep={setCurrentFormStep}
                        selectedFormData={selectedFormData}
                        setSelectedFormData={setSelectedFormData}
                        setIsFormOpen={setIsFormOpen}
                    />
                </>
            ) : "" }
            
        </>
    )
}

export default CostForm