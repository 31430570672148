// ** packages **
import { useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";

// ** components **
import TableCellRender from "components/DataTable/components/TableCellRender";

// ** constants **
import { PRIVATE_NAVIGATION } from "constants/navigation.constant";
import { firstCharUpperCase, formatTimeAgo } from "../../../utils/index";
import ImageShow from "components/Image";
import { Mic } from "react-feather";
// ** helper **

type usePatientColumnNewSidebarPropsType = {
    selectedPatientIdsArr: number[];
    setSelectedPatientIdsArr: React.Dispatch<React.SetStateAction<number[]>> | any;
};


const usePatientColumnNewSidebar = ( props : usePatientColumnNewSidebarPropsType) => {

    const {
        selectedPatientIdsArr,
        setSelectedPatientIdsArr,
    } = props;

  const navigate = useNavigate();
  const navigateToPatient = (id: number | string) => {
    navigate(`${PRIVATE_NAVIGATION.patient.detail.view(String(id))}`);
  };

  const [windowSize, setWindowSize] = useState(window.innerWidth);

  const handleResize = () => {
    if (
      (window.innerWidth > 1100 && windowSize < 1100) ||
      (window.innerWidth < 1100 && windowSize >= 1100)
    ) {
      setWindowSize(window.innerWidth);
    }
  };

  useEffect(() => {
    window.addEventListener("resize", handleResize);

    // Cleanup function to remove event listener
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [windowSize]);

  const handleCheckboxChange = (id: number, isChecked: boolean) => {
    setSelectedPatientIdsArr((prevSelectedIds: any = []) => {
      if (prevSelectedIds.includes(id)) {
        return prevSelectedIds.filter(
          (selectedId: number) => selectedId !== id
        );
      } else {
        if (isChecked && id) {
          return [...prevSelectedIds, id];
        }
        return prevSelectedIds;
      }
    });
  };

  const columnDefs = useMemo(
    () => [
        {
            // name: "Select",
            selector: (row: { id: string | number }) => row.id,
            sortable: false,
            width: "50px",
            cell: (row: {
                id: string | number;
                // status: string;
                // note_type: string;
            }) => (
                <TableCellRender
                cellRowData={
                    <div className="custom__checkbox__SD without__label flex">
                        <input
                            type="checkbox"
                            onChange={(e) => {
                                handleCheckboxChange(+row.id, e.target.checked);
                            }}
                            checked={selectedPatientIdsArr?.includes(+row.id)}
                        />
                        <label className="label__SD"></label>
                    </div>
                }
                />
            ),
        },
      {
        name: "Name",
        selector: (row: { name: string }) => row.name,
        sortable: true,
        sortField: "name",
        // width: "40%",
        cell: (row: {
          name: string;
          email: string;
          profile: string;
          id: number | string;
        }) => (
          <TableCellRender
            cellRowData={
              <>
                <div
                  className="container mx-auto py-2 cursor-pointer"
                  onClick={() => navigateToPatient(row.id)}
                >
                  <div className="flex gap-2 items-center">
                    <div className="flex-shrink-0">
                      <ImageShow
                        avatarWrapperClassName="h-[40px] w-[40px] rounded-full"
                        imgClassName="rounded-full z-2"
                        avatarInnerWrapperClassName="!rounded-full"
                        imgPath={row.profile}
                        first_name={row.name}
                        last_name={row.name}
                        NoNameLetterWrapperClass="!z-0"
                      />
                    </div>
                    <div className="text-left w-[calc(100%-50px)">
                      <div className="font-medium text-gray-900 truncate max-w-[190px] 4xl:max-w-[160px]">
                        {row.name}
                      </div>
                      <div className="text-gray-500 text-[11px] truncate max-w-[190px] 4xl:max-w-[160px]">{row.email}</div>
                    </div>
                  </div>
                </div>
              </>
            }
          />
        ),
      },
    ],
    [windowSize, selectedPatientIdsArr]
  );
  return columnDefs;
};

export default usePatientColumnNewSidebar;
