import React, { useState } from "react";

import { useDispatch } from "react-redux";
import { setCurrentFormType } from "redux/slices/formSlice";

import CostForm from "./sub-module/CostForm";
import CostCalculatorDashboard from "./sub-module/CostCalculatorDashboard";
import Tab from "components/Tab";

interface CostCalculatorFormsType {}

const CostCalculator: React.FC<CostCalculatorFormsType> = () => {

  return (
    <>
      
      <CostCalculatorDashboard />
      
    </>
  );
};

export default CostCalculator;
