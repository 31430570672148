// ** packages **
import { useEffect, useRef, useState } from "react";
import { Plus, Search } from "react-feather";
import { debounce } from "lodash";
// ** components **
import Breadcrumbs from "components/Breadcrumbs";
import Button from "components/Theme/Button";
import AddMeetingForm from "./components/AddMeetingForm";
import DataTableReact, { TableRefType } from "components/DataTable";
// ** hooks **
import useMeetingColumn from "./hooks/useMeetingColumn";
// ** services **
import { useAllDeleteMeetingFolderAPI, useMeetingFolderListAPI } from "./services";
// ** constants **
import { BREAD_CRUMB } from "constants/breadcrumbs.constant";
import usePermission from "hooks/usePermission";
import { PERMISSIONS } from "constants/Permissions.constant";
import NoteSideBar from "modules/DashBoard/sub-modules/Notes/components/NoteSideBar";
import SelectMenu, { optionsType } from "components/SelectMenu";
import { NoteOptions } from "modules/DashBoard/sub-modules/Notes";
import { PRIVATE_NAVIGATION } from "constants/navigation.constant";
import { useNavigate, useParams } from "react-router-dom";
import AlertModal from "components/modal/AlertModal";
import useMeetingFolderColumn from "./hooks/useMeetingFolderColumn";

const MeetingSideBar = (props: any) => {
  const { setSearchValue, searchData } = props;
  const [openForm, setOpenForm] = useState<boolean>(false);
  const [isSideOpen, setIsSideOpen] = useState<boolean>(false);
  const [selectedMeetingFolderIdsArr, setSelectedMeetingFolderIdsArr] = useState<number[]>([]);
  const [open, setOpen] = useState(false);
  const tableRef = useRef<TableRefType>(null);
  const navigate = useNavigate();
  const { getMeetingFolderListApi, isLoading } = useMeetingFolderListAPI();
  const { allDeleteMeetingFolderAPI } = useAllDeleteMeetingFolderAPI();
  const { hasPermission } = usePermission();
  // const hasNoteDeletePermission = hasPermission(PERMISSIONS.NOTES.DELETE);
  const hasMeetingFolderDeletePermission = hasPermission(PERMISSIONS.MEETING.DELETE)

  const getTableData = async (params: {
    page: number;
    perPage: number;
    sortField: string;
    sortOrder: string;
    search: string;
  }) => {
    let tableData = { rowData: [], rowCount: 10 };
    const { data, error } = await getMeetingFolderListApi({
      params: {
        page: params.page,
        per_page: params.perPage,
        sort_by: params?.sortField,
        sort_order: params?.sortOrder,
        search: searchData ?? params.search,
      },
    });
    if (data && !error) {
      tableData = {
        rowData: data?.data || [],
        rowCount: data?.count || 0,
      };
    }
    return tableData;
  };
  const refreshTable = () => {
    tableRef.current?.refreshData();
  };

  const onClose = () => {
    setOpen(false);
    setSelectedMeetingFolderIdsArr([]);
    refreshTable();
  };

  const columnDefs = useMeetingFolderColumn({
    selectedMeetingFolderIdsArr,
    setSelectedMeetingFolderIdsArr,
  });

  const onDeleteClickSidebar = async () => {
    setOpen(true);
  };

  const deleteHandler = async () => {
    if (selectedMeetingFolderIdsArr?.length) {
      await allDeleteMeetingFolderAPI(selectedMeetingFolderIdsArr);
      setSelectedMeetingFolderIdsArr([]);
      refreshTable();
      navigate("/")
    }
  };

  useEffect(() => {
    setSearchValue(tableRef);
  }, [tableRef.current]);

  return (
    <>
    <div>
      <NoteSideBar
        setIsSideOpen={setIsSideOpen}
        tableRef={tableRef}
        isSideOpen={isSideOpen}
        note_type={"meeting"}
      />
      <div className="content-table-data">
        <div
          className={`reactDataTable__DSD meetingListNotes ${
            selectedMeetingFolderIdsArr.length ? "data-check" : ""
          }`}
        >
          <DataTableReact
          setTableLength={null}
          ref={tableRef}
          columns={columnDefs}
          loader={isLoading}
          getData={(params: {
            page: number;
            perPage: number;
            sortField: string;
            sortOrder: string;
            search: string;
          }) => getTableData(params)}
        />
        </div>
      </div>
      <AlertModal
          visible={open}
          onClose={onClose}
          onCancel={onClose}
          onSubmit={() => {
            deleteHandler();
            setOpen(false);
          }}
          submitButtonText="Yes"
        >
          <p className="text-[18px] font-Biotif__SemiBold text-textDark text-center w-[300px] max-w-full mx-auto">
            {`Are you sure you want to delete ${
              (selectedMeetingFolderIdsArr.length === 1 && "this folder ? All your recordings will be deleted permantely. ") ||
              (selectedMeetingFolderIdsArr.length > 1 && "this folders ? All your recordings will be deleted permantely. ")
            } `}
          </p>
        </AlertModal>
    </div>
    {hasMeetingFolderDeletePermission && <div
        className={`${
          selectedMeetingFolderIdsArr?.length && "p-3 border-t border-black/15"
        }`}
      >
        {selectedMeetingFolderIdsArr?.length ? (
          <Button
            className="bg-white text-red-600 border border-red-600 w-48 hover:bg-red-400 hover:text-white hover:border-red-400"
            onClick={onDeleteClickSidebar}
          >
            Delete&nbsp;({selectedMeetingFolderIdsArr?.length})
          </Button>
        ) : (
          <></>
        )}
      </div>}
    </>
  );
};
export default MeetingSideBar;
