// ** packages **
import { useEffect, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { Edit } from "react-feather";
import { Editor } from "@tiptap/core";

// ** components **
import { Modal } from "components/Theme/Modal";
import SelectField from "components/FormField/common/SelectField";
import Label from "components/FormField/common/Label";
import InputField from "components/FormField/common/inputField";

// ** services **
import { useGetQuestionAPI, useGetUserInputQuestionsAPI, useUpdateQuestionAPI } from "modules/CostCalculator/services";

// ** schema **

// ** types **
import { QuestionSettingFormType } from "./types";

// ** hooks **
import usePermission from "hooks/usePermission";
import { PERMISSIONS } from "constants/Permissions.constant";
import { getAuth } from "redux/slices/authSlice";
import { useSelector } from "react-redux";

import CheckBoxField from "components/FormField/common/CheckBoxField";
import { costFormDataType, optionsDataType, questionsDataType } from "modules/CostCalculator/types";
import Note from "components/Note";

const QuestionSettingForm = (props: QuestionSettingFormType) => {

  const { hasPermission } = usePermission();
  const { user } = useSelector(getAuth);
  const is_superAdmin = user?.is_super_administrator;

  const customPromptUpdateRole = hasPermission(
    PERMISSIONS.CUSTOM_PROMPT.UPDATE
  );

  const { isOpen, setIsOpen, refreshTable, selectedFormData, setSelectedFormData } = props;


  const [questionImage, setQuestionImage] = useState<string | File>("");
  const [fileName, setFileName] = useState<string | null>(null);
 

  const getQuestionApi = useGetQuestionAPI();
  const updateQuestionApi = useUpdateQuestionAPI();

  const getUserInputQuestionsApi = useGetUserInputQuestionsAPI();

  // const [promptType, setPromptType] = useState<TypeofQuestionType[]>([
  //   { name: "", questionImage: "" },
  // ]);

  const [questionData, setQuestionData] = useState<questionsDataType>();

  const [userInputQuestionsData, setUserInputQuestionsData] = useState<any[]>([]);
  const [selectedUserInputQuestionId, setSelectedUserInputQuestionId] = useState<any>();

  const [visibleFixedPrice, setVisibleFixedPrice] = useState<boolean>(questionData?.is_number_field ? true : false);
  const [disableIsRequiredOne, setDisableIsRequiredOne] = useState<boolean>(false);
  const [disableAllowMultiple, setDisableAllowMultiple] = useState<boolean>(false);

  const [visibleUserQuestionSelection, setVisibleUserQuestionSelection] = useState<boolean>(questionData?.is_multiply_all_cost ? true : false);

  const [learnMoreContent, setLearnMoreContent] = useState<string | undefined>(questionData?.learn_more_content ? questionData?.learn_more_content : questionData?.learn_more_content);
  const [isEditEnable, setIsEditEnable] = useState<boolean>(false);

  const [isPlaybookCreatePermission, setIsPlaybookCreatePermission] =
    useState<boolean>(false);

  useEffect(() => {
    user?.current_plan?.payment_data?.plan?.specification?.forEach(
      (spec: any) => {
        if (
          spec.name_type?.toLowerCase() === "custom prompt" &&
          spec.limit === "false"
        ) {
          setIsPlaybookCreatePermission(true);
        }
      }
    );
  }, []);

  const getUserInputQuestionListApiData = async () => {
    const { data, error } = await getUserInputQuestionsApi.getUserInputQuestionsAPI({
      params: {
        'question_id' : isOpen?.id
      },
    });
    if (!error && data) {
      // setIndustryData(data.data);
      const questionData = data?.data?.map((question:any) => ({
        id: question.id,
        value: question.question_name,
        label: question.question_name
      }));
      setUserInputQuestionsData(questionData);
    }
  }; 

  const getQuestionApiData = async () => {
    if (isOpen.id) {
      const { data, error } = await getQuestionApi.getQuestionAPI(isOpen.id);
      if (!error && data) {
        setQuestionData(data);
        setValue("question_subtitle", data?.question_subtitle);
        setLearnMoreContent(data?.learn_more_content);
        setValue("video_url", data?.video_url);
        setValue("allow_multiple_selections", data?.allow_multiple_selections);
        
        if (data?.allow_multiple_selections) {
          setDisableIsRequiredOne(true)
        }

        setValue("is_required_one", data?.is_required_one);
        setValue("is_number_field", data?.is_number_field);
        setValue("fixed_unit_price", data?.fixed_unit_price);
        setValue("is_multiply_all_cost", data?.is_multiply_all_cost);
        if (data?.is_multiply_all_cost) {
          setVisibleUserQuestionSelection(true);
          setSelectedUserInputQuestionId({
            id: data?.parent?.id,
            value: data?.parent?.question_name,
            label: data?.parent?.question_name
          })
        }

      }
    }
  };

  useEffect(() => {
    if (isOpen.id) {
      getUserInputQuestionListApiData();
      getQuestionApiData()
    }
  }, [isOpen])

  const {
    register,
    handleSubmit,
    setValue,
    getValues,
    reset,
    control,
    formState: { errors },
  } = useForm<any>({
    mode: "all",
    reValidateMode: "onChange",
    // resolver: yupResolver(createQuestionTypeSchema),
    defaultValues: { name: "" },
  });

    const stateFormReset = (error: any, data: any) => {
        if (!error && data) {
        setIsOpen({ open: false, id: null, isDefault: false, viewOnly: true });
        reset();
        // refreshTable && refreshTable();
        }
    };

    const updateQuestion = (questionId: number | any, updatedData: optionsDataType) => {
            setSelectedFormData((selectedFormData: costFormDataType) => {
                return {
                    ...selectedFormData,
                    services: selectedFormData.services.map((service:any) => ({
                        ...service,
                        questions: service.questions.map((question:any) =>
                            question.id === questionId
                                ? { ...question, ...updatedData } // Update question data
                                : question
                        )
                    }))
                };
            });
        };

    const onSubmit = handleSubmit(async (submittedData) => {
        const formData = new FormData();
        for (const [key, value] of Object.entries(submittedData)) {
            if (value !== null) {
              formData.append(key, String(value));
            }
        }
        // const content = editor?.getHTML()
        if (learnMoreContent) {
          formData.append("learn_more_content", learnMoreContent);
        }
        if (selectedUserInputQuestionId && visibleUserQuestionSelection) {
          formData.append("question_id", String(selectedUserInputQuestionId?.id));
        }
        if (isOpen?.id) {
            const { data, error } = await updateQuestionApi.updateQuestionsAPI(
              formData,
              String(isOpen?.id)
            );
            updateQuestion(isOpen?.id, data)
            stateFormReset(error, data);

        }
    });

    const onClose = () => {
      setIsOpen({
        open: false,
        id: null,
        isDefault: false,
        viewOnly: true,
      });
      reset();
    };

    const handleChange = (e:any) => {
        const { name, value, files } = e.target;
        setQuestionImage(files ? files[0] : value);
        setFileName(files ? files[0]?.name : value?.name)
    };

  const onSave = async (editor: Editor | null) => {
    if (editor) {
      setLearnMoreContent(editor?.getHTML()) 
    }
  };

  const onCancel = () => {
    setIsEditEnable(false);
  };


  return (
    <div className="w-full ">
      {isOpen.open && (
        <Modal
          onSubmit={onSubmit}
          headerName={ "Question Settings"
            // isOpen?.id
            //   ? isOpen?.viewOnly || (isOpen?.isDefault && !is_superAdmin)
            //     ? "View Question"
            //     : "Edit Question"
            //   : "Add Question"
          }
          modalWidth="800px"
        //   isLoading={updateQuestionApi.isLoading}
          onClose={onClose}
          onCancel={onClose}
        >
          {/* {getQuestionApi.isLoading ? (
            <PageLoader pageLoaderClassName="h-[604px]" />
          ) : ( */}
            <>
              <form onSubmit={onSubmit}>
                {customPromptUpdateRole && isOpen?.id && (
                  <div className="flex justify-end">
                    {isOpen?.viewOnly && (
                      <Edit
                        className="text-xs cursor-pointer"
                        onClick={() => {
                          setIsOpen({ ...isOpen, viewOnly: false });
                        }}
                      />
                    )}
                  </div>
                )}
                
                <div className="form__group">
                  <div className="field__wrapper">
                    <div className="text-md text-gray-800 font-normal py-1">Question Sub Title</div>
                    <div className="py-1 px-2">
                    <InputField
                        name="question_subtitle"
                        // label="Question Sub Title"
                        register={register}
                        placeholder="Question subtitle"
                        // required
                        type="text"
                        errors={errors.question_subtitle}
                        disabled={
                          (isOpen?.isDefault && !is_superAdmin) || isOpen?.viewOnly
                        }
                        className={
                          (isOpen?.isDefault && !is_superAdmin) || isOpen?.viewOnly
                            ? "opacity-50"
                            : ""
                        }
                      />
                    </div>
                  </div>
                </div>
                <div className="form__group">
                  <div className="field__wrapper">
                    <div className="text-md text-gray-800 font-normal py-1">Learn More Content </div>
                    <div className="font-normal text-gray-500 text-sm py-1 px-2">Provide more context such as an explanation of technical terms or a guide on how to answer. A 'Learn More' button will appear under the question.</div>
                    <div className="py-1 px-2">
                        <Note
                          content={learnMoreContent ? learnMoreContent : ""}
                          isEdit={true}
                          // setIsEdit={setIsEditEnable}
                          // isLoading={isLoading}
                          EditorContentClassName="w-full !h-[200px]"
                          onSave={onSave}
                          mainDivClassName="w-full"
                          // isLoadingSave={isUpdateLoading}
                          // needAlert={true}
                          // onCancel={onCancel}
                          contentId={questionData ? questionData?.id : 0 }
                          hasUpdatePermission={true}
                        />
                    </div>
                  </div>
                </div>
                <div className="form__group">
                  <div className="field__wrapper">
                    <div className="text-md text-gray-800 font-normal py-1">Video </div>
                    <div className="font-normal text-gray-500 text-sm py-1 px-2">A video could be a preview of what's on offer, or provide an explanation of the question. A 'Watch Video' button will appear under the question.</div>
                    <div className="py-1 px-2">
                      <InputField
                          name="video_url"
                          // label="Title"
                          register={register}
                          placeholder="Video"
                          required
                          type="text"
                          errors={errors.video_url}
                          disabled={
                            (isOpen?.isDefault && !is_superAdmin) || isOpen?.viewOnly
                          }
                          className={
                            (isOpen?.isDefault && !is_superAdmin) || isOpen?.viewOnly
                              ? "opacity-50"
                              : ""
                          }
                      />
                    </div>
                    <div className="font-normal text-gray-500 text-sm py-1 px-2 italic">PriceGuide supports YouTube and Vimeo videos. Paste the video URL above.</div>
                  </div>
                </div>
                <div className="form__group">
                  <div className="field__wrapper">
                    <div className="text-md text-gray-800 font-normal py-1">Selection Options </div>
                    <div className="font-normal text-gray-500 text-sm py-1 px-2">By default, a user can only select one answer to a question. Use the following settings to change this behaviour. Check out our multiple selection tutorial for more information.</div>
                    <div className="py-1 px-2">
                      <div className="flex pl-16 py-2">
                        <CheckBoxField
                          wrapperClass="top-[3px]"
                          hasFieldLabel={false}
                          // key={`specification.${i}checkbox`}
                          type="checkbox"
                          // id={`specification.${i}infinite`}
                          name="allow_multiple_selections"
                          defaultChecked={true
                            // spec.limit === "-1" ? true : false
                          }
                          checked={false}
                          register={register}
                          disabled={disableAllowMultiple}
                          onChange={(e) => {
                            if (e.target.checked) {
                              setDisableIsRequiredOne(true);
                              setValue("is_number_field", false);
                              setVisibleFixedPrice(false);
                            } else {
                              setDisableIsRequiredOne(false);
                              setValue("is_required_one", false);
                            }
                          }}
                        />
                        <div>
                          <div className="text-sm text-gray-800 font-normal pl-4">Allow multiple selections </div>
                          <div className="font-normal text-gray-500 text-sm pl-4 py-2">Users can choose more than one answer</div>
                        </div>
                      </div>
                      <div className="flex pl-16 py-2">
                        <CheckBoxField
                          wrapperClass="top-[3px]"
                          hasFieldLabel={false}
                          type="checkbox"
                          name="is_required_one"
                          defaultChecked={true
                            // spec.limit === "-1" ? true : false
                          }
                          checked={false}
                          register={register}
                          disabled={!disableIsRequiredOne}
                          onChange={(e) => {
                          }}
                        />
                        <div>
                          <div className="text-sm text-gray-800 font-normal pl-4">Require at least one selection </div>
                          <div className="font-normal text-gray-500 text-sm pl-4 py-2">At least one answer must be selected</div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="form__group">
                  <div className="field__wrapper">
                    <div className="text-md text-gray-800 font-normal py-1">Multiply Options </div>
                    <div className="font-normal text-gray-500 text-sm py-1 px-2">These settings allow your users to enter a value that is used to estimate the price of your services, for example if you charge per room, or per square foot. Create one question for the value and then one or more questions that you want to multiply by that value. 
                      <br/><br/>Need help? Try our cost multiplication tutorial.</div>
                    <div className="py-1 px-2">
                      <div className="flex pl-16 py-2">
                          <CheckBoxField
                            wrapperClass="top-[3px]"
                            hasFieldLabel={false}
                            type="checkbox"
                            name="is_number_field"
                            defaultChecked={true
                              // spec.limit === "-1" ? true : false
                            }
                            checked={false}
                            register={register}
                            onChange={(e) => {
                              if (e.target.checked) {
                                setVisibleFixedPrice(true);
                                setDisableAllowMultiple(true)
                                setValue("is_multiply_all_cost", false);
                                setVisibleUserQuestionSelection(false);
                              } else {
                                setVisibleFixedPrice(false);
                                setDisableAllowMultiple(false)
                              }
                            }}
                            disabled={disableIsRequiredOne}
                          />
                          <div>
                            <div className="text-sm text-gray-800 font-normal pl-4">Use number field </div>
                            <div className="font-normal text-gray-500 text-sm pl-4 py-2">Users respond to this question by typing in a number</div>
                          </div>
                      </div>
                      {visibleFixedPrice && (
                        <>
                        <div className="flex pl-24 item-center items-baseline">
                          <div className="text-sm text-gray-800 font-normal pr-2">Fixed price per unit (optional):</div>
                          <InputField
                              name="fixed_unit_price"
                              // label="Title"
                              register={register}
                              placeholder="$ 0"
                              required
                              type="number"
                              errors={errors.fixed_unit_price}
                              disabled={
                                (isOpen?.isDefault && !is_superAdmin) || isOpen?.viewOnly
                              }
                              className={
                                (isOpen?.isDefault && !is_superAdmin) || isOpen?.viewOnly
                                  ? "no-spinner opacity-50"
                                  : "no-spinner"
                              }
                          />
                        </div>
                        </>
                      )}
                      
                      <div className="flex pl-16 py-2">
                          <CheckBoxField
                            wrapperClass="top-[3px]"
                            hasFieldLabel={false}
                            type="checkbox"
                            name="is_multiply_all_cost"
                            defaultChecked={true
                              // spec.limit === "-1" ? true : false
                            }
                            checked={false}
                            register={register}
                            disabled={disableAllowMultiple}
                            onChange={(e) => {
                              if (e.target.checked) {
                                setVisibleUserQuestionSelection(true);
                                // setDisableAllowMultiple(true)
                              } else {
                                setVisibleUserQuestionSelection(false);
                                // setDisableAllowMultiple(false)
                              }
                            }}
                          />
                          <div>
                            <div className="text-sm text-gray-800 font-normal pl-4">Multiply all costs by a user input </div>
                            <div className="font-normal text-gray-500 text-sm pl-4 py-2">Choose a number field to link these costs to</div>
                          </div>
                      </div>
                      {visibleUserQuestionSelection && 
                        <div className="form__group pl-16 py-2">
                          <div className="field__wrapper">
                            <Label required={true} label="Select Question" />
                            <div
                              className={
                                (isOpen?.isDefault && !is_superAdmin) || isOpen?.viewOnly
                                  ? "opacity-50 field__inner__wrapper"
                                  : "field__inner__wrapper"
                              }
                            >
                              <SelectField
                                name="defaultValSelect"
                                onChange={(e: any) => {
                                  setSelectedUserInputQuestionId(e);
                                }}
                                value={selectedUserInputQuestionId}
                                options={userInputQuestionsData}
                                disabled={
                                  (isOpen?.isDefault && !is_superAdmin) || isOpen?.viewOnly
                                }
                              />
                              <div className="icon__wrapper"></div>
                            </div>
                          </div>
                        </div>
                      }
                    </div>
                  </div>
                </div>
              </form>
            </>
          {/* )} */}
        </Modal>
      )}
    </div>
  );
};

export default QuestionSettingForm;
