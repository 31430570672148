import { RouteObjType } from "routes";
import {
  PRIVATE_NAVIGATION,
} from "constants/navigation.constant";
import AdminRoute from "./components/AdminRoute";
import React, { Suspense } from "react";
import PermissionGuard from "modules/Auth/components/PermissionGuard";
import { PERMISSIONS } from "constants/Permissions.constant";
import NotFoundPage from "modules/Auth/pages/NotFound";
import SuperAdminInvoices from "./sub-module/Setting/SuperUser/components/Invoices";
import AllAdminPlans from "./sub-module/Setting/PlanManagement/components";
import PlaybookSettingUser from "modules/Setting/sub-modules/PlaybookSetting";
import AdminIndustry from "./sub-module/AdminCostCalculator/Industry";
import AdminMarket from "./sub-module/AdminCostCalculator/Market";
import AdminServices from "./sub-module/AdminCostCalculator/AdminServices";
import CostCalculator from "modules/CostCalculator";
import UserResponseListPage from "modules/CostCalculator/sub-module/UserResponse/components/UserResponseListPage";
import EstimatorPage from "modules/CostCalculator/sub-module/EstimatorPage";
import DesignOptions from "modules/CostCalculator/sub-module/DesignOptions";

const AdminUser = React.lazy(() => import("./sub-module/AdminCustomer"));
const AdminDashboard = React.lazy(() => import("./sub-module/AdminDashboard"));
const CustomerDetail = React.lazy(
  () => import("./sub-module/AdminCustomer/components/CustomerDetail")
);
const PromptDetail = React.lazy(
  () => import("./sub-module/AdminPrompt/index")
);
const SuperUser = React.lazy(() => import("./sub-module/Setting/SuperUser"));
const NotFound404 = React.lazy(
  () => import("components/Theme/NoDataFound/NotFound")
);
const RoleAndPermissionSuperAdmin = React.lazy(
  () => import("./sub-module/Setting/RoleAndPermission")
);

export const applyAdminAuth = (routes: RouteObjType[]): RouteObjType[] => {
  return routes.map((route) => ({
    ...route,
    element: <AdminRoute>{route.element}</AdminRoute>,
  }));
};

const adminRoutes: RouteObjType[] = applyAdminAuth([
  {
    path: PRIVATE_NAVIGATION.adminDashboard.view,
    element: <AdminDashboard />,
  },
  {
    path: PRIVATE_NAVIGATION.customer.view,
    element: (
      <PermissionGuard permission={PERMISSIONS.CUSTOMER.READ} wantSuper={true}>
        <AdminUser />
      </PermissionGuard>
    ),
  },
  {
    path: PRIVATE_NAVIGATION.customer.detail.view(),
    element: (
      <PermissionGuard permission={PERMISSIONS.CUSTOMER.READ} wantSuper={true}>
        <CustomerDetail />
      </PermissionGuard>
    ),
  },
  {
    path: PRIVATE_NAVIGATION.playbook.view,
    element: (
      <PermissionGuard permission={PERMISSIONS.CUSTOMER.READ} wantSuper={true}>
        <PlaybookSettingUser/>
      </PermissionGuard>
    ),
  },
  {
    path: PRIVATE_NAVIGATION.adminSetting.superuser.view,
    element: (
      <PermissionGuard permission={PERMISSIONS.USER.READ} wantSuper={true}>
        <SuperUser />
      </PermissionGuard>
    ),
  },
  {
    path: PRIVATE_NAVIGATION.adminSetting.rolesAndPermissions.view,
    element: (
      <PermissionGuard permission={PERMISSIONS.ROLE.READ} wantSuper={true}>
        <RoleAndPermissionSuperAdmin />
      </PermissionGuard>
    ),
  },
  {
    path: PRIVATE_NAVIGATION.adminSetting.invoices.view,
    element: (
      <PermissionGuard permission={PERMISSIONS.USER.READ} wantSuper={true}>
        <SuperAdminInvoices />
      </PermissionGuard>
    ),
  },
  {
    path: PRIVATE_NAVIGATION.adminNotFoundPage,
    element: (
      <Suspense>
        <NotFound404 />
      </Suspense>
    ),
  },
  {
    path: PRIVATE_NAVIGATION.adminPrompt.view,
    element: (
      <PermissionGuard permission={PERMISSIONS.CUSTOM_PROMPT.READ} wantSuper={true}>
        <PromptDetail />
      </PermissionGuard>
    ),
  },
  {
    path: PRIVATE_NAVIGATION.adminSetting.planManagement.view,
    element: (
      <PermissionGuard permission={PERMISSIONS.PLAN.READ} wantSuper={true}>
        <AllAdminPlans />
      </PermissionGuard>
    ),
  },
  {
    path: PRIVATE_NAVIGATION.adminCostCalculator.industryPermission.view,
    element: (
      // <PermissionGuard permission={PERMISSIONS.USER.READ} wantSuper={true}>
        <AdminIndustry />
      // </PermissionGuard>
    ),
  },
  {
    path: PRIVATE_NAVIGATION.adminCostCalculator.marketPermission.view,
    element: (
      // <PermissionGuard permission={PERMISSIONS.ROLE.READ} wantSuper={true}>
        <AdminMarket />
      // </PermissionGuard>
    ),
  },
  {
    path: PRIVATE_NAVIGATION.adminCostCalculator.servicesPermission.view,
    element: (
      // <PermissionGuard permission={PERMISSIONS.USER.READ} wantSuper={true}>
        <AdminServices />
      // </PermissionGuard>
    ),
  },
  {
    path: PRIVATE_NAVIGATION.adminCostCalculator.costFormPermission.view,
    element: (
      // <PermissionGuard permission={PERMISSIONS.USER.READ} wantSuper={true}>
        <CostCalculator />
      // </PermissionGuard>
    ),
  },
  {
    path: PRIVATE_NAVIGATION.adminCostCalculator.designOptionsPermission.view,
    element: (
      // <PermissionGuard permission={PERMISSIONS.USER.READ} wantSuper={true}>
        <DesignOptions />
      // </PermissionGuard>
    ),
  },
  {
    path: PRIVATE_NAVIGATION.adminCostCalculator.userResponsesPermission.view,
    element: (
      // <PermissionGuard permission={PERMISSIONS.USER.READ} wantSuper={true}>
      <UserResponseListPage/>
      // </PermissionGuard>
    ),
  },
  {
    path: PRIVATE_NAVIGATION.adminCostCalculator.estimatorPagePermission.view(),
    element: (
      // <PermissionGuard permission={PERMISSIONS.NOTES.CREATE}>
        <EstimatorPage/>
      // </PermissionGuard>
    ),
  },
  {
    path: "*",
    element: <NotFoundPage />,
  },
]);
export default adminRoutes;
