import React from "react";
import { industryDataType, marketDataType, servicesDataType } from "../../../types";
import { useDispatch } from "react-redux";
import {
  setStep1Data,
  setStep2Data,
  setStep3Data,
  setStep4Data,
} from "redux/slices/formSlice";
// import { Button } from "@headlessui/react";
import Button from "components/Theme/Button";

interface StepButtonProps {
  prevButtonLable?: string;
  prevButtonVisible?: boolean;
  nextButtonLable?: string;
  nextButtonDisable?: boolean;
  handlePrevClick?:any
  handleNextClick?:any
  buttonStyleProps?:any
}

const StepButton: React.FC<StepButtonProps> = ({
  prevButtonLable,
  prevButtonVisible,
  nextButtonLable,
  nextButtonDisable,
  handlePrevClick,
  handleNextClick,
  buttonStyleProps,
}) => {

  return (
    <>
      <div className="w-full flex items-center justify-between">
        {prevButtonVisible === true ? (
          <>
            <div></div>
          </>
        ) : (
          <>
            <div>
            <button
              className="bg-[#6558F5] border-[#6558F5] border flex gap-2 items-center mx-auto text-white px-4 py-2 text-sm rounded-md"
              type="button"
              onClick={handlePrevClick}
              style={buttonStyleProps}
            >
              <div className="btn__text">{prevButtonLable ? prevButtonLable : "Prev"}</div>
            </button>
            </div>
          </>
        )}
        <div>
        <button
          className="bg-[#6558F5] border-[#6558F5] border flex gap-2 items-center mx-auto text-white px-4 py-2 text-sm rounded-md"
          onClick={handleNextClick}
          disabled={nextButtonDisable}
          style={buttonStyleProps}
        >
          {nextButtonLable ? nextButtonLable : "Next" }
        </button>
        </div>
      </div>
      {/* <div className="w-full flex items-center justify-between">
        {prevButtonVisible === true ? (
          <>
            <div></div>
          </>
        ) : (
          <>
            <Button
              className="button__DSD text-[#4c60e9] bg-white border border-[#4c60e9] h-9"
              type="button"
              onClick={handlePrevClick}
            >
              <div className="btn__text">{prevButtonLable ? prevButtonLable : "Prev"}</div>
            </Button>
          </>
        )}
        <Button
          className="primary__Btn w-fit"
          onClick={handleNextClick}
          disabled={nextButtonDisable}
        >
          {nextButtonLable ? nextButtonLable : "Next" }
        </Button>
      </div> */}
    </>
  );
};

export default StepButton;
