import React, { useState } from "react";
import * as Icon from "react-feather";
import SelectField from "components/FormField/common/SelectField";
import InputField from "components/FormField/common/inputField";
import { costFormDataType, optionsDataType } from "modules/CostCalculator/types";
import { useUpdateOptionsAPI } from "modules/CostCalculator/services";
import AlertModal from "components/modal/AlertModal";

interface OptionCardProps{
    option?: optionsDataType;
    optionDeleteHandler? : any
    selectedFormData?: costFormDataType | null;
    setSelectedFormData?: any;
}


const OptionCard: React.FC<OptionCardProps> = ({
    option,
    optionDeleteHandler,
    setSelectedFormData,
}) => {

    const updateOptionsAPI = useUpdateOptionsAPI();

    const [editOption, setEditOption] = useState<boolean>(false);
    const [alertModelVisible, setAlertModelVisible] = useState<boolean>(false);

    const [optionName, setOptionName] = useState<string | any>(option?.option_name);
    const [selectOptionName, setSelectOptionName] = useState<any>({label: option?.question_value_type, value: option?.question_value_type});
    const [minValue, setMinValue] = useState<string | undefined | any>(option?.min_value);
    const [maxValue, setMaxValue] = useState<string | undefined | any>(option?.max_value);
    const [otherValue, setOtherValue] = useState<string | undefined | any>(option?.other_value);

    const optionSelectionList = [
        {label: "Range", value: "Range"},
        // {label: "Percentage", value: "Percentage"},
        {label: "Cost", value: "Cost"},
    ]

    const updateOption = (optionId: number | any, updatedData: optionsDataType) => {
        setSelectedFormData((selectedFormData: costFormDataType) => {
            return {
                ...selectedFormData,
                services: selectedFormData.services.map((service:any) => ({
                    ...service,
                    questions: service.questions.map((question: any) => ({
                        ...question,
                        options: question.options.map((option: any) =>
                            option.id === optionId
                                ? { ...option, ...updatedData } // Update the option directly
                                : option
                        )
                    }))
                }))
            };
        });
    };
    

    const updateOptionHandler = async (option: optionsDataType | any) => {
        const formData = new FormData();
        if (option?.question_id) {
            if (optionName !== option?.option_name) {
                formData.append('option_name', optionName)
            }
            if (selectOptionName) {
                if (selectOptionName?.value === option?.question_value_type) {
                    formData.append('question_value_type', selectOptionName?.value)
                }
                if (selectOptionName?.value === "Range") {
                    minValue !== option?.min_value && formData.append("min_value", minValue)
                    maxValue !== option?.max_value && formData.append("max_value", maxValue)
                } else {
                    otherValue !== option?.other_value && formData.append("other_value", otherValue)
                }
            }
            const { data, error } = await updateOptionsAPI.updateOptionsAPI(
                formData,
                String(option?.id)
            );
            updateOption(option?.question_id, data)
            setEditOption(false)
        } 
    }

    const alertSubmit = () => {
        optionDeleteHandler(option)
        setAlertModelVisible(false)
    }

    const alertCancle = () => {
        setAlertModelVisible(false)
    }

    return (
        <>
            <div className="w-full flex flex-col gap-2">
                <div className="w-full border-[2px] border-[#6558f531] px-2 py-2 gap-2 xl:gap-4 rounded flex items-center xl:items-start">
                    <div className="w-full flex xl:flex-col flex-row gap-2 items-center xl:items-stretch max-w-[calc(100%_-_88px)]">
                        <div className="w-full font-medium text-sm">
                            {/* {option?.option_name} */}
                            <InputField
                                fieldWrapperClassName="mb-0"
                                wrapperClass={`w-full shrink-0 ${editOption ? "" : "no-edit"}`}
                                name="option_name"
                                value={optionName}
                                onChange={(e) => setOptionName(e.target.value)}
                                // label="Option Name"
                                placeholder="Option Name"
                                // type="number"
                                disabled={!editOption}
                            />
                        </div>
                        <div className="w-fit flex gap-2">
                            <SelectField
                                name="defaultValSelect"
                                // fromGroupClass="!w-[150px] shrink-0 !mb-0 cost-select"
                                fromGroupClass={`!w-[150px] shrink-0 !mb-0 cost-select ${editOption ? "" : "no-edit"}`}
                                wrapperClass={`${editOption ? "" : "no-edit"}`}
                                placeholder="Select Value Type"
                                options={optionSelectionList}
                                value={selectOptionName}
                                onChange={(e: any) => {
                                    setSelectOptionName(e);
                                }}
                                disabled={!editOption}
                                // setInputValue={}
                            />
                            {selectOptionName && selectOptionName?.value === "Range" ? (
                                <>
                                <div className="w-fit flex gap-1 items-center">
                                    <span className="font-medium text-sm">
                                        Min
                                    </span>
                                    <InputField
                                        fieldWrapperClassName="mb-0"
                                        wrapperClass={`w-[80px] shrink-0 ${editOption ? "" : "no-edit"}`}
                                        className="no-spinner"
                                        name="min_value"
                                        value={minValue}
                                        onChange={(e) => setMinValue(e.target.value)}
                                        placeholder="$0"
                                        type="number"
                                        disabled={!editOption}
                                        onKeyDown={(e) => {
                                            if (!/[0-9]/.test(e.key) && e.key !== "Backspace") {
                                                e.preventDefault();
                                            }
                                        }}
                                    />
                                </div>
                                <div className="w-fit flex gap-1 items-center">
                                    <span className="font-medium text-sm">
                                        Max
                                    </span>
                                    <InputField
                                        fieldWrapperClassName="mb-0"
                                        wrapperClass={`w-[80px] shrink-0 ${editOption ? "" : "no-edit"}`}
                                        className="no-spinner"
                                        name="max_value"
                                        value={maxValue}
                                        onChange={(e) => setMaxValue(e.target.value)}
                                        placeholder="$100"
                                        type="number"
                                        disabled={!editOption}
                                        onKeyDown={(e) => {
                                            if (!/[0-9]/.test(e.key) && e.key !== "Backspace") {
                                                e.preventDefault();
                                            }
                                        }}
                                    />
                                </div>
                                </>
                            ) : (
                                <>
                                <div className="w-fit flex gap-1 items-center">
                                    <span className="font-medium text-sm">
                                        Other
                                    </span>
                                    <InputField
                                        fieldWrapperClassName="mb-0"
                                        wrapperClass={`w-[80px] shrink-0 ${editOption ? "" : "no-edit"}`}
                                        className="no-spinner"
                                        name="other_value"
                                        value={otherValue}
                                        onChange={(e) => setOtherValue(e.target.value)}
                                        placeholder="$100"
                                        type="number"
                                        disabled={!editOption}
                                        onKeyDown={(e) => {
                                            if (!/[0-9]/.test(e.key) && e.key !== "Backspace") {
                                                e.preventDefault();
                                            }
                                        }}
                                    />
                                </div>
                                </>
                            )}
                        </div>
                    </div>
                    <div className="w-fit flex items-center gap-2 xl:mt-1">
                        {editOption ? (
                            <>
                                <button
                                    title="Save"
                                    className="size-8 flex items-center justify-center shrink-0 border border-indigo-700 text-white bg-indigo-600 rounded"
                                    onClick={() => updateOptionHandler(option)}
                                >
                                    <Icon.Save className="size-4" />
                                </button>
                            </>
                        ) : (
                            <button
                                title="Edit"
                                className="border border-gray-500 flex hover:bg-indigo-600 hover:border-indigo-700 hover:text-white items-center justify-center rounded shrink-0 size-8 text-gray-500"
                                onClick={() => setEditOption(true)}
                            >
                                <Icon.Edit className="size-4" />
                            </button>    
                        )}
                        
                        <button
                            title=""
                            className="size-8 flex items-center justify-center shrink-0 bg-[#ee5555] text-white rounded"
                            // onClick={() => {optionDeleteHandler(option)}}
                            onClick={() => setAlertModelVisible(true)}
                        >
                            <svg
                                className="size-4"
                                width="24"
                                height="24"
                                viewBox="0 0 15 15"
                                fill="currentColor"
                            >
                                <path d="M2.64 1.27 7.5 6.13l4.84-4.84A.92.92 0 0 1 13 1a1 1 0 0 1 1 1 .9.9 0 0 1-.27.66L8.84 7.5l4.89 4.89A.9.9 0 0 1 14 13a1 1 0 0 1-1 1 .92.92 0 0 1-.69-.27L7.5 8.87l-4.85 4.85A.92.92 0 0 1 2 14a1 1 0 0 1-1-1 .9.9 0 0 1 .27-.66L6.16 7.5 1.27 2.61A.9.9 0 0 1 1 2a1 1 0 0 1 1-1c.24.003.47.1.64.27" />
                            </svg>
                        </button>
                    </div>
                </div>
            </div>
            <AlertModal
                visible={alertModelVisible}
                showCrossIcon
                onSubmit={alertSubmit}
                onCancel={alertCancle}
                onClose={alertCancle}
                submitButtonText="Yes"
                // secondaryLoading={updateServicesApi?.isLoading}
            >
                <p className="text-md mb-3 flex ">
                    <span>
                    Are you sure you want to delete this option ?
                    </span>
                </p>
            </AlertModal>
        </>
    )
}

export default OptionCard
