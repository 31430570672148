// ** hooks **
import { AxiosRequestConfig } from "axios";
import { useAxiosGet, useAxiosPost, useAxiosPut } from "hooks/useaxios";

const PATIENT_API_BASE_PATH = "/patients";

export const usePatientListAPI = () => {
  // ** Custom Hooks **
  const [callApi, { isLoading, isError, isSuccess }] = useAxiosGet();

  const getPatientListApi = async (data: object) => {
    return callApi(`${PATIENT_API_BASE_PATH}/get_all_patients`, data);
  };

  return { getPatientListApi, isLoading, isError, isSuccess };
};

export const useAddPatientAPI = () => {
  const [callApi, { isLoading, isError, isSuccess }] = useAxiosPost();
  const addPatientApi = async (
    data: object,
    config: AxiosRequestConfig<object> = {}
  ) => {
    return callApi(`${PATIENT_API_BASE_PATH}/add_patient`, data, config);
  };
  return { addPatientApi, isLoading, isError, isSuccess };
};

export const useGetPatientAPI = () => {
  // ** Custom Hooks **
  const [callApi, { isLoading, isError, isSuccess }] = useAxiosGet();

  const getPatientApi = async (id: number) => {
    return callApi(`${PATIENT_API_BASE_PATH}/patient/${id}`);
  };

  return { getPatientApi, isLoading, isError, isSuccess };
};

export const useUpdatePatientAPI = () => {
  const [callApi, { isLoading, isError, isSuccess }] = useAxiosPut();
  const updatePatientAPI = async (data: object, user_id: number) => {
    const response = await callApi(
      `${PATIENT_API_BASE_PATH}/update_patient/${user_id}`,
      data
    );
    return response;
  };

  return { updatePatientAPI, isLoading, isError, isSuccess };
};

export const useGetAppointmentAPI = () => {
  // ** Custom Hooks **
  const [callApi, { isLoading, isError, isSuccess }] = useAxiosGet();

  const getAppointmentApi = async (data: object) => {
    return callApi(
      `${PATIENT_API_BASE_PATH}/get_all_appointment_by_patient`,
      data
    );
  };

  return { getAppointmentApi, isLoading, isError, isSuccess };
};

export const usePatientTranscriptListAPI = (id: number | string) => {
  // ** Custom Hooks **
  const [callApi, { isLoading, isError, isSuccess }] = useAxiosGet();

  const getPatientTranscriptGetAPI = async (data: object) => {
    return callApi(
      `${PATIENT_API_BASE_PATH}/get_transcript_by_patient/${id}`,
      data
    );
  };

  return { getPatientTranscriptGetAPI, isLoading, isError, isSuccess };
};

export const useDeleteAttachmentAPI = () => {
  const [callApi, { isLoading, isError, isSuccess }] = useAxiosPut();
  const deleteAttachmentAPI = async (data: object, user_id: number) => {
    const response = await callApi(
      `${PATIENT_API_BASE_PATH}/delete_attachment/${user_id}`,
      data
    );
    return response;
  };

  return { deleteAttachmentAPI, isLoading, isError, isSuccess };
};

export const useAllDeletePatientsAPI = () => {
  const [callApi, { isLoading, isError, isSuccess }] = useAxiosPost();
  const allDeletePatientsAPI = async (select_delete_list: number[]) => {
    return callApi(
      `${PATIENT_API_BASE_PATH}/select_all_delete_patients`,
      {select_delete_list}
    );
  };
  return { allDeletePatientsAPI, isLoading, isError, isSuccess };
};