export const PERMISSIONS = {
  USER: {
    CREATE: { name: "create", type: "users" },
    READ: { name: "read", type: "users" },
    UPDATE: { name: "update", type: "users" },
    DELETE: { name: "delete", type: "users" },
  },
  PATIENT: {
    CREATE: { name: "create", type: "patient" },
    READ: { name: "read", type: "patient" },
    UPDATE: { name: "update", type: "patient" },
    DELETE: { name: "delete", type: "patient" },
  },
  TRANSCRIPTAI: {
    WHISPER: { name: "whisper", type: "TranscriptAI" },
    ASSEMBLYAI: { name: "assemblyai", type: "TranscriptAI" },
    AMAZON: { name: "amazon", type: "TranscriptAI" },
  },
  NOTES: {
    CREATE: { name: "create", type: "notes" },
    READ: { name: "read", type: "notes" },
    UPDATE: { name: "update", type: "notes" },
    DELETE: { name: "delete", type: "notes" },
  },
  NOTESAI: {
    ANTHROPIC: { name: "anthropic", type: "NotesAI" },
    Claude_3_OpusAI: { name: "Claude 3 Opus", type: "NotesAI" },
    Claude_3_SonnetAI: { name: "Claude 3 Sonnet", type: "NotesAI" },
    Claude_3_HaikuAI: { name: "Claude 3 Haiku", type: "NotesAI" },
    Claude_21AI: { name: "Claude 2.1", type: "NotesAI" },
    Claude_2AI: { name: "Claude 2", type: "NotesAI" },
    Claude_Instant_12Ai: { name: "Claude Instant 1.2", type: "NotesAI" },
  },
  ROLE: {
    CREATE: { name: "create", type: "role" },
    READ: { name: "read", type: "role" },
    UPDATE: { name: "update", type: "role" },
    DELETE: { name: "delete", type: "role" },
  },
  CUSTOMER: {
    CREATE: { name: "create", type: "customer" },
    READ: { name: "read", type: "customer" },
    UPDATE: { name: "update", type: "customer" },
    DELETE: { name: "delete", type: "customer" },
  },
  CUSTOM_PROMPT: {
    CREATE: { name: "create", type: "custom prompt" },
    READ: { name: "read", type: "custom prompt" },
    UPDATE: { name: "update", type: "custom prompt" },
    DELETE: { name: "delete", type: "custom prompt" },
  },
  PLAYBOOK: {
    CREATE: { name: "create", type: "playbook" },
    READ: { name: "read", type: "playbook" },
    UPDATE: { name: "update", type: "playbook" },
    DELETE: { name: "delete", type: "playbook" },
  },
  SUBSCRIPTION: {
    CREATE: { name: "create", type: "subscription" },
  },
  PLAN: {
    CREATE: { name: "create", type: "plan" },
    READ: { name: "read", type: "plan" },
    UPDATE: { name: "update", type: "plan" },
  },
  MEETING : {
    CREATE: { name: "create", type: "meeting folder" },
    READ: { name: "read", type: "meeting folder" },
    UPDATE: { name: "update", type: "meeting folder" },
    DELETE: { name: "delete", type: "meeting folder" },
  }
};
