import React, { useState, useRef } from 'react';
import { X } from 'react-feather';

export interface MultiSelectOptionTypes {
    title?: string;
    label?: string;
    value: string;
}

interface MultiSelectProps {
    options: MultiSelectOptionTypes[];
    selectedValue: MultiSelectOptionTypes[];
    setSelectedValue : React.Dispatch<React.SetStateAction<MultiSelectOptionTypes[] | null>>;
    setShowError: React.Dispatch<React.SetStateAction<boolean>>;
}

const MultiSelect: React.FC<MultiSelectProps> = ({ options, selectedValue, setSelectedValue, setShowError }) => {
    const [selectedOptions, setSelectedOptions] = useState<MultiSelectOptionTypes[]>(selectedValue ?? []);
    const [isOpen, setIsOpen] = useState(false);
    const dropdownRef = useRef<HTMLDivElement>(null);

    const handleOptionSelect = (option: MultiSelectOptionTypes, isRemove:boolean = false) => {
        if (selectedOptions?.some((opt) => opt.value === option.value)) {
            if(isRemove){
                setSelectedOptions(selectedOptions.filter((opt) => opt.value !== option.value));
                setSelectedValue(selectedValue.filter((opt) => opt.value !== option.value));
                selectedValue?.length === 0 && setShowError(true)
            }
        } 
        else {
            selectedValue?.length > 0 && setShowError(false)
            setSelectedOptions([...selectedOptions, option]);
            setSelectedValue([...selectedOptions, option])
        }
    };

    const toggleDropdown = () => {
        setIsOpen(!isOpen);
    };

    const handleClickOutside = (event: MouseEvent) => {
        if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
            setIsOpen(false);
        }
    };

    React.useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    return (
        <div className="relative select-none" ref={dropdownRef}>
            <div
                className="flex items-center justify-between px-4 py-2 bg-white border border-gray-300 rounded cursor-pointer"
                onClick={toggleDropdown}
            >   
            <>
                <div className='flex flex-wrap gap-2 overflow-y-clip'>
                    {selectedOptions && selectedOptions?.length === 0 ? (
                        <span className="text-gray-500">Select options</span>
                    ) : (
                        selectedOptions?.map((option, index) => (
                            <span key={`${option?.value}-${index}`} className="mr-2 px-2 py-1 bg-blue-200 text-blue-800 rounded">
                                {option?.title ?? option?.label}
                            </span>
                        ))
                    )}
                </div>
                    <span className="text-gray-500">{isOpen ? '▲' : '▼'}</span>
            </>
            </div>
            {isOpen && (
                <ul className="absolute z-10 w-full bg-white border border-gray-300 rounded mt-2">
                    {options?.map((option, index) => (
                        <div key={`${index}-${option?.value}`}>
                            <li
                                className={`${(options && options?.length - 1 === index && "rounded-b") || (index === 0 && "rounded-t")} flex justify-between px-4 py-2 cursor-pointer hover:bg-[#877eec] ${selectedOptions?.some((opt) => opt.value === option.value) ? 'bg-[#6558F5] text-white' : ''
                                    }`}
                                onClick={() => handleOptionSelect(option)}
                            >
                                <span className='w-[90%]'>{option?.title ?? option?.label}</span>
                                {selectedOptions?.some((opt) => opt.value === option.value) && (
                                    <X className='justify-end w-4 hover:opacity-45 hover:bg-[#5346df] hover:rounded-md h-4 ml-auto my-auto'
                                        onClick={(e) => {
                                            e.stopPropagation(); // Prevents parent onClick from firing
                                            handleOptionSelect(option, true);
                                        }} />
                                )}
                            </li>
                            <hr />
                        </div>
                    ))}
                </ul>
            )}

            {/* {isOpen && (
                <ul className="absolute z-10 w-full bg-white border border-gray-300 rounded mt-2">
                    {options?.map((option, index) => (
                        <div key={`${index}-${option?.value}`}>
                            <li
                                className={`${(options && options?.length-1 === index  && "rounded-b" )||(index === 0 && "rounded-t")} flex justify-center px-4 py-2 cursor-pointer hover:bg-[#877eec] ${selectedOptions?.some((opt) => opt.value === option.value) ? 'bg-[#6558F5] text-white' : ''
                                    }`}
                                onClick={() => handleOptionSelect(option)}
                            >
                                <span className='w-[90%]'>{option?.title ?? option?.label}</span>
                                <X className='justify-end w-4 hover:opacity-45 hover:bg-[#5346df] hover:rounded-md h-4 m-auto' onClick={() => handleOptionSelect(option, true)}/>
                            </li>
                            <hr/>
                        </div>
                    ))}
                </ul>
            )} */}
        </div>
    );
};

export default MultiSelect;