import { IconInputProps } from ".";

export const DownloadIcon2 = ({ className, styleProps }: IconInputProps) => {
  return (
    <svg className={className ?? ''} width="28" height="29" viewBox="0 0 28 29" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path style={styleProps ?? {}} fill-rule="evenodd" clip-rule="evenodd" d="M12.2842 20.3254V0H14.9942V20.3254H12.2842Z" fill="white"/>
      <path style={styleProps ?? {}} fill-rule="evenodd" clip-rule="evenodd" d="M13.6402 18.4094L6.4681 11.2373L4.55176 13.1537L12.682 21.2839C13.211 21.8129 14.0688 21.8129 14.5984 21.2839L22.7286 13.1537L20.8123 11.2373L13.6402 18.4094Z" fill="white"/>
      <path style={styleProps ?? {}} fill-rule="evenodd" clip-rule="evenodd" d="M27.1903 28.4542H0.0898438V25.7441H27.1903V28.4542Z" fill="white"/>
    </svg>
  )
}
