import React, { useState } from "react";
import { costFormDataType, formServicesDataType } from "../../../types";
import StepButton from "../StepButton";
import Button from "components/Theme/Button";
import * as Icon from "react-feather";
import ServicesCard from "./components/ServiceCard";
import AlertModal from "components/modal/AlertModal";

interface FormStep4Props {
  currentFormStep?: string;
  setCurrentFormStep?: ((value: string) => void) | undefined | any;
  selectedFormData?: costFormDataType | null;
  setSelectedFormData?: any;
  setIsFormOpen?: any;
}

const FormStep4: React.FC<FormStep4Props> = ({
  currentFormStep,
  setCurrentFormStep,
  selectedFormData,
  setSelectedFormData,
  setIsFormOpen
}) => {

  const [alertModelVisible, setAlertModelVisible] = useState<boolean>(false);


  const checkQuestionOptionsValidation = () => {
    if (selectedFormData?.id) {
      for (let service of selectedFormData?.services) {
        if (service?.questions && service?.questions?.length < 1) {
          setAlertModelVisible(true)
          return true
          // break; // Exit loop when the number is found
        }
      }
      return false
      // setCurrentFormStep("Step 5")
    }
  }

  return (
    <>
      <div className="size-full flex flex-col overflow-auto md:py-5 py-10 md:px-0 px-10">
        <Button
          className="w-fit h-9 px-1 mb-5"
          onClick={() => setCurrentFormStep("Step 3")}
        >
          <span className="flex items-center gap-2">
            <Icon.ArrowLeft />
            Back to Services
          </span>
        </Button>
        <div className="w-full md:text-2xl text-3xl font-medium mb-4">
          Configure Your Pricing (Step 4 of 5)
        </div>
        <div className="w-full md:text-sm text-base text-gray-500 mb-3">
          Edit, add, or remove services, questions, and answers to reflect how
          you calculate prices for each service.
        </div>
        <div className="w-full md:text-sm text-base text-gray-500 mb-8">
          <span className="font-medium mr-1">Need help ?</span>
          <a href="" className="underline mr-1">
            Click here
          </a>
          to view our How To guide
        </div>
        {/* <div className="flex-grow flex flex-col gap-8 max-w-[992px] mx-auto w-full overflow-auto"> */}
        <div className="flex-grow flex flex-col gap-8 max-w-[992px] mx-auto w-full">

          {selectedFormData && selectedFormData?.services?.map((serviceItem: formServicesDataType, index: number) => {
            return (
              <>
                <ServicesCard 
                  serviceItem={serviceItem}
                  selectedFormData={selectedFormData}
                  setSelectedFormData={setSelectedFormData}
                />
              </>
            );
          })}

          <StepButton
            prevButtonLable="Prev to Services"
            nextButtonLable="Next : Result Page"
            nextButtonDisable={false}
            handlePrevClick={() => {
              setCurrentFormStep("Step 3")
            }}
            handleNextClick={() => {
              const is_valid = checkQuestionOptionsValidation()
              if (is_valid) {
                setAlertModelVisible(true)
              } else {
                setCurrentFormStep("Step 5")
              }
              // setIsOpen(true)
            }}
          />
        </div>
      </div>
      {alertModelVisible && 
      <AlertModal
          visible={alertModelVisible}
          showCrossIcon
          onSubmit={() => setAlertModelVisible(false)}
          onClose={() => setAlertModelVisible(false)}
          showCancelButton={false}
          submitButtonText="Done"
          // secondaryLoading={updateServicesApi?.isLoading}
      >
          <p className="text-md mb-3 flex ">
              <span>
                Some Services have no questions added !!
              </span>
          </p>
      </AlertModal>
      }
    </>
  );
};

export default FormStep4;
