import { RestartIcon, CheckboxIcon } from "components/Icon/SvgIcon";
import StepButton from "modules/CostCalculator/sub-module/CostForm/StepButton";
import { createdType, optionsDataType } from "modules/CostCalculator/types";
import * as Icon from "react-feather";
import ReactPlayer from "react-player";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { 
    getUserCostForm,
    setSelectedServices, 
    setAnswer,
    nextStep,
    prevStep,
    setUserDetails,
    resetForm,
} from "redux/slices/userFormSlice";
import Note from "components/Note";
import { Modal } from "components/Theme/Modal";
import InputField from "components/FormField/common/inputField";


interface QuestionPageProps {
    questionsWithOptions: any
    themeData:createdType | null
}


const QuestionPage:React.FC<QuestionPageProps> = ({
    questionsWithOptions,
    themeData
}) => {

    const dispatch = useDispatch();

    const { currentStep, selectedServices, answers, userDetails } = useSelector(getUserCostForm);

    const [modelData, setModelData] = useState<string>("");
    const [userValue, setUserValue] = useState<string | number>(0);

    // const handleAnswerSelect = (questionId: any, option: any) => {
    //     dispatch(setAnswer({ questionId, option }));
    // };


    const handleAnswerSelect = (questionId: any, option: any) => {
        const allowMultiple = questionsWithOptions[currentStep -1]?.allow_multiple_selections
        const isNumberField = questionsWithOptions[currentStep -1]?.is_number_field
        dispatch(setAnswer({ questionId, option, allowMultiple, isNumberField }));
    };

    const handleResetForm = () => {
        dispatch(resetForm());
    };

    return (
        <>
            <div className="flex flex-row justify-between">
                <div 
                    className="border-[#6558F5] border bg-[#6558F5] p-2 items-center justify-center text-white gap-2 text-white px-4 py-2 text-sm rounded-md"
                    style={{
                        backgroundColor: themeData?.button_color ? themeData?.button_color : "#6558f5",
                        color: themeData?.button_text_color ? themeData?.button_text_color : "#ffffff",
                      }}
                    >Question : {currentStep} / {questionsWithOptions.length}</div>
                <div>
                    <button 
                        className="bg-[#6558F5] border-[#6558F5] border flex gap-2 items-center mx-auto text-white px-4 py-2 text-sm rounded-md"
                        style={{
                            backgroundColor: themeData?.button_color ? themeData?.button_color : "#6558f5",
                            color: themeData?.button_text_color ? themeData?.button_text_color : "#ffffff",
                          }}
                        onClick={() => handleResetForm()}
                    >
                        <RestartIcon className="w-5 h-5 white-icon" styleProps={
                             themeData?.button_text_color ? { fill:  themeData?.button_text_color } : { fill : "#ffffff"}
                          }></RestartIcon> Start Again
                    </button>
                </div>
            </div>
            <div className="flex-grow flex flex-col gap-4 mx-auto w-full mt-8">
                <div 
                    className="w-full text-md text-textDark font-normal text-center"
                    style={{color: themeData?.font_paragraph_color ? themeData?.font_paragraph_color : "#2d3849"}}
                    >
                    {questionsWithOptions[currentStep -1]?.service?.name}
                </div>
                <div 
                    className="w-full text-4xl text-textDark font-semibold text-center"
                    style={{color: themeData?.font_heading_color ? themeData?.font_heading_color : "#2d3849"}}
                    >
                    {questionsWithOptions[currentStep -1]?.question_name}
                </div>
                {questionsWithOptions[currentStep -1]?.question_subtitle && (
                <div 
                    className="w-full text-sm text-textDark font-medium text-center"
                    style={{color: themeData?.font_paragraph_color ? themeData?.font_paragraph_color : "#2d3849"}}
                    >
                    {questionsWithOptions[currentStep -1]?.question_subtitle}
                </div>
                )}
            </div>
            {(questionsWithOptions[currentStep -1]?.learn_more_content || questionsWithOptions[currentStep -1]?.video_url) && (
                <>
                <div className="flex justify-center">
                    {questionsWithOptions[currentStep -1]?.learn_more_content ? (
                        <div className="px-1">
                            <button 
                                className="bg-[#6558F5] border-[#6558F5] border flex gap-2 items-center mx-auto text-white px-4 py-2 text-sm rounded-md"
                                style={{
                                    backgroundColor: themeData?.button_color ? themeData?.button_color : "#6558f5",
                                    color: themeData?.button_text_color ? themeData?.button_text_color : "#ffffff",
                                  }}
                                onClick={() => {setModelData("Learn More")}}
                            >
                                <Icon.Info className="size-4" /> Learn More
                            </button>
                        </div>
                    ) : (
                        <div className="px-1">
                        </div>
                    )}
                    {questionsWithOptions[currentStep -1]?.video_url ? (
                        <div className="px-1">
                            <button 
                                className="bg-[#6558F5] border-[#6558F5] border flex gap-2 items-center mx-auto text-white px-4 py-2 text-sm rounded-md"
                                style={{
                                    backgroundColor: themeData?.button_color ? themeData?.button_color : "#6558f5",
                                    color: themeData?.button_text_color ? themeData?.button_text_color : "#ffffff",
                                  }}
                                onClick={() => {setModelData("Watch Video")}}
                            >
                                <Icon.Video className="size-4" /> Watch Video
                            </button>
                        </div>
                    ) : (
                        <div className="px-1">
                        </div>
                    )}
                </div>
                </>
            )}     
            <div className="flex-grow  flex flex-col gap-8 overflow-hidden mt-8">
                <div className="w-full max-h-full flex flex-wrap gap-4 justify-center overflow-auto pr-3">
                    {questionsWithOptions && questionsWithOptions[currentStep -1]?.is_number_field ? (
                        <>
                            <div className="items-center flex">
                            <div className="w-[300px] flex gap-1 items-center">
                                {/* <span className="font-medium text-sm">
                                    User Value
                                </span> */}
                                <InputField
                                    fieldWrapperClassName="mb-0"
                                    wrapperClass={`w-full shrink-0`}
                                    // className="no-spinner"
                                    name="max_value"
                                    value={userValue}
                                    onChange={(e) => {
                                        setUserValue(e.target.value)
                                        handleAnswerSelect(questionsWithOptions[currentStep -1]?.id, e.target.value)
                                    }}
                                    placeholder="$100"
                                    type="number"
                                />
                                
                            </div>
                            <div 
                                className="font-medium text-sm ml-2 text-md"
                                style={{color: themeData?.font_paragraph_color ? themeData?.font_paragraph_color : "#2d3849"}}
                                >
                                {userValue && Number(userValue) > 1 ? questionsWithOptions[currentStep -1]?.plural_value : questionsWithOptions[currentStep -1]?.singular_value}
                            </div>
                            </div>
                        </>
                    ) : (

                   <>
                    {questionsWithOptions && questionsWithOptions[currentStep -1]?.options?.map((option: optionsDataType, index: number) => (
                        <div
                        key={index}
                        className={`w-full group max-w-[316px] bg-white ${answers[questionsWithOptions[currentStep -1]?.id]?.includes(option?.id)
                            ? "border-[3px] border-primaryColor"
                            : "border border-gray-500"
                            } rounded-md flex flex-col items-center justify-center cursor-pointer py-8 px-5 gap-8 text-center hover:shadow-md`}
                        style={{borderColor: themeData?.primary_brand_color ? themeData?.primary_brand_color: "#6558f5",}}
                        onClick={() => handleAnswerSelect(questionsWithOptions[currentStep -1]?.id, option?.id)}
                        // onClick={() => sampleFunction(questionsWithOptions[currentStep], option)}
                        >
                        <div 
                            // className="w-8 h-8 border-[2px] border-textDark rounded-full flex justify-center items-center hover:scale-110 transition-all duration-200"
                            className={`w-8 h-8 rounded-full flex justify-center items-center transition-all duration-200 border-[2px] border-textDark group-hover:scale-110 ${answers[questionsWithOptions[currentStep -1]?.id] === option ? "bg-primaryColor" : ""}`}
                            style={{backgroundColor: answers[questionsWithOptions[currentStep -1]?.id]?.includes(option?.id) ? themeData?.primary_brand_color: "#ffffff"}}
                        >
                            <CheckboxIcon className="w-4"></CheckboxIcon>
                        </div>
                        <div className="w-full flex items-center justify-center">
                            <label
                            htmlFor="default-radio-1"
                            className="text-gray-900 dark:text-gray-600 w-full text-base font-medium truncate"
                            style={{color: themeData?.font_paragraph_color ? themeData?.font_paragraph_color: "#2d3849"}}
                            >
                            {option?.option_name}
                            </label>
                        </div>
                        </div>
                    ))}
                </>
                )}
                </div>
            </div>
            <StepButton
                // currentFormStep={currentFormStep}
                // setCurrentFormStep={setCurrentFormStep}
                // selectedMarketId={selectedMarketId}
                // prevButtonLable="Industry"
                // nextButtonLable="Service"
                nextButtonDisable={!answers[questionsWithOptions[currentStep -1]?.id]}
                handlePrevClick={() => dispatch(prevStep())}
                handleNextClick={() => dispatch(nextStep())}
                buttonStyleProps={{
                    backgroundColor: themeData?.button_color ? themeData?.button_color : "#6558f5",
                    color: themeData?.button_text_color ? themeData?.button_text_color : "#ffffff",
                }}

            />
            {modelData !== "" && (
                <Modal
                    onSubmit={() => setModelData("")}
                    submitButtonName="Done"
                    headerName={modelData === "Learn More" ? "Learn More" : "Watch Video"}
                    modalWidth="800px"
                    onClose={() => setModelData("")}
                >
                    {modelData === "Learn More" ? (
                        <p className="text-md mb-3 flex justify-center">
                            <Note
                                content={questionsWithOptions[currentStep -1]?.learn_more_content}
                                isEdit={false}
                                EditorContentClassName="w-full h-full shadow-none"
                                mainDivClassName="w-full"
                                contentId={questionsWithOptions[currentStep -1]}
                                hasUpdatePermission={true}
                            />
                        </p>
                    ) : (
                        <p className="text-md mb-3 flex justify-center">
                            <ReactPlayer
                                url={questionsWithOptions[currentStep -1]?.video_url}
                                controls
                                width="640px"
                                height="360px"
                            />
                        </p>
                    )}
                    
                </Modal>
            )}
        </>
    )
}

export default QuestionPage