import { useAddUserCostFormResponseAPI } from "modules/CostCalculator/services";
import { costFormDataType, createdType } from "modules/CostCalculator/types";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { 
    getUserCostForm,
    setUserDetails
} from "redux/slices/userFormSlice";
import EstimatorPage from "../../../EstimatorPage";
import AlertModal from "components/modal/AlertModal";
import PageLoader from "components/Loaders/PageLoader";
import { Loader } from "react-feather";


interface UserDetailPageProps {
  selectedFormData?: costFormDataType | null;
  setSelectedFormData?: any;
  themeData?: createdType | null;
}


const UserDetailPage: React.FC<UserDetailPageProps> = ({
  selectedFormData,
  setSelectedFormData,
  themeData,
}) => {

    const dispatch = useDispatch();

    const { selectedServices, answers, userDetails } = useSelector(getUserCostForm);

    const [openEstimatorPage, setOpenEstimatorPage] = useState<boolean>(false)
    const [userResponseId, setUserResponseId] = useState<number>()
    const [alertModelVisible, setAlertModelVisible] = useState<boolean>(false);

    const [isLoading, setLoading] = useState<boolean>(false);
    const [isDisabled, setDisabled] = useState<boolean>(false);
    const [emptyError, setEmptyError] = useState<boolean>(false);

    const [formData, setFormData] = useState({
      name: "",
      email: "",
      phone: "",
      zip: "",
    });
  
    const [errors, setErrors] = useState<any>({});


    // const handleUserDetailsChange = (e:any) => {
    //     dispatch(setUserDetails({ [e.target.name]: e.target.value }));
    // };

    const addUserCostFormAPI = useAddUserCostFormResponseAPI();

    const handleSubmit = async (userData: any) => {
        setLoading(true)
        const responseData = { answers, userDetails, selectedServices };
        const formData = new FormData();
        if (responseData) {
            if (selectedFormData?.id) {
              formData.append('form_id', String(selectedFormData?.id))
              formData.append('email', String(selectedFormData?.created?.email))
              formData.append('first_name', String(selectedFormData?.created?.first_name))
              formData.append('last_name', String(selectedFormData?.created?.last_name))
            } 
            if (responseData?.answers) {
                formData.append('user_answers', JSON.stringify(responseData?.answers))
            } 
            // if (responseData?.userDetails) {
            //     formData.append('userDetails', JSON.stringify(responseData?.userDetails))
            // }
            if (userData) {
                formData.append('userDetails', JSON.stringify(userData))
            }
            if (responseData?.selectedServices) {
                formData.append('selectedServices', JSON.stringify(responseData?.selectedServices))
            }
            const { data, error } = await addUserCostFormAPI.addUserCostFormAPI(formData);
            if (!error) {
              setLoading(false)
              setDisabled(false)
              setAlertModelVisible(true)
              setUserResponseId(data?.id)
              setOpenEstimatorPage(true)
            }
            setLoading(false)
            setDisabled(false)

        }
        setLoading(false)
        setDisabled(false)
    };

    const validationRules:any = {
      name: /^[a-zA-Z\s]{1,30}$/, // Only letters, min 3 characters
      email: /^[^\s@]+@[^\s@]+\.[^\s@]+$/, // Basic email regex
      phone:  /^(\+?\d{1,3})?[-.\s]?(\(?\d{3}\)?)?[-.\s]?\d{3}[-.\s]?\d{4}$/,
      zip: /^[0-9]*$/, // 5-digit zip code
    };
  
    const handleChange = (e:any) => {
      const { name, value } = e.target;
      setFormData({ ...formData, [name]: value });
  
      if (!validationRules[name].test(value)) {
        if (name === "name") {
          setErrors({ ...errors, [name]: `Please Enter your ${name}` });  
        } else {
          setErrors({ ...errors, [name]: `Invalid ${name}` });
        }
      } else {
        setEmptyError(false)
        const newErrors = { ...errors };
        delete newErrors[name]; // Remove error if valid
        setErrors(newErrors);
      }
    };

    const newhandleSubmit = (e:any) => {
      e.preventDefault();
      setLoading(true)
      if (Object.keys(errors).length === 0 && Object.values(formData).every((val) => val !== "")) {
        setDisabled(true)
        dispatch(setUserDetails(formData));
        handleSubmit(formData)
        setLoading(false)
      } else {
        // alert("Please fix validation errors before submitting.");
        setLoading(false)
        setEmptyError(true)
      }
      setLoading(false)
    };
  

    return (
        <>
            {/* {isLoading ? ( */}
              {isLoading && (
                <div className="justify-center">
                  <PageLoader />
                </div>
              )}
            {/* ) : ( */}
              <>
            
            {openEstimatorPage ? (
              <EstimatorPage 
                userResponseId={userResponseId}
                isUser={true}
              />
            ) : (
              <>
              <div className="h-screen md:py-5 py-10 md:px-0 px-10  flex items-center justify-center">
                <div>
                  <form onSubmit={newhandleSubmit}>
                  <div className="flex flex-col justify-center items-center gap-5">
                    <div className="bg-white py-10 px-14 rounded-lg shadow-md">
                      <div className="mb-5 justify-center flex items-center gap-2">
                        <p className="text-xl md:text-base text-[#6588f8] text-center ">You are almost there </p>
                        <img src="/images/party-popper.png" className="w-full max-w-5 md:max-w-4"/>
                      </div>
                      <h3 
                        className="text-center font-medium text-xl md:text-base text-indigo-600 mb-8"
                        style={{color: themeData?.font_heading_color ? themeData?.font_heading_color: "#2d3849"}}
                        >Enter Your Details</h3>
                      <div className="flex flex-col gap-4">
                          <div>
                            <input 
                              className="py-2 px-4 border border-textDark rounded-md text-base"
                              type="text" 
                              name="name" 
                              placeholder="Full Name" 
                              value={formData.name} 
                              onChange={handleChange} />
                              
                            {errors.name && <p style={{ color: "red" }}>{errors.name}</p>}
                            </div>
                            <div>
                            <input 
                              className="py-2 px-4 border border-textDark rounded-md text-base"
                              type="email" 
                              name="email" 
                              placeholder="Email" 
                              value={formData.email} 
                              onChange={handleChange} />
                            {errors.email && <p style={{ color: "red" }}>{errors.email}</p>}
                            </div>
                            <div>
                            <input 
                              className="py-2 px-4 border  border-textDark rounded-md text-base"
                              type="text" 
                              name="phone" 
                              placeholder="Phone Number" 
                              value={formData.phone} 
                              onChange={handleChange} />
                            {errors.phone && <p style={{ color: "red" }}>{errors.phone}</p>}
                            </div>
                            <div>
                            <input 
                              className="py-2 px-4 border border-textDark rounded-md text-base"
                              type="text" 
                              name="zip" 
                              placeholder="Zip Code" 
                              value={formData.zip} 
                              onChange={handleChange} />
                            {errors.zip && <p style={{ color: "red" }}>{errors.zip}</p>}
                            </div>
                          
                        </div>
                        <p className="pt-4" style={{ color: "red" }}>{emptyError ? "Please fill form first." : ""}</p>
                        <button
                            className="bg-[#6558F5] border-[#6558F5] border hover:bg-transparent hover:text-[#6558F5] text-white px-4 py-2 text-sm rounded-md mt-8 w-full" 
                            type="submit"
                            style={{
                              backgroundColor: themeData?.button_color ? themeData?.button_color : "#6558f5",
                              color: themeData?.button_text_color ? themeData?.button_text_color : "#ffffff",
                            }}
                            disabled={isDisabled}
                          >
                            Submit
                            {isDisabled && <PageLoader />}
                          </button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
              </>
            )}
            </>
           {/* )} */}
            <AlertModal
                visible={alertModelVisible}
                showCrossIcon
                onSubmit={() => setAlertModelVisible(false)}
                showCancelButton={false}
                // onCancel={() => setAlertModelVisible(false)}
                onClose={() => setAlertModelVisible(false)}
                submitButtonText="Done"
                // submitButtonClass = "bg-[#4c60e9] text-white"
                // submitButtonClass = {` ${themeData?.button_color ? `bg-[+${themeData?.button_color}+]` : "" }`}
                // secondaryLoading={updateServicesApi?.isLoading}
            >
                <img className="max-w-28 w-full mx-auto mb-5" src="/images/success-icon.jpg" />
                <p className="text-md mb-3 flex justify-center">
                  <span>
                    Form submitted successfully!
                  </span>
                </p>
            </AlertModal>
        </>
    )
}

export default UserDetailPage