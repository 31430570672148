import StepButton from "modules/CostCalculator/sub-module/CostForm/StepButton";
import { createdType, formServicesDataType } from "modules/CostCalculator/types";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { 
    getUserCostForm,
    setSelectedServices, 
    nextStep,
} from "redux/slices/userFormSlice";


interface ServicesPageProps {
    servicesData: formServicesDataType | any;
    setServicesData: any;
    setQuestionsWithOptions: any;
    themeData?: createdType | null;
}


const ServicesPage: React.FC<ServicesPageProps> = ({
    servicesData,
    setServicesData,
    setQuestionsWithOptions,
    themeData
}) => {

    const dispatch = useDispatch();    
    const { selectedServices } = useSelector(getUserCostForm);

    const handleServiceSelect = (serviceId: number) => {
    dispatch(
        setSelectedServices(
        selectedServices.includes(serviceId)
            ? selectedServices.filter((id: number) => id !== serviceId)
            : [...selectedServices, serviceId]
        )
    );
    };

    const filterQuestions = async () => {
    const selectedQuestions = servicesData.filter((service: formServicesDataType) => selectedServices.includes(service?.id))
        .flatMap((service: formServicesDataType) => service?.questions);
    setQuestionsWithOptions(selectedQuestions)
    dispatch(nextStep())
    };
    
    



    return (
        <>
            <div 
                className="w-full md:text-sm text-xl text-textDark font-medium text-center"
                style={{color: themeData?.font_heading_color ? themeData?.font_heading_color : "#2d3849"}}
            >
                Select Services you want to get Estimate.
            </div>
            <div className="flex flex-row gap-8 overflow-hidden">
                <div className="w-full max-h-full flex gap-4 justify-center overflow-auto pr-3">
                {servicesData &&
                    servicesData.map((item: formServicesDataType | any, index: number) => {
                    return (
                        <>
                        <div
                            key={index}
                            className={`w-full group max-w-[316px] bg-white ${
                                selectedServices.includes(item?.id)
                                ? "border-[3px] border-primaryColor"
                                : "border border-gray-500"
                            } rounded-md flex flex-col items-center justify-center cursor-pointer py-8 px-5 gap-8 text-center hover:shadow-md`}
                            style={{borderColor: themeData?.primary_brand_color ? themeData?.primary_brand_color: "#6558f5",}}
                            onClick={() => handleServiceSelect(item?.id)}
                        >
                            <div className="w-24 h-24 shrink-0 flex items-center justify-center overflow-hidden group-hover:scale-110 transition-all duration-200">
                            <img
                                src={item?.service?.services_image}
                                alt="img"
                                className="size-full object-contain object-center"
                            />
                            </div>
                            <div className="w-full flex items-center justify-center">
                            <label
                                htmlFor="default-radio-1"
                                className=" text-gray-900 dark:text-gray-600 w-full text-base font-medium truncate"
                                style={{color: themeData?.font_paragraph_color ? themeData?.font_paragraph_color : "#2d3849"}}
                            >
                                {item && item?.service?.name}
                            </label>
                            </div>
                        </div>
                        </>
                    );
                })}

                </div>
          </div>
          <StepButton
            prevButtonVisible={true}
            nextButtonDisable={selectedServices.length === 0}
            handleNextClick={() => {
                filterQuestions()
            }}
            buttonStyleProps={{
                backgroundColor: themeData?.button_color ? themeData?.button_color : "#6558f5",
                color: themeData?.button_text_color ? themeData?.button_text_color : "#ffffff",
            }}
          />
        </>
    )
}

export default ServicesPage