// ** packages **
import { useEffect, useRef, useState } from "react";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import { Edit, Eye } from "react-feather";

// ** components **
import { Modal } from "components/Theme/Modal";
import SelectField from "components/FormField/common/SelectField";
import Label from "components/FormField/common/Label";
import InputField from "components/FormField/common/inputField";
import TextAreaField from "components/FormField/common/TextAreaField";

// ** services **
import { useAddIndustryAPI, useGetIndustryAPI, useUpdateIndustryAPI } from "../services";

// ** schema **
import { createCustomPromptWithTypeSchema } from "modules/Patient/sub-modules/Summary/schema/createCustomPrompt.validation.schema";

// ** types **
import { IndustryFormType } from "../types";

import PageLoader from "components/Loaders/PageLoader";

// ** hooks **
import usePermission from "hooks/usePermission";
import { PERMISSIONS } from "constants/Permissions.constant";
import { isEqual } from "lodash";
import { getAuth } from "redux/slices/authSlice";
import { useSelector } from "react-redux";
import MultiSelect, {
  MultiSelectOptionTypes,
} from "components/FormField/common/MultiSelect";
import AlertModal from "components/modal/AlertModal";
import { useNavigate } from "react-router-dom";

import { createIndustryTypeSchema } from "../schema";
import FileUploadButton from "components/FileUploadButton";

const IndustryForm = (props: IndustryFormType) => {

  const { hasPermission } = usePermission();
  const { user } = useSelector(getAuth);
  const is_superAdmin = user?.is_super_administrator;

  const customPromptUpdateRole = hasPermission(
    PERMISSIONS.CUSTOM_PROMPT.UPDATE
  );

  const { isOpen, setIsOpen, refreshTable, setIndustryStatus } = props;


  const [industryImage, setIndustryImage] = useState<string | File>("");
  const [fileName, setFileName] = useState<string | null>(null);

  const getIndustryApi = useGetIndustryAPI();
  const updateIndustryApi = useUpdateIndustryAPI();
  const addIndustryApi = useAddIndustryAPI();

  // const [promptType, setPromptType] = useState<TypeofIndustryType[]>([
  //   { name: "", industryImage: "" },
  // ]);
  const [industryData, setIndustryData] = useState<any>([]);

  const [isPlaybookCreatePermission, setIsPlaybookCreatePermission] =
    useState<boolean>(false);


  useEffect(() => {
    user?.current_plan?.payment_data?.plan?.specification?.forEach(
      (spec: any) => {
        if (
          spec.name_type?.toLowerCase() === "custom prompt" &&
          spec.limit === "false"
        ) {
          setIsPlaybookCreatePermission(true);
        }
      }
    );
  }, []);

  const getIndustryApiData = async () => {
    if (isOpen.id) {
      const { data, error } = await getIndustryApi.getIndustryApi(isOpen.id);
      if (!error && data) {
        setIndustryData(data);
        setValue("name", data?.name);
        setFileName(data?.industry_image_name)
      }
    }
  };

  useEffect(() => {
    if (isOpen.id) {
      getIndustryApiData()
    }
  }, [isOpen])

  const {
    register,
    handleSubmit,
    setValue,
    getValues,
    reset,
    control,
    formState: { errors },
  } = useForm<any>({
    mode: "all",
    reValidateMode: "onChange",
    resolver: yupResolver(createIndustryTypeSchema),
    defaultValues: { name: "" },
  });

    const stateFormReset = (error: any, data: any) => {
        if (!error && data) {
        setIsOpen({ open: false, id: null, isDefault: false, viewOnly: true });
        reset();
        refreshTable && refreshTable();
        }
    };


    const onSubmit = handleSubmit(async (submittedData) => {
        const formData = new FormData();
        for (const [key, value] of Object.entries(submittedData)) {
            formData.append(key, String(value));
        }
        if (industryImage) {
            formData.append('industry_image', industryImage)
            if (fileName) {
              formData.append('industry_image_name', fileName)
            }
        }
        if (isOpen?.id) {
            const { data, error } = await updateIndustryApi.updateIndustryAPI(
            formData,
            String(isOpen?.id)
            );
            stateFormReset(error, data);
            setIndustryImage("")
            setFileName(null)

        } else {
            const { data, error } = await addIndustryApi.addIndustryAPI(formData);
            stateFormReset(error, data);
            setIndustryImage("")
            setFileName(null)
        }
    });

    const onClose = () => {
      setIndustryImage("")
      setFileName(null)
      setIsOpen({
        open: false,
        id: null,
        isDefault: false,
        viewOnly: true,
      });
      reset();
    };

    const handleChange = (e:any) => {
        const { name, value, files } = e.target;
        setIndustryImage(files ? files[0] : value);
        setFileName(files ? files[0]?.name : value?.name)
    };

  return (
    <div className="w-full bold-label">
      {isOpen.open && (
        <Modal
          onSubmit={onSubmit}
          headerName={
            isOpen?.id
              ? isOpen?.viewOnly || (isOpen?.isDefault && !is_superAdmin)
                ? "View Industry"
                : "Edit Industry"
              : "Add Industry"
          }
          modalWidth="800px"
          isLoading={updateIndustryApi.isLoading}
          onClose={onClose}
          onCancel={onClose}
        >
          {getIndustryApi.isLoading ? (
            <PageLoader pageLoaderClassName="h-[604px]" />
          ) : (
            <>
              <form onSubmit={onSubmit}>
                {customPromptUpdateRole && isOpen?.id && (
                  <div className="flex justify-end">
                    {isOpen?.viewOnly && (
                      <Edit
                        className="text-xs cursor-pointer"
                        onClick={() => {
                          setIsOpen({ ...isOpen, viewOnly: false });
                        }}
                      />
                    )}
                  </div>
                )}
                <InputField
                  name="name"
                  label="Title"
                  register={register}
                  placeholder="Enter Industry Title"
                  required
                  type="text"
                  errors={errors.name}
                  disabled={
                    (isOpen?.isDefault && !is_superAdmin) || isOpen?.viewOnly
                  }
                  className={
                    (isOpen?.isDefault && !is_superAdmin) || isOpen?.viewOnly
                      ? "opacity-50"
                      : ""
                  }
                />
                <div className="form__group">
                  <div className="field__wrapper">
                    <div className="relative w-fit">
                        <FileUploadButton
                          labelName="Industry Image"
                          className={`${ isOpen?.viewOnly ? "bg-gray-500 text-white" : "bg-textDark text-white cursor-pointer" }`}
                          fileName={fileName}
                          handleChange={handleChange}
                          disabled={(isOpen?.isDefault && !is_superAdmin) || isOpen?.viewOnly}
                          handleDeleteFile={() => {
                            setFileName(null)
                            setIndustryImage("")
                          }}
                        />
                      
                    </div>
                  </div>
                </div>
              </form>
            </>
          )}
        </Modal>
      )}
    </div>
  );
};

export default IndustryForm;
