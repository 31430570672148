import React, { useState } from "react";
import Button from "components/Theme/Button";
import { useDispatch, useSelector } from "react-redux";
import { setCurrentFormType } from "redux/slices/formSlice";
import * as Icon from "react-feather";
import { useNavigate } from "react-router-dom";
import CopyToClipboard from "react-copy-to-clipboard";
import { costFormDataType } from "modules/CostCalculator/types";
import { format } from 'date-fns'
import SharePopup from "../SharePopup";
import { getAuth } from "redux/slices/authSlice";
import { useCopyTemplateToCostFormAPI } from "modules/CostCalculator/services";
const { REACT_APP_FRONT_URL } = process.env;

interface FormTemplateProps {
    formDataItem?: costFormDataType; 
    selectedFormData?: costFormDataType;
    setSelectedFormData?: any; 
    setIsFormOpen?: ((value: boolean) => void) | undefined | any; 
    setCurrentTab?: ((value: boolean) => void) | undefined | any; 
}


const FormTemplate: React.FC<FormTemplateProps> = ({
    formDataItem,
    selectedFormData,
    setSelectedFormData,
    setIsFormOpen,
    setCurrentTab,
}) => {

    const { user } = useSelector(getAuth);
    const [isOpen, setIsOpen] = useState(false)

    const dispatch = useDispatch()
    const navigate = useNavigate()

    const copyTemplateToCostFormAPI = useCopyTemplateToCostFormAPI();

    const onUseTemplateClickHandler = async () => {
        
        // setSelectedFormData(formDataItem)
        // setIsFormOpen(true)
        // dispatch(setCurrentFormType("Edit"));
        const formData = new FormData();

        if (formDataItem?.id) {
            formData.append("template_id", String(formDataItem?.id))
            const { data, error } = await copyTemplateToCostFormAPI.copyTemplateToCostFormAPI(formData);

            // setCurrentTab("myforms")
            setSelectedFormData(data)
            setIsFormOpen(true)
            dispatch(setCurrentFormType("Edit"));
        }
    }

    const onListClickHandler = () => {
      if (user?.is_super_administrator) {
        navigate("/admin/costcalculator/form-responses/", { state : {id: formDataItem?.id}})
      } else {
        navigate("/costcalculator/form-responses/", { state : {id: formDataItem?.id}})
      }
    }

    const embedUrl = REACT_APP_FRONT_URL + "/cost/form/" +formDataItem?.code


    return (
        
          <>
            {formDataItem &&
            <div className="w-full bg-white border border-gray-500 rounded-md flex flex-col items-center justify-center cursor-pointer py-6 px-5 gap-4 hover:shadow-md">
                <div className="w-full text-xl font-medium">{formDataItem?.form_name}</div>
                <div className="w-full flex flex-col">
                  <div className="w-full grid grid-cols-2 gap-2 py-3 border-b last:border-b-0 border-dashed border-gray-500 items-center">
                    <div className="w-full text-base font-medium">Services</div>
                    <div className="w-full flex items-center justify-end">
                        {formDataItem?.services?.length > 1 ? (
                          <>
                            <span className="w-fit flex items-center justify-center text-xs px-2 py-0.5 rounded-2xl font-medium border border-gray-500 text-gray-500 text-center">
                              {formDataItem?.services[0]?.service?.name} 
                            </span>
                            <span className="w-fit flex items-center justify-center text-xs px-2 py-0.5 mx-1 rounded-2xl font-medium border border-gray-500 text-blue-500 text-center whitespace-nowrap">
                              +  {formDataItem?.services?.length - 1}
                            </span>
                          </>
                        ) : (
                          <>
                            <span className="w-fit flex items-center justify-center text-xs px-2 py-0.5 rounded-2xl font-medium border border-gray-500 text-gray-500 text-center">
                                {formDataItem?.services[0]?.service?.name}
                            </span>
                          </>
                        )}
                        
                    </div>
                  </div>

                  <div className="w-full grid grid-cols-2 gap-2 py-3 border-b last:border-b-0 border-dashed border-gray-500 items-center">
                    <div className="w-full text-base font-medium">
                      Created At
                    </div>
                    <div className="w-full flex items-center justify-end">
                      <span className="w-fit flex items-center justify-center text-xs px-2 py-0.5 rounded-2xl font-medium border border-gray-500 text-gray-500 text-center">
                        {format(new Date(formDataItem?.created_at), 'dd MMM yyyy')}
                      </span>
                    </div>
                  </div>
                </div>
                <div className="w-full flex items-center justify-end gap-3">
                  <Button
                    className="primary__Btn w-fit h-9"
                    type="button"
                    onClick={() => onUseTemplateClickHandler()}
                  >
                    <div className="btn__text">Use Template</div>
                  </Button>
                  {/* <CopyToClipboard text={embedUrl} onCopy={() => setEstimatorLinkCopied(true)}> */}
                    {/* <Button 
                      className="button__DSD text-[#4c60e9] bg-white border border-[#4c60e9] h-9"
                      onClick={() => {setIsOpen(true)}}
                    >Share</Button> */}
                  {/* </CopyToClipboard> */}
                  
                </div>
              </div>
              }
         
        {isOpen && (
          <>
            <SharePopup
              isOpen={isOpen}
              setIsOpen={setIsOpen}
              selectedFormData={formDataItem}
            />
          </>
        )}     
            
        </>
    )
}

export default FormTemplate