import React, { useEffect, useState } from "react";
import { useBulkUpdateFormServicesAPI, useServicesListForFormAPI } from "../../../services";
import { costFormDataType, formServicesDataType, servicesDataType } from "../../../types";
import StepButton from "../StepButton";
import { useDispatch, useSelector } from "react-redux";
import { getCostFormData, setStep3Data } from "redux/slices/formSlice";
import Button from "components/Theme/Button";
import * as Icon from "react-feather";
import InputField from "components/FormField/common/inputField";
import { useAddServicesAPI } from "modules/Admin/sub-module/AdminCostCalculator/AdminServices/services";
import { isOpenStateType } from "../../UserResponse/types";
import { getAuth } from "redux/slices/authSlice";
import ServicesForm from "modules/Admin/sub-module/AdminCostCalculator/AdminServices/components/ServicesForm";

interface FormStep3Props {
  currentFormStep?: string;
  setCurrentFormStep?: ((value: string) => void) | undefined | any;
  selectedFormData?: costFormDataType | any;
  setSelectedFormData?: any;
}

const FormStep3: React.FC<FormStep3Props> = ({
  currentFormStep,
  setCurrentFormStep,
  selectedFormData,
  setSelectedFormData
}) => {
  const { user } = useSelector(getAuth);
  const is_superAdmin = user?.is_super_administrator;
  const currentFormData = useSelector(getCostFormData);
  const dispatch = useDispatch();

  const addServicesApi = useAddServicesAPI();
  const updateBulkServicesApi = useBulkUpdateFormServicesAPI();

  const [servicesData, setServicesData] = useState<servicesDataType[]>([]);
  const [selectedServicesIds, setSelectedServicesIds] = useState<number[] | any >([]);
  // const [selectedServicesIds, setSelectedServicesIds] = useState<formServicesDataType[] | any>(selectedFormData?.services);
  
  // const [editFormServices, setEditFormServices] = useState<boolean>(false);
  
  const [newServicesValue, setNewServicesValue] = useState<string>("");

  const { getServicesListApi, isLoading } = useServicesListForFormAPI();

  // ===================== ADD Service Functionality ======================================

  const [isOpen, setIsOpen] = useState<isOpenStateType>({
      open: false,
      id: null,
      isDefault: false,
      viewOnly: true,
  });

  const getServicesListApiData = async (industryId:number) => {
    const { data, error } = await getServicesListApi({
      params: {
        'industry_id' : industryId
      },
    });
    if (!error && data) {
      setServicesData(data?.data);
    }
  };
  useEffect(() => {
    getServicesListApiData(selectedFormData?.industry_id);
  }, [isOpen]);

  const servicesHandler = (item: servicesDataType) => {
    if (!selectedServicesIds.includes(item.id)) {
      const updatedIds = [...selectedServicesIds, item.id];
      setSelectedServicesIds(updatedIds);
    } else {
      setSelectedServicesIds(selectedServicesIds.filter((id:number)=> id !== item.id));
    }
  };

  useEffect(() => {
    if (selectedFormData?.id) {
      let selectedIds:any = []
      selectedFormData.services.forEach((item: any) => {selectedIds.push(item?.services_id)})
      setSelectedServicesIds(selectedIds)
    }
  }, [])

  const replaceServices = (newServices: servicesDataType[]) => {
    setSelectedFormData((selectedFormData: servicesDataType[]) => ({
        ...selectedFormData,
        services: newServices // Replace entire services list
    }));
};

  const submitFormServicesHandler = async () => {
    const formData = new FormData();
    // let selectedIds:any = selectedFormData.services.forEach((item: any) => {return item?.services_id})

    if (selectedServicesIds && selectedFormData?.id) {
        formData.append('service_ids', JSON.stringify(selectedServicesIds))
        selectedFormData?.id && formData.append('form_id', selectedFormData?.id)
        const { data, error } = await updateBulkServicesApi.updateBulkFormServices(formData);
        replaceServices(data)
    }
  } 



  return (
    <>
      <div className="size-full flex flex-col overflow-hidden md:py-5 py-10 md:px-0 px-10">
        <Button
          className="w-fit h-9 px-1 mb-5"
          onClick={() => setCurrentFormStep("Step 2")}
        >
          <span className="flex items-center gap-2">
            <Icon.ArrowLeft />
            Back to Market
          </span>
        </Button>
        <div className="w-full md:text-2xl text-3xl font-medium mb-4">
          Choose Your Service (Step 3 of 5)
        </div>
        <div className="w-full md:text-sm text-base text-gray-500 mb-3">
          <span className="font-medium mr-1">Pick Your Services:</span>
          Choose the core services you want to show off in your pricing estimator.
        </div>
        <div className="w-full md:text-sm text-base text-gray-500 mb-8">
          <span className="font-medium mr-1">Need help ?</span>
          <a href="" className="underline mr-1">
            Click here
          </a>
          to view our How To guide
        </div>
        <div className="flex-grow flex flex-col gap-8 max-w-[992px] mx-auto w-full overflow-hidden">
          {/* <p>Create new services:</p> */}
          <div className="flex gap-4 items-center">
            <span className="text-md font-medium text-gray-700">Select from below or</span>
            <Button 
              className="primary__Btn w-fit" 
              onClick={() =>
                setIsOpen({
                  open: true,
                  id: null,
                  isDefault: false,
                  viewOnly: false,
                })
              }>
              Add Services
            </Button>
          </div>

          <div className="flex-grow  flex flex-col gap-8 overflow-hidden">
            <div className="w-full max-h-full grid md:grid-cols-1 lg:grid-cols-2 grid-cols-3 gap-4 overflow-auto pr-3">
              {servicesData &&
                servicesData.map((item: servicesDataType, index: number) => {
                  return (
                    <>
                      <div
                        key={index}
                        className={`w-full group bg-white relative ${
                          selectedServicesIds.includes(item.id)
                            ? "border-[3px] border-primaryColor"
                            : "border border-gray-500"
                        } rounded-md flex flex-col items-center justify-center cursor-pointer py-8 px-5 gap-8 text-center hover:shadow-md`}
                        onClick={() => servicesHandler(item)}
                      >
                        <div className="w-24 h-24 shrink-0 flex items-center justify-center overflow-hidden group-hover:scale-110 transition-all duration-200">
                            {item?.services_image ? (
                                <img
                                src={item?.services_image}
                                alt="img"
                                className="size-full object-contain object-center"
                            />
                            ) : (
                                <>
                                <svg className="w-16 h-16" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"><path d="M13 2H6a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V9z"></path><polyline points="13 2 13 9 20 9"></polyline></svg>
                                </>
                            )}
                        </div>
                        <div className="w-full flex items-center justify-center">
                          <label
                            htmlFor="default-radio-1"
                            className=" text-gray-900 dark:text-gray-600 w-full text-base font-medium truncate"
                          >
                            {item && item?.name}
                          </label>
                        </div>
                        <div className="absolute top-3 right-3">
                          {(item?.created?.email === user?.email || is_superAdmin) &&  (
                            <button
                                title="Edit"
                                className="size-8 flex items-center justify-center shrink-0 border border-gray-500 text-gray-500 rounded"
                                onClick={() => setIsOpen({
                                    open: true,
                                    id: Number(item?.id),
                                    isDefault: false,
                                    viewOnly: false,
                                  })
                                }
                            >
                                <Icon.Edit className="size-4" />
                            </button> 
                          )}
                        </div>
                      </div>
                      

                    </>
                  );
                })}
              {servicesData && servicesData?.length === 0 && (
                <div className="text-center w-full">
                  <img className="mx-auto mb-5 w-full max-w-[150px]" src="/images/no-data-found.gif" />
                  <p className="text-center"> No Services Found</p>
                </div>
              )}
            </div>
          </div>
          <StepButton
            prevButtonLable="Prev to Market"
            nextButtonLable="Next : Add Questions"
            nextButtonDisable={selectedServicesIds.length === 0}
            handlePrevClick={() => {
              setCurrentFormStep("Step 2")
            }}
            handleNextClick={() => {
              submitFormServicesHandler()
              setCurrentFormStep("Step 4")
            }}
          />
        </div>
      </div>
        <ServicesForm
          isOpen={isOpen}
          setIsOpen={setIsOpen}
          industryId={selectedFormData?.industry_id}
        />
    </>
  );
};

export default FormStep3;
