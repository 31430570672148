// ** packages **
import { MarketSchemaError } from "constants/formErrorMessage.constant";
import * as yup from "yup";

export const createMarketTypeSchema = yup.object().shape({
  name: yup
    .string()
    .trim()
    .min(3, MarketSchemaError.name_min)
    .required(MarketSchemaError.name)
    .max(50, MarketSchemaError.name_max),

});
