import React, { useEffect, useState } from "react";

import {
  useIndustryListForFormAPI,
  useMarketListForFormAPI,
  useUpdateCostFormAPI,
} from "../../../services";

import * as Icon from "react-feather";

import StepButton from "../StepButton";

import Button from "components/Theme/Button";

import { costFormDataType, industryDataType, marketDataType } from "../../../types";
import { useSelector } from "react-redux";
import { getCostFormData, setStep2Data } from "redux/slices/formSlice";
import InputField from "components/FormField/common/inputField";
import { useAddMarketAPI } from "modules/Admin/sub-module/AdminCostCalculator/Market/services";
import { useDispatch } from "react-redux";
import { isOpenStateType } from "modules/Admin/sub-module/AdminCostCalculator/Market/types";
import MarketForm from "modules/Admin/sub-module/AdminCostCalculator/Market/components/MarketForm";
import { getAuth } from "redux/slices/authSlice";

interface IFormStep1Props {
  currentFormStep?: string;
  setCurrentFormStep?: ((value: string) => void) | undefined | any;
  selectedFormData?: costFormDataType | null;
  setSelectedFormData?: any;
}

const FormStep2: React.FC<IFormStep1Props> = ({
  currentFormStep,
  setCurrentFormStep,
  selectedFormData,
  setSelectedFormData
}) => {
  const { user } = useSelector(getAuth);
  const is_superAdmin = user?.is_super_administrator;
  const dispatch = useDispatch();

  const [marketData, setMarketData] = useState<marketDataType[]>([]);
  // const [selectedMarketId, setSelectedMarketId] = useState<marketDataType>(
  //   currentFormData.step2
  // );
  const [selectedMarketId, setSelectedMarketId] = useState<number | any>(selectedFormData?.industry_market_id);
  const [newMarketValue, setNewMarketValue] = useState<string>("");

  const { getMarketListApi, isLoading } = useMarketListForFormAPI();
  const addMarketApi = useAddMarketAPI();
  const updateCostFormApi = useUpdateCostFormAPI();

  const [isOpen, setIsOpen] = useState<isOpenStateType>({
      open: false,
      id: null,
      isDefault: false,
      viewOnly: true,
  });


  const getMarketListApiData = async () => {
    const { data, error } = await getMarketListApi();
    if (!error && data) {
      setMarketData(data?.data);
    }
  };

  useEffect(() => {
    getMarketListApiData();
  }, [isOpen]);

  const addMarketHandle = async () => {
    const formData = new FormData();
    if (newMarketValue !== "") {
      formData.append("name", newMarketValue);
      const { data, error } = await addMarketApi.addMarketAPI(formData);
      getMarketListApiData();
    }
  };

  const marketHandler = (item: marketDataType) => {
    setSelectedMarketId(item?.id);
    // dispatch(setStep2Data(item));
  };

  const handleSubmitMarket = async () => {
    const formData = new FormData();
    if (selectedMarketId) {
        formData.append('industry_market_id', selectedMarketId)
    }

    if (selectedFormData?.id && selectedFormData?.industry_market_id !== selectedMarketId) {
        const { data, error } = await updateCostFormApi.updateCostFormAPI(
            formData,
            selectedFormData?.id
        );
        setSelectedFormData(data)
    }

  } 



  return (
    <>
      <div className="size-full flex flex-col overflow-hidden md:py-5 py-10 md:px-0 px-10">
        <Button
          className="w-fit h-9 px-1 mb-5"
          onClick={() => setCurrentFormStep("Step 1")}
        >
          <span className="flex items-center gap-2">
            <Icon.ArrowLeft />
            Back to Category
          </span>
        </Button>
        <div className="w-full md:text-2xl text-3xl font-medium mb-4">
          Choose Your Market (Step 2 of 5)
        </div>
        <div className="w-full md:text-sm text-base text-gray-500 mb-3">
          <span className="font-medium mr-1">Choose Your Market:</span>
          What kind of customers will be using this estimator?
        </div>
        <div className="w-full md:text-sm text-base text-gray-500 mb-8">
          <span className="font-medium mr-1">Need help ?</span>
          <a href="" className="underline mr-1">
            Click here
          </a>
          to view our How To guide
        </div>
        <div className="flex-grow flex flex-col gap-8 max-w-[992px] mx-auto w-full overflow-hidden">
          {/* <p>Create new Market:</p>
          <div className="flex gap-4"> */}
            {/* <InputField
              fieldWrapperClassName="mb-0"
              name="name"
              // label="Add new market"
              placeholder="e.g lawn care"
              type="text"
              value={newMarketValue}
              onChange={(e) => {
                setNewMarketValue(e.target.value);
              }}
            />
            <Button className="primary__Btn w-fit" onClick={addMarketHandle}>
              Add Market
            </Button> */}
            <div className="flex gap-4 items-center">
            <span className="text-md font-medium text-gray-700">Select from below or</span>
            <Button 
              className="primary__Btn w-fit" 
              onClick={() =>
                setIsOpen({
                  open: true,
                  id: null,
                  isDefault: false,
                  viewOnly: false,
                })
              }>
              Add Market
            </Button>
            </div>
          {/* </div> */}
          <div className="flex-grow  flex flex-col gap-8 overflow-hidden">
            <div className="w-full max-h-full grid md:grid-cols-1 lg:grid-cols-2 grid-cols-3 gap-4 overflow-auto pr-3">
              {marketData &&
                marketData.map((item: marketDataType, index: number) => (
                  <div
                    key={index}
                    className={`w-full group bg-white relative ${selectedMarketId === item?.id
                        ? "border-[3px] border-primaryColor"
                        : "border border-gray-500"
                      } rounded-md flex flex-col items-center justify-center cursor-pointer py-8 px-5 gap-8 text-center hover:shadow-md`}
                    onClick={() => marketHandler(item)}
                  >
                    <div className="w-24 h-24 shrink-0 flex items-center justify-center overflow-hidden group-hover:scale-110 transition-all duration-200">
                      {item?.market_image ? (
                        <img
                          src={item.market_image}
                          alt="img"
                          className="size-full object-contain object-center"
                        />
                      ) : (
                        <>
                          <svg className="w-16 h-16" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"><path d="M13 2H6a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V9z"></path><polyline points="13 2 13 9 20 9"></polyline></svg>
                        </>
                      ) }
                      
                    </div>
                    <div className="w-full flex items-center justify-center">
                      <label
                        htmlFor="default-radio-1"
                        className="text-gray-900 dark:text-gray-600 w-full text-base font-medium truncate"
                      >
                        {item && item?.name}
                      </label>
                    </div>
                    <div className="absolute top-3 right-3">
                      {(item?.created?.email === user?.email || is_superAdmin) &&  (
                        <button
                            title="Edit"
                            className="size-8 flex items-center justify-center shrink-0 border border-gray-500 text-gray-500 rounded"
                            onClick={() => setIsOpen({
                                open: true,
                                id: Number(item?.id),
                                isDefault: false,
                                viewOnly: false,
                              })
                            }
                        >
                            <Icon.Edit className="size-4" />
                        </button> 
                      )}
                    </div>
                  </div>
                ))}
              {marketData && marketData?.length === 0 && (
                <div className="text-center w-full">
                  <img className="mx-auto mb-5 w-full max-w-[150px]" src="/images/no-data-found.gif" />
                  <p className="text-center">No Market Found</p>
                </div>
              )}
            </div>
          </div>
          <StepButton
            // selectedMarketId={selectedMarketId}
            prevButtonLable="Prev to Category"
            nextButtonLable="Next : Service"
            nextButtonDisable={selectedMarketId ? false : true }
            handlePrevClick={() => {
              setCurrentFormStep("Step 1")
            }}
            handleNextClick={() => {
              setCurrentFormStep("Step 3")
              handleSubmitMarket()
            }}
          />
        </div>
      </div>
      <MarketForm
          isOpen={isOpen}
          setIsOpen={setIsOpen}
        />
    </>
  );
};

export default FormStep2;
