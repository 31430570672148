import React, { useState } from "react";
import * as Icon from "react-feather";
import InputField from "components/FormField/common/inputField";
import SelectField from "components/FormField/common/SelectField";
import QuestionsCard from "../QuestionCard";
import { costFormDataType, formServicesDataType, questionsDataType, servicesDataType } from "modules/CostCalculator/types";
import OptionCard from "../OptionCard";
import { useAddQuestionsAPI, useDeleteFormServicesAPI, useDeleteQuestionAPI, useQuestionsMoveAPI, useUpdateFormServicesAPI } from "modules/CostCalculator/services";
import { number } from "yup";
import AlertModal from "components/modal/AlertModal";


interface ServicesCardProps {
    serviceItem: formServicesDataType;
    // setServicesCardOpen?: ((value: boolean) => void) | undefined | any;
    selectedFormData?: costFormDataType | null;
    setSelectedFormData?: any;
}


const ServicesCard: React.FC<ServicesCardProps> = ({
    serviceItem,
    // setServicesCardOpen
    selectedFormData,
    setSelectedFormData,
}) => {

    const addQuestionsApi = useAddQuestionsAPI();
    const deleteQuestionsApi = useDeleteQuestionAPI();
    const moveQuestionsAPI = useQuestionsMoveAPI();

    const deleteFormServicesApi = useDeleteFormServicesAPI();
    const updateFormServicesAPI = useUpdateFormServicesAPI();

    const [servicesCardOpen, setServicesCardOpen] = useState<boolean>(false);
    const [loading, setLoading] = useState(false);
    
    // ---------- Services Form Fields -----------------------
    const [editFormServices, setEditFormServices] = useState<boolean>(false);
    const [serviceBaseCost, setServiceBaseCost] = useState<number | any>(serviceItem?.base_cost ? serviceItem?.base_cost : 0);
    
    // ---------- Questions Forms Fields --------------------
    const [addNewQuestion, setAddNewQuestion] = useState<boolean>(false);
    const [questionName, setQuestionName] = useState<string>("");
    const [alertModelVisible, setAlertModelVisible] = useState<boolean>(false);



    console.log(serviceItem, "--------serviceItem-------------")
    // ================== Questions Functionality =========================================
    const addQuestion = (serviceId: number | any, newQuestion: any) => {
        setSelectedFormData((selectedFormData: costFormDataType) => {
            return {
                ...selectedFormData,
                services: selectedFormData.services.map((service:any) =>
                    service.id === serviceId
                        ? { ...service, questions: [...service.questions, newQuestion] }
                        : service
                )
            };
        });
    };

    const removeQuestion = (serviceId: number, questionId: number) => {
        setSelectedFormData((selectedFormData: costFormDataType) => {
            return {
                ...selectedFormData,
                services: selectedFormData.services.map((service: any) =>
                    service.id === serviceId
                        ? {
                              ...service,
                              questions: service.questions.filter(
                                  (question: any) => question.id !== questionId
                              )
                          }
                        : service
                )
            };
        });
    };

    const questionSubmitHandle = async () => {
        const formData = new FormData();
        if (serviceItem?.id) {
            if (questionName) {
                formData.append('question_name', questionName)
            }
            formData.append('form_services_id', String(serviceItem.id))
            const { data, error } = await addQuestionsApi.addQuestionsApi(formData);
            addQuestion(serviceItem?.id, data)
            setAddNewQuestion(false)
            setQuestionName("")
        } 
    }

    const questionDeleteHandler = async (question:questionsDataType | any) => {        
        if (question?.id) {
            const { data, error } = await deleteQuestionsApi.deleteQuestionsApi(question?.id);
            removeQuestion(question?.form_services_id, question?.id)
        }
    }

    const replaceServiceAfterQuestionMove = (serviceId: number | any, newServiceData: formServicesDataType) => {
        setSelectedFormData((selectedFormData: costFormDataType) => ({
            ...selectedFormData,
            services: selectedFormData.services.map((service) =>
                service.id === serviceId ? newServiceData : service
            )
        }));
    };


    const handleMoveQuestion = async (questionId: number | null | any, direction: "up" | "down") => {
        try {
            setLoading(true)
            const formData = new FormData();
            if (questionId) {
                formData.append('question_id', String(questionId))
                formData.append('form_services_id', String(serviceItem?.id))
                formData.append('direction', direction)
            }
            const { data, error } = await moveQuestionsAPI.questionsMoveApi(formData,);
            if (!error) {
                replaceServiceAfterQuestionMove(serviceItem?.id, data)
            }
            setLoading(false)
            
        } catch (error) {
            console.error("Error moving question:", error);
        }
    };


    // ============================== Services Functionality =========================================

    const replaceSingleService = (serviceId: number | any, newServiceData: formServicesDataType) => {
        setSelectedFormData((selectedFormData: costFormDataType) => ({
            ...selectedFormData,
            services: selectedFormData.services.map((service) =>
                service.services_id === serviceId ? newServiceData : service
            )
        }));
    };

    const removeService = (serviceId: number) => {
        setSelectedFormData((selectedFormData:costFormDataType) => ({
            ...selectedFormData,
            services: selectedFormData?.services.filter((service) => service?.id !== serviceId)
        }));
    };

    const updateFormServiceHandler = async (serviceItem: formServicesDataType | any) => {
        const formData = new FormData();
        if (serviceItem?.id) {
            if (serviceBaseCost !== serviceItem?.base_cost) {
                formData.append('base_cost', serviceBaseCost)
            }
            const { data, error } = await updateFormServicesAPI.updateFormServicesAPI(
                formData,
                String(serviceItem?.id)
            );
            replaceSingleService(serviceItem?.id, data)
            setEditFormServices(false)
        } 
    }

    const serviceDeleteHandler = async (serviceItem:formServicesDataType | any) => {        
        if (serviceItem?.id) {
            const { data, error } = await deleteFormServicesApi.deleteFormServicesApi(serviceItem?.id);
            if (!error) {
                removeService(serviceItem?.id)
            }
        }
        setAlertModelVisible(false)
    }

    return (
        <>
            <div className="flex flex-col">
                <div className="w-full flex items-center justify-end mb-4">
                    <div 
                        className="text-sm cursor-pointer text-[#6558F5] w-fit flex items-center justify-center gap-1 font-medium hover:underline"
                        onClick={() => setServicesCardOpen(!servicesCardOpen)}
                    >
                        {servicesCardOpen ? "Hide" : ""} all questions
                        <Icon.Eye className="size-4" />
                    </div>
                </div>
                <div className="flex-grow flex flex-col gap-4 overflow-auto pr-3">
                    <div className="accrodion-item w-full flex flex-col">
                        <div
                            className={`accrodion-item-header w-full flex items-center rounded border-[2px] ${servicesCardOpen ? "border-[#6558F5]" : "border-gray-500"}`}
                        >
                            <button
                                className={`border-0 shrink-0 py-2 w-10 border-r-[2px] h-full flex flex-col items-center justify-center cursor-pointer ${servicesCardOpen ? "border-[#6558F5]" : "border-gray-500"}`}
                                onClick={() => setServicesCardOpen(!servicesCardOpen)}
                            >
                                <Icon.ChevronDown
                                    className={`size-4 ${servicesCardOpen && "rotate-180"}`}
                                />
                            </button>
                            <div className="w-full flex items-center px-5 py-2">
                                <div className="size-16 shrink-0 group flex items-center justify-center border border-gray-300 rounded relative p-1">

                                    {serviceItem?.service?.services_image ? (
                                        <img
                                            src={serviceItem?.service?.services_image}
                                            alt="img"
                                            className="size-full object-contain object-center"
                                        />
                                    ): (
                                        <svg className="w-14 h-14" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="1.25" stroke-linecap="round" stroke-linejoin="round"><path d="M13 2H6a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V9z"></path><polyline points="13 2 13 9 20 9"></polyline></svg>
                                    )}
                                    
                                    {/* <button
                                        title="Edit"
                                        className="group-hover:flex rounded-full size-5 text-white bg-gray-400 hover:bg-gray-500 transition-colors shrink-0 hidden items-center justify-center absolute right-1 bottom-1 "
                                    >
                                        <Icon.Edit className="size-3" />
                                    </button> */}
                                </div>
                                <div className="w-full flex items-center px-5">
                                    <span
                                        title="Management Accounts"
                                        className="text-xl font-medium truncate"
                                    >
                                        {serviceItem?.service?.name}
                                    </span>
                                </div>
                                <button
                                    title="Delete"
                                    className="size-8 flex items-center justify-center shrink-0 bg-[#ee5555] text-white rounded mr-3"
                                    onClick={() => setAlertModelVisible(true)}
                                >
                                    <svg
                                        className="size-4"
                                        width="24"
                                        height="24"
                                        viewBox="0 0 15 15"
                                        fill="currentColor"
                                    >
                                        <path d="M2.64 1.27 7.5 6.13l4.84-4.84A.92.92 0 0 1 13 1a1 1 0 0 1 1 1 .9.9 0 0 1-.27.66L8.84 7.5l4.89 4.89A.9.9 0 0 1 14 13a1 1 0 0 1-1 1 .92.92 0 0 1-.69-.27L7.5 8.87l-4.85 4.85A.92.92 0 0 1 2 14a1 1 0 0 1-1-1 .9.9 0 0 1 .27-.66L6.16 7.5 1.27 2.61A.9.9 0 0 1 1 2a1 1 0 0 1 1-1c.24.003.47.1.64.27" />
                                    </svg>
                                </button>
                                <button
                                    title=""
                                    className="size-8 flex items-center justify-center shrink-0 text-black rounded"
                                    onClick={() => setServicesCardOpen(!servicesCardOpen)}
                                >
                                    <Icon.EyeOff className="size-4" />
                                </button>
                            </div>
                        </div>
                        {servicesCardOpen && (
                            <div className="accrodion-item-body w-full flex flex-col gap-4 pl-4 pt-4 pb-4">
                                <div
                                    className={`w-full flex items-center gap-4 border-[2px] border-[#6558F5] rounded py-2 px-5 `}
                                >
                                    <div className="flex items-center gap-3">
                                        <div className="w-fit text-lg font-medium">
                                            Base Cost:
                                        </div>
                                        <InputField
                                            fieldWrapperClassName={`mb-0 base-cost ${editFormServices ? "" : "no-edit"}`}
                                            className="no-spinner"
                                            name="base_cost"
                                            value={serviceBaseCost}
                                            onChange={(e) => setServiceBaseCost(e.target.value)}
                                            // label="Option Name"
                                            disabled={!editFormServices}
                                            placeholder="Base Cost"
                                            type="number"
                                            onKeyDown={(e) => {
                                                if (!/[0-9]/.test(e.key) && e.key !== "Backspace") {
                                                    e.preventDefault();
                                                }
                                            }}
                                        />
                                    </div>
                                    {editFormServices ? (
                                        <>
                                            <button
                                                title="Save"
                                                className="size-8 flex items-center justify-center shrink-0 border border-indigo-700 text-white bg-indigo-600 rounded"
                                                onClick={() => updateFormServiceHandler(serviceItem)}
                                            >
                                                <Icon.Save className="size-4" />
                                            </button>
                                        </>
                                    ) : (
                                        <button
                                            title="Edit"
                                            className="border border-gray-500 flex hover:bg-indigo-600 hover:border-indigo-700 hover:text-white items-center justify-center rounded shrink-0 size-8 text-gray-500"
                                            onClick={() => setEditFormServices(true)}
                                        >
                                            <Icon.Edit className="size-4" />
                                        </button>    
                                    )}
                                    {/* <button
                                        title=""
                                        className="size-8 flex items-center justify-center shrink-0 rounded-full border border-gray-500 text-gray-500 "
                                    >
                                        <Icon.Info className="size-4" />
                                    </button> */}
                                </div>
                                {!loading && serviceItem && serviceItem?.questions && serviceItem?.questions?.map((questionItem: questionsDataType, index: number) => {
                                    return (
                                        <>
                                            <QuestionsCard 
                                                index={index}
                                                questionList={serviceItem?.questions}
                                                questionItem={questionItem}
                                                selectedFormData={selectedFormData}
                                                setSelectedFormData={setSelectedFormData}
                                                questionDeleteHandler={questionDeleteHandler}
                                                handleMoveQuestion={handleMoveQuestion}
                                            />        
                                        </>
                                    )
                                })}
                                {addNewQuestion && (
                                    <>
                                        <div className="w-full rounded border-[2px] border-[#6558F5] flex items-center">

                                            <button 
                                                className="border-0 shrink-0 py-2 w-10 border-r border-gray-500 h-full flex flex-col items-center justify-center"
                                            >
                                                {/* <Icon.ChevronDown className="size-4" /> */}
                                            </button>
                                            <div className="w-full flex flex-col p-2 gap-3 max-w-[calc(100%_-_41px)]">
                                                <div className="w-full flex items-center justify-between gap-2 px-2 border-b border-gray-500 pb-2">
                                                    <div className="w-full text-base font-medium truncate">
                                                        <div className="w-full font-medium text-sm">
                                                            <InputField
                                                                fieldWrapperClassName="mb-0"
                                                                wrapperClass="w-full shrink-0"
                                                                name="question_name"
                                                                value={questionName}
                                                                onChange={(e) => setQuestionName(e.target.value)}
                                                                // label="Option Name"
                                                                placeholder="Question Name"
                                                                // type="number"
                                                            />
                                                        </div>

                                                    </div>
                                                    <button
                                                        title="Save"
                                                        className="size-8 flex items-center justify-center shrink-0 border border-indigo-700 text-white bg-indigo-600 rounded"
                                                        onClick={questionSubmitHandle}
                                                    >
                                                       <Icon.Save className="size-4" />
                                                    </button>
                                                    <button
                                                        title="Delete"
                                                        className="size-8 flex items-center justify-center shrink-0 bg-[#ee5555] text-white rounded"
                                                        onClick={() => {setAddNewQuestion(false)}}
                                                    >
                                                        <svg
                                                            className="size-4"
                                                            width="24"
                                                            height="24"
                                                            viewBox="0 0 15 15"
                                                            fill="currentColor"
                                                        >
                                                            <path d="M2.64 1.27 7.5 6.13l4.84-4.84A.92.92 0 0 1 13 1a1 1 0 0 1 1 1 .9.9 0 0 1-.27.66L8.84 7.5l4.89 4.89A.9.9 0 0 1 14 13a1 1 0 0 1-1 1 .92.92 0 0 1-.69-.27L7.5 8.87l-4.85 4.85A.92.92 0 0 1 2 14a1 1 0 0 1-1-1 .9.9 0 0 1 .27-.66L6.16 7.5 1.27 2.61A.9.9 0 0 1 1 2a1 1 0 0 1 1-1c.24.003.47.1.64.27" />
                                                        </svg>
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </>
                                )}
                                
                                <button
                                    title=""
                                    className="h-10 font-medium text-base w-full flex items-center justify-center shrink-0 border-[1px] border-[#6558f5a6] text-gray-500 rounded"
                                    onClick={() => {
                                        setAddNewQuestion(true)
                                    }}
                                >
                                    <Icon.Plus className="size-4" />
                                    Add Question
                                </button>
                            </div>
                        )}
                    </div>
                </div>
            </div>

            <AlertModal
                visible={alertModelVisible}
                showCrossIcon
                onSubmit={() => serviceDeleteHandler(serviceItem)}
                onCancel={() => setAlertModelVisible(false)}
                onClose={() => setAlertModelVisible(false)}
                submitButtonText="Yes"
                // secondaryLoading={updateServicesApi?.isLoading}
            >
                <p className="text-md mb-3 flex ">
                    <span>
                    Are you sure you want to delete this services ?
                    </span>
                </p>
            </AlertModal>

        </>
    )
}

export default ServicesCard