import Label from "components/FormField/common/Label";
import * as Icon from "react-feather";
import React from "react";


interface FileUploadButtonProps {
    labelName?: string | null | undefined
    fileName?: string | null | undefined
    className?: string | null | undefined
    handleChange?: any
    disabled?: boolean
    handleDeleteFile?: any
}


const FileUploadButton: React.FC<FileUploadButtonProps> = ({
    labelName,
    fileName,
    className,
    handleChange,
    disabled,
    handleDeleteFile
}) => {
    return (
        <>
        {labelName && <Label required={false} label={labelName} />}
            <div className="flex">
                <div className="flex items-center relative">
                    <button className={`text-sm font-medium py-2.5 px-4 rounded-md ${className} `}>Upload file</button>
                    
                    <input
                        type="file"
                        name="image"
                        onChange={handleChange}
                        disabled={disabled}
                        className={`absolute top-0 left-0 right-0 bottom-0 opacity-0 ${disabled ? "" : "cursor-pointer"} `}
                    />
                </div>
                {fileName &&
                <div className="flex items-center ml-2">
                    <p className={`ml-2 text-sm items-center ${disabled ? "text-gray-500" : "text-gray-800"} `}>{fileName}</p>
                    {!disabled && 
                        <p 
                            className="ml-2 p-1 bg-gray-100 rounded-full text-sm text-red-500 cursor-pointer items-center"
                            onClick={handleDeleteFile}
                            > 
                            <Icon.X className="size-5" />
                        </p>
                    }
                </div>  
                }
            </div>
        </>
    )
}

export default FileUploadButton