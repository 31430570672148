import InputField from "components/FormField/common/inputField";
import * as Icon from "react-feather";
import { RestartIcon, CheckboxIcon } from "components/Icon/SvgIcon";
import { useGetQuestionAPI, useUpdateUserQuestionAnswerAPI, useUpdateUserResponseAPI } from "modules/CostCalculator/services";
import StepButton from "modules/CostCalculator/sub-module/CostForm/StepButton";
import { createdType, optionsDataType, questionsDataType, userResponseDataType, userResponseQuestionAnswerType } from "modules/CostCalculator/types";
import React, { useEffect, useState } from "react";
import ReactPlayer from "react-player";
import { useDispatch, useSelector } from "react-redux";
import { 
    getUserCostForm,
    setSelectedServices, 
    setAnswer,
    nextStep,
    prevStep,
    setUserDetails,
    resetForm,
} from "redux/slices/userFormSlice";
import { Modal } from "components/Theme/Modal";
import Note from "components/Note";


interface EditQuestionPageProps {
    editQuestion: any;
    setEditQuestion: any;
    currentQuestionOption: userResponseQuestionAnswerType | any;
    setCurrentQuestionOption: any;
    userResponseData: userResponseDataType;
    themeData: createdType | null
}


const EditQuestionPage:React.FC<EditQuestionPageProps> = ({
    editQuestion,
    setEditQuestion,
    currentQuestionOption,
    setCurrentQuestionOption,
    userResponseData,
    themeData
}) => {

    const { getQuestionAPI, isLoading } = useGetQuestionAPI();
    const updateUserQuestionAnserApi = useUpdateUserQuestionAnswerAPI();
    const updateUserResponseApi = useUpdateUserResponseAPI();

    const dispatch = useDispatch();

    const { currentStep, selectedServices, answers, userDetails } = useSelector(getUserCostForm);

    const [modelData, setModelData] = useState<string>("");

    const [questionData, setQuestionData] = useState<questionsDataType | null | any>(null);
    // const [selectOption, setSelectOption] = useState<number | string | any>(currentQuestionOption?.question_option_id);
    const [selectOption, setSelectOption] = useState<any[]>([currentQuestionOption?.question_option_id]);
    const [userResponseId, setUserResponseId] = useState<boolean>();

    const [userValue, setUserValue] = useState<string | number>(currentQuestionOption?.user_custom_value);
    const getQuestionData = async () => {
        const { data, error } =  await getQuestionAPI(currentQuestionOption?.question_id)
        if (!error) {
            setQuestionData(data)
        }
    }

    useEffect(() => {
        if (currentQuestionOption) {
            getQuestionData()
        }
    }, [currentQuestionOption])

    const handleResetForm = () => {
        dispatch(resetForm());
    };

    const updateQuestionAnswerHandler = async () => {
        const formData = new FormData();
        const question_dict:any = { "question_id": questionData?.id };

        if (questionData?.is_number_field && userValue) {
            question_dict["user_custom_value"] = userValue;
        } else if (!questionData?.is_number_field && selectOption) {
            question_dict["selected_option"] = selectOption;
            question_dict["allow_multiple"] = questionData?.allow_multiple_selections
        }
        if (currentQuestionOption?.id) {
            question_dict["question_answer_id"] = currentQuestionOption?.id;
        }
        formData.append('question_data', JSON.stringify(question_dict))


        if (userResponseData?.id && formData) {
            const { data, error } = await updateUserResponseApi.updateUserResponseAPI(
                formData,
                userResponseData?.id
            );
            setEditQuestion(false)
        }
    };


    const handleAnswerSelect = (option: any) => {
        const allowMultiple = questionData?.allow_multiple_selections
        if (allowMultiple) {
            const includedCurrentOption = selectOption?.includes(option?.id)
            if (!includedCurrentOption) {
                setSelectOption([...selectOption, option?.id])
            } else {
                setSelectOption(selectOption.filter((id:number)=> id !== option?.id));
            }
        } else {
            setSelectOption([option?.id])
        }
    };




    return (
        <>  <div className="container h-screen px-4 mx-auto cost-calculator-user">
        <div className="size-full  flex flex-col md:py-5 py-10 md:px-0 px-10">
            <div className="flex flex-col gap-6 max-w-[992px] mx-auto w-full">
            <div className="flex flex-row justify-between">
                <div 
                    className="border-[#6558F5] border bg-[#6558F5] p-2 items-center justify-center text-white gap-2 text-white px-4 py-2 text-sm rounded-md"
                    style={{
                        backgroundColor: themeData?.button_color ? themeData?.button_color : "#6558f5",
                        color: themeData?.button_text_color ? themeData?.button_text_color : "#ffffff",
                    }}
                >Edit Question</div>
                <div>

                    <button 
                        className="bg-[#6558F5] border-[#6558F5] border flex gap-2 items-center mx-auto text-white px-4 py-2 text-sm rounded-md"
                        style={{
                            backgroundColor: themeData?.button_color ? themeData?.button_color : "#6558f5",
                            color: themeData?.button_text_color ? themeData?.button_text_color : "#ffffff",
                        }}
                        onClick={() => handleResetForm()}
                    >
                        <RestartIcon className="w-5 h-5 white-icon"></RestartIcon> Start Again
                    </button>
                </div>
            </div>
            <div className="flex-grow flex flex-col gap-4 mx-auto w-full mt-8">
                <div 
                    className="w-full text-md text-textDark font-normal text-center"
                    style={{color: themeData?.font_paragraph_color ? themeData?.font_paragraph_color : "#2d3849"}}
                    >
                    {questionData?.service?.name}
                </div>
                <div 
                    className="w-full text-4xl text-textDark font-semibold text-center"
                    style={{color: themeData?.font_heading_color ? themeData?.font_heading_color : "#2d3849"}}
                    >
                    {questionData?.question_name}
                </div>
                {questionData?.question_subtitle && (
                <div 
                    className="w-full text-sm text-textDark font-medium text-center"
                    style={{color: themeData?.font_paragraph_color ? themeData?.font_paragraph_color : "#2d3849"}}
                    >
                    {questionData?.question_subtitle}
                </div>
                )}
            </div>
            {(questionData?.learn_more_content || questionData?.video_url) && (
                <>
                <div className="flex justify-center">
                    {questionData?.learn_more_content ? (
                        <div className="px-1">
                            <button 
                                className="bg-[#6558F5] border-[#6558F5] border flex gap-2 items-center mx-auto text-white px-4 py-2 text-sm rounded-md"
                                style={{
                                    backgroundColor: themeData?.button_color ? themeData?.button_color : "#6558f5",
                                    color: themeData?.button_text_color ? themeData?.button_text_color : "#ffffff",
                                    }}
                                onClick={() => {setModelData("Learn More")}}
                            >
                                <Icon.Info className="size-4" /> Learn More
                            </button>
                        </div>
                    ) : (
                        <div className="px-1">
                        </div>
                    )}
                    {questionData?.video_url ? (
                        <div className="px-1">
                            <button 
                                className="bg-[#6558F5] border-[#6558F5] border flex gap-2 items-center mx-auto text-white px-4 py-2 text-sm rounded-md"
                                style={{
                                    backgroundColor: themeData?.button_color ? themeData?.button_color : "#6558f5",
                                    color: themeData?.button_text_color ? themeData?.button_text_color : "#ffffff",
                                    }}
                                onClick={() => {setModelData("Watch Video")}}
                            >
                                <Icon.Video className="size-4" /> Watch Video
                            </button>
                        </div>
                    ) : (
                        <div className="px-1">
                        </div>
                    )}
                </div>
                </>
            )} 
            <div className="flex-grow  flex flex-col gap-8 overflow-hidden">
                <div className="w-full max-h-full flex flex-wrap gap-4 justify-center overflow-auto pr-3">
                {questionData && questionData?.is_number_field ? (
                        <>
                            <div className="w-[150px] flex gap-1 items-center">
                                {/* <span className="font-medium text-sm">
                                    User Value
                                </span> */}
                                <InputField
                                    fieldWrapperClassName="mb-0"
                                    wrapperClass={`w-full shrink-0`}
                                    // className="no-spinner"
                                    name="max_value"
                                    value={userValue}
                                    onChange={(e) => {
                                        setUserValue(e.target.value)
                                        // handleAnswerSelect(questionData?.id, e.target.value)
                                    }}
                                    placeholder="$100"
                                    type="number"
                                />
                                <div 
                                    className="font-medium text-sm ml-2 w-[100px] text-sm"
                                    style={{color: themeData?.font_paragraph_color ? themeData?.font_paragraph_color : "#2d3849"}}
                                >
                                    {userValue && Number(userValue) > 1 ? questionData?.plural_value : questionData?.singular_value}
                                </div>
                            </div>
                        </>
                    ) : (
                        <>
                    {questionData && questionData?.options?.map((option: optionsDataType, index: number) => (
                        <div
                        key={index}
                        // className={`w-full max-w-[316px] bg-white ${ selectOption === option?.id
                        //     ? "border-2 border-primaryColor"
                        //     : "border border-gray-500"
                        //     } rounded-md flex flex-col items-center justify-center cursor-pointer py-8 px-5 gap-8 text-center hover:shadow-md`}
                        // // onClick={() => marketHandler(item)}
                        // onClick={() => setSelectOption(option?.id)}
                        className={`w-full max-w-[316px] bg-white ${selectOption?.includes(option?.id)
                            ? "border-[3px] border-primaryColor"
                            : "border border-gray-500"
                            } rounded-md flex flex-col items-center justify-center cursor-pointer py-8 px-5 gap-8 text-center hover:shadow-md`}
                        style={{borderColor: themeData?.primary_brand_color ? themeData?.primary_brand_color: "#6558f5",}}
                        onClick={() => handleAnswerSelect(option)}
                        // onClick={() => sampleFunction(questionsWithOptions[currentStep], option)}
                        >
                        <div 
                            className="w-8 h-8 border-[2px] border-textDark rounded-full flex justify-center items-center hover:scale-110 transition-all duration-200"
                            style={{backgroundColor: selectOption?.includes(option?.id) ? themeData?.primary_brand_color: "#ffffff"}}    
                        >
                            <CheckboxIcon className="w-4"></CheckboxIcon>
                        </div>
                        <div className="w-full flex items-center justify-center">
                            <label
                            htmlFor="default-radio-1"
                            className="text-gray-900 dark:text-gray-600 w-full text-base font-medium truncate"
                            style={{color: themeData?.font_paragraph_color ? themeData?.font_paragraph_color: "#2d3849"}}
                            >
                            {option?.option_name}
                            </label>
                        </div>
                        </div>
                    ))}
                    </>
                    )}
                </div>
            </div>
            <StepButton
                nextButtonLable="Update Data"
                handleNextClick={() => {updateQuestionAnswerHandler()}}
                buttonStyleProps={{
                    backgroundColor: themeData?.button_color ? themeData?.button_color : "#6558f5",
                    color: themeData?.button_text_color ? themeData?.button_text_color : "#ffffff",
                }}
            />
            {modelData !== "" && (
                <Modal
                    onSubmit={() => setModelData("")}
                    submitButtonName="Done"
                    headerName={modelData === "Learn More" ? "Learn More" : "Watch Video"}
                    modalWidth="800px"
                    onClose={() => setModelData("")}
                >
                    {modelData === "Learn More" ? (
                        <p className="text-md mb-3 flex justify-center">
                            <Note
                                content={questionData?.learn_more_content}
                                isEdit={false}
                                EditorContentClassName="w-full h-full"
                                mainDivClassName="w-full"
                                contentId={questionData?.id}
                                hasUpdatePermission={true}
                            />
                        </p>
                    ) : (
                        <p className="text-md mb-3 flex justify-center">
                            <ReactPlayer
                                url={questionData?.video_url}
                                controls
                                width="640px"
                                height="360px"
                            />
                        </p>
                    )}
                    
                </Modal>
            )}
            </div>
            </div>
                  </div>
        </>
    )
}

export default EditQuestionPage