import Breadcrumbs from "components/Breadcrumbs";
import { CheckboxIcon } from "components/Icon/SvgIcon";
import Button from "components/Theme/Button";
import { BREAD_CRUMB } from "constants/breadcrumbs.constant";
import { useGetDesignOptionsAPI, useUpdateDesignOptionsAPI } from "modules/CostCalculator/services";
import React, { useEffect, useRef, useState } from "react";
import { SketchPicker } from "react-color";

interface DesignOptionsProps {

}


const DesignOptions: React.FC<DesignOptionsProps> = () => {

    const updateDesignOptionsApi = useUpdateDesignOptionsAPI();
    const { getDesignOptionsAPI, isLoading } = useGetDesignOptionsAPI();

    const [selectedColorOptions, setSelectedColorOptions] = useState<any>();
    const [selectedOption, setSelectedOption] = useState<Number>(0);
    const [isDisabled, setDisabled] = useState<boolean>(false);

    const defaultColors = [
        { name: "Primary Brand Color", value: "#6558f5" },
        { name: "Button Color", value: "#6558f5" },
        { name: "Button Text Color", value: "#ffffff" },
        { name: "Font Heading Color", value: "#2d3849" },
        { name: "Font Paragraph Color", value: "#2d3849" },
        { name: "Background Color", value: "#ffffff" },
    ];

    const [selectableOptions, setSelectableOptions] = useState([...defaultColors]);

    // const [selectableOptions, setSelectableOptions] = useState([
    //     { name: "Primary Brand Color", value: "#6558f5" },
    //     { name: "Button Color", value: "#6558f5" },
    //     { name: "Font Heading Color", value: "#6558f5" },
    //     { name: "Font Paragraph Color", value: "#6558f5" },
    //     { name: "Background Color", value: "#ffffff" },
    // ]);

    const pickerRef = useRef(null);

    const getDesignOptionsData = async () => {
        const { data, error } =  await getDesignOptionsAPI()
        if (!error) {
            const colorArray = Object.keys(data).map((key) => ({
                name: key,
                value: data[key] ?? defaultColors.find((item) => item.name === key)?.value
              }));    
            setDisabled(true)          
            setSelectableOptions(colorArray)
        }
    }

    useEffect(() => {
        getDesignOptionsData()
    }, [])

    useEffect(() => {
        const handleClickOutside = (event: MouseEvent) => {
            if (pickerRef.current && !(pickerRef.current as any).contains(event.target)) {
                setSelectedColorOptions(null);
            }
        };

        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);


    const handleChange = (newColor: any) => {
        setSelectableOptions((prevOptions) =>
            prevOptions.map((item) =>
                item.name === selectedColorOptions.name ? { ...item, value: newColor.hex } : item
            )
        );
        setSelectedColorOptions((prev:any) => ({
            ...prev,
            value: newColor.hex,
        }));
        setDisabled(false)
    };

    const resetToDefault = () => {
        setSelectableOptions([...defaultColors]); // Reset to original colors
        setSelectedColorOptions(null); // Close SketchPicker
    };

    const handleUpdateThemeSettings = async () => {

        const formData = new FormData();
        if (selectableOptions) {
            selectableOptions.forEach((item, index) => {
                formData.append(String(item?.name), item?.value)
            })
        }
        const { data, error } = await updateDesignOptionsApi.updateDesignOptionsAPI(
            formData,
        );
        if (!error) {
            setDisabled(true)
        }
    }


    return (
        <>
            <Breadcrumbs path={BREAD_CRUMB.DesignOption} />  
            <div className="px-10 py-8 sm:px-0 sm:py-0">
            <h1 className="w-full md:text-2xl text-3xl font-medium mb-4"> Design Options</h1>
            <h4 className="w-full md:text-md text-md font-normal mb-4"> Tweak your estimators to match your brand colours to create a uniform look and feel.
            </h4>
            <div className="flex md:flex-wrap">
                <div className="w-[40%] md:w-full">
                    {selectableOptions?.map((item, index) => {
                        return (
                            <>
                            <div className="py-3">
                                <p className="text-gray-700 text-base font-normal">{item?.name}</p>
                                <div 
                                    className="p-2 mt-2 border border-black rounded-md"
                                    onClick={() => setSelectedColorOptions(item)}
                                    >
                                    <div
                                        style={{
                                        backgroundColor: item?.value,
                                        }}
                                        className="w-full h-[20px] border rounded-md"
                                    ></div>
                                </div>
                                {selectedColorOptions && selectedColorOptions.name === item?.name && (
                                    <div ref={pickerRef} className="absolute z-10 mt-2">
                                        <SketchPicker color={selectedColorOptions?.value} onChange={handleChange} />
                                    </div>
                                )}
                            </div>
                            </>
                        )
                    })}
                    
                    <div className="w-full flex items-center justify-end gap-3 mt-2 overflow-hidden">
                        <Button 
                            className="button__DSD text-[#4c60e9] bg-white border border-[#4c60e9] h-9"
                            onClick={resetToDefault}
                        >Reset to Default</Button>
                        <Button 
                            className="primary__Btn w-fit h-9"
                            onClick={handleUpdateThemeSettings}
                            disabled={isDisabled}
                        >Update Data</Button>
                    </div>
                </div>
                { selectableOptions && 
                <div className="w-[60%] md:w-full pl-5 md:pl-0 sm:pt-4">
                    <h3 className="w-full md:text-base text-xl font-normal mb-6"> Preview with example content</h3>
                    <div 
                        className="px-4 py-7 border rounded-lg border-gray-800 mb-5"
                        style={{
                            backgroundColor:
                                selectableOptions.find((item) => item.name === "Background Color")?.value || "#6558f5",
                        }}
                    >
                        <h4 
                            className="font-medium text-lg mb-6"
                            style={{
                                color: selectableOptions.find((item) => item.name === "Font Heading Color")?.value || "#2d3849",
                            }}
                        >Example Heading</h4>
                        <p 
                            className="mb-4"
                            style={{
                                color: selectableOptions.find((item) => item.name === "Font Paragraph Color")?.value || "#2d3849",
                            }}
                        >Lorem ipsum dolor sit amet consectetur adipisicing elit. Nisi consequuntur exercitationem recusandae eveniet quis porro quisquam adipisci veniam illo hic illum, eos sequi iste sed vel cumque corporis esse quibusdam?</p>
                        {/* <button className={`text-white py-2 px-5 rounded-md ${ selectableOptions && selectableOptions?.filter((item)=> item.name === "Button Color") ? `bg-[${selectableOptions.find((item) => item.name === "Button Color")?.value}]` : "bg-primaryColor"}`}>Example</button> */}
                        <button
                            className="text-white py-2 px-5 rounded-md"
                            style={{
                                backgroundColor: selectableOptions.find((item) => item.name === "Button Color")?.value || "#6558f5",
                                color: selectableOptions.find((item) => item.name === "Button Text Color")?.value || "#ffffff",
                            }}
                        >Example</button>
                    </div>
                    <div 
                        className="px-4 py-7 border rounded-lg border-gray-800"
                        style={{
                            backgroundColor:
                                selectableOptions.find((item) => item.name === "Background Color")?.value || "#ffffff",
                        }}
                    >
                        <h4 
                            className="md:text-base text-xl font-normal mb-6 text-center uppercase mb-5"
                            style={{
                                color: selectableOptions.find((item) => item.name === "Font Paragraph Color")?.value || "#2d3849",
                            }}
                        >My Service</h4>
                        <div 
                            className="w-full text-4xl text-textDark font-semibold text-center"
                            style={{
                                color: selectableOptions.find((item) => item.name === "Font Heading Color")?.value || "#2d3849",
                            }}
                        >
                            A question for your user?
                        </div>
                        <div className="flex-grow  flex flex-col gap-8 overflow-hidden mt-8">
                            <div className="w-full max-h-full flex flex-wrap gap-4 justify-center overflow-auto pr-3">                                
                                {Array.from({ length: 3 }).map((_, index) => (
                                    <div
                                    key={index}
                                    className={`w-full group max-w-[250px] bg-white ${selectedOption === index
                                        ? `border-[3px] border-primaryColor`
                                        : "border border-gray-500"
                                        } rounded-md flex flex-col items-center justify-center cursor-pointer py-8 px-5 gap-8 text-center hover:shadow-md`}
                                    style={{
                                        borderColor:
                                            selectableOptions.find((item) => item.name === "Primary Brand Color")?.value || "#6558f5",
                                    }}
                                    onClick={() => setSelectedOption(index)}
                                    >
                                    <div 
                                        className={`w-8 h-8 rounded-full flex justify-center items-center transition-all duration-200 border-[2px] border-textDark group-hover:scale-110 ${selectedOption === index ? selectableOptions.find((item) => item.name === "Primary Brand Color")?.value : "#ffffff"}`}
                                        style={{
                                            backgroundColor:
                                            selectedOption === index ? selectableOptions.find((item) => item.name === "Primary Brand Color")?.value : "#ffffff",
                                        }}
                                    >
                                        <CheckboxIcon className="w-4"></CheckboxIcon>
                                    </div>
                                    <div className="w-full flex items-center justify-center">
                                        <label
                                        htmlFor="default-radio-1"
                                        className="text-gray-900 dark:text-gray-600 w-full text-base font-medium truncate"
                                        >
                                        Option 1
                                        </label>
                                    </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                </div>
                }
                </div>
            </div>
            
        </>
    )
}

export default DesignOptions