import { DownloadIcon2 } from "components/Icon/SvgIcon";
import { useGetUserResponseFormAPI, useGetUserResponsePublicFormAPI, useSendUserResponseEmailAPI } from "modules/CostCalculator/services";
import { createdType, userResponseDataType, userResponseQuestionAnswerType, userResponseServicesDataType } from "modules/CostCalculator/types";
import React, { useEffect, useRef, useState } from "react";
import EditQuestionPage from "../UserForm/components/EditQuestionPage";
import * as Icon from "react-feather";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import Breadcrumbs from "components/Breadcrumbs";
import { BREAD_CRUMB } from "constants/breadcrumbs.constant";
import store from "redux/store";
import { useParams } from "react-router-dom";
import { PRIVATE_NAVIGATION } from "constants/navigation.constant";
import Note from "components/Note";
import PageLoader from "components/Loaders/PageLoader";


interface EstimatorPageProps {
    userResponseId?: number | undefined | any; 
    isUser?: boolean
}

interface totalAmountType {
    minTotal: number,
    maxTotal: number, 
}


const EstimatorPage: React.FC<EstimatorPageProps> = ({
    userResponseId,
    isUser
}) => {

    const storeData = store.getState();
    const { id, code } = useParams();

    const is_super_user = storeData?.auth?.user?.is_super_administrator;

    const pageRef = useRef<HTMLDivElement>(null);

    const { getUserResponseFormAPI } = useGetUserResponseFormAPI();
    const { getUserResponsePublicFormAPI } = useGetUserResponsePublicFormAPI();

    const sendUserResponseEmailAPI = useSendUserResponseEmailAPI();

    const [userResponseData, setUserResponseData] = useState<userResponseDataType | null | any>(null);
    const [totalAmount, setTotalAmount] = useState<totalAmountType>({ minTotal: 0, maxTotal:0})
    const [themeData, setThemeData] = useState<createdType | null>(null);

    const [isDisabled, setDisabled] = useState<boolean>(false);
    
    // ============ Edit Questions =============================
    const [editQuestion, setEditQuestion] = useState<boolean>(false)
    const [currentQuestionOption, setCurrentQuestionOption] = useState<userResponseQuestionAnswerType>()


    const getUserResponseData = async () => {
        if (id || userResponseId) {
            const responseId = userResponseId || id;
            const { data, error } = await getUserResponseFormAPI(responseId);
            console.log(data, "---------data--------data-------data------data-data-data-data-data-v-----")
            if (!error) {
                setUserResponseData(data);
                setThemeData(data?.form?.created);
            }
        } else if (code) {
            const { data, error } = await getUserResponsePublicFormAPI(code);
            if (!error) {
                setUserResponseData(data);
                setThemeData(data?.form?.created);
            }
        }
    };

    useEffect(() => {
        getUserResponseData();
    }, [userResponseId, id, code, editQuestion]);

    const findParentQuestionData = (item: userResponseQuestionAnswerType) => {
        if (item?.question?.parent) {
          return userResponseData?.user_question_answers.find(
            (entry: userResponseQuestionAnswerType) => entry?.question_id === item?.question?.parent.id
          );
        }
        return null;
    };

    const calculateFinalTotal = () => {
        if (userResponseData) {
            let minTotal = 0
            let maxTotal = 0
            userResponseData?.user_question_answers?.forEach((item:userResponseQuestionAnswerType, index: number) => {
                if (item?.question?.is_number_field) {
                    minTotal = minTotal + (Number(item?.user_custom_value) * Number(item?.question?.fixed_unit_price))
                    maxTotal = maxTotal + (Number(item?.user_custom_value) * Number(item?.question?.fixed_unit_price))
                } else if (item?.question_option?.question_value_type === "Cost") {
                    if (item?.question?.is_multiply_all_cost && item?.question?.question_id) {
                        const question_new = findParentQuestionData(item)
                        if (question_new) { 
                            minTotal = minTotal + (Number(item?.question_option?.other_value) * Number(question_new?.user_custom_value))
                            maxTotal = maxTotal + (Number(item?.question_option?.other_value) * Number(question_new?.user_custom_value))
                        } else {
                            minTotal = minTotal + Number(item?.question_option?.other_value)
                            maxTotal = maxTotal + Number(item?.question_option?.other_value)
                        }
                    } else { 
                        minTotal = minTotal + Number(item?.question_option?.other_value)
                        maxTotal = maxTotal + Number(item?.question_option?.other_value)
                    }
                } else if (item?.question_option?.question_value_type === "Range") {
                    if (item?.question?.is_multiply_all_cost && item?.question?.question_id) {
                        const question_new = findParentQuestionData(item)
                        if (question_new) { 
                            minTotal = minTotal + (Number(item?.question_option?.min_value) * Number(question_new?.user_custom_value))
                            maxTotal = maxTotal + (Number(item?.question_option?.max_value) * Number(question_new?.user_custom_value))
                        } else {
                            minTotal = minTotal + Number(item?.question_option?.min_value)
                            maxTotal = maxTotal + Number(item?.question_option?.max_value)
                        }
                    } else { 
                        minTotal = minTotal + Number(item?.question_option?.min_value)
                        maxTotal = maxTotal + Number(item?.question_option?.max_value)
                    }
                }
            });

            userResponseData?.user_response_services.forEach((item2:userResponseServicesDataType, index: number) => {
                minTotal = minTotal + Number(item2?.form_service?.base_cost)
                maxTotal = maxTotal + Number(item2?.form_service?.base_cost)
            });

            setTotalAmount({minTotal, maxTotal})
        }
    }

    const calculateServicesTotal = (service: userResponseServicesDataType) => {
        if (userResponseData) {
            let minTotal = 0
            let maxTotal = 0
            userResponseData?.user_question_answers.forEach((item:userResponseQuestionAnswerType, index: number) => {
                if (service?.form_services_id === item?.question?.form_services_id) {
                    if (item?.question?.is_number_field) {
                        minTotal = minTotal + (Number(item?.user_custom_value) * Number(item?.question?.fixed_unit_price))
                        maxTotal = maxTotal + (Number(item?.user_custom_value) * Number(item?.question?.fixed_unit_price))
                    } else if (item?.question_option?.question_value_type === "Cost") {

                        if (item?.question?.is_multiply_all_cost && item?.question?.question_id) {
                            const question_new = findParentQuestionData(item)
                            if (question_new) {
                                minTotal = minTotal + (Number(item?.question_option?.other_value) * Number(question_new?.user_custom_value))
                                maxTotal = maxTotal + (Number(item?.question_option?.other_value) * Number(question_new?.user_custom_value))
                            } else {
                                minTotal = minTotal + Number(item?.question_option?.other_value)
                                maxTotal = maxTotal + Number(item?.question_option?.other_value)
                            }
                        } else {
                            minTotal = minTotal + Number(item?.question_option?.other_value)
                            maxTotal = maxTotal + Number(item?.question_option?.other_value)
                        }
                    } else if (item?.question_option?.question_value_type === "Range") {
                        if (item?.question?.is_multiply_all_cost && item?.question?.question_id) {
                            const question_new = findParentQuestionData(item)
                            if (question_new) {
                                minTotal = minTotal + (Number(item?.question_option?.min_value) * Number(question_new?.user_custom_value))
                                maxTotal = maxTotal + (Number(item?.question_option?.max_value) * Number(question_new?.user_custom_value))

                            } else {
                                minTotal = minTotal + Number(item?.question_option?.min_value)
                                maxTotal = maxTotal + Number(item?.question_option?.max_value)    
                            }
                        } else {
                            minTotal = minTotal + Number(item?.question_option?.min_value)
                            maxTotal = maxTotal + Number(item?.question_option?.max_value)
                        }
                    }
                }
            });
            minTotal = minTotal + Number(service?.form_service?.base_cost)
            maxTotal = maxTotal + Number(service?.form_service?.base_cost)
            return [minTotal, maxTotal]
        } else {
            return [0, 0]
        }
    }


    const calculateQuestionAnswerTotal = (item:userResponseQuestionAnswerType) => {
            let minTotal = 0
            let maxTotal = 0
            if (item?.question?.is_number_field) {
                minTotal = minTotal + (Number(item?.user_custom_value) * Number(item?.question?.fixed_unit_price))
                maxTotal = maxTotal + (Number(item?.user_custom_value) * Number(item?.question?.fixed_unit_price))
            } else if (item?.question_option?.question_value_type === "Cost") {

                if (item?.question?.is_multiply_all_cost && item?.question?.question_id) {
                    const question_new = findParentQuestionData(item)
                    if (question_new) {
                        minTotal = minTotal + (Number(item?.question_option?.other_value) * Number(question_new?.user_custom_value))
                        maxTotal = maxTotal + (Number(item?.question_option?.other_value) * Number(question_new?.user_custom_value))
                    } else {
                        minTotal = minTotal + Number(item?.question_option?.other_value)
                        maxTotal = maxTotal + Number(item?.question_option?.other_value)
                    }
                } else {
                    minTotal = minTotal + Number(item?.question_option?.other_value)
                    maxTotal = maxTotal + Number(item?.question_option?.other_value)
                }
            } else if (item?.question_option?.question_value_type === "Range") {
                if (item?.question?.is_multiply_all_cost && item?.question?.question_id) {
                    const question_new = findParentQuestionData(item)
                    if (question_new) {
                        minTotal = minTotal + (Number(item?.question_option?.min_value) * Number(question_new?.user_custom_value))
                        maxTotal = maxTotal + (Number(item?.question_option?.max_value) * Number(question_new?.user_custom_value))

                    } else {
                        minTotal = minTotal + Number(item?.question_option?.min_value)
                        maxTotal = maxTotal + Number(item?.question_option?.max_value)    
                    }
                } else {
                    minTotal = minTotal + Number(item?.question_option?.min_value)
                    maxTotal = maxTotal + Number(item?.question_option?.max_value)
                }
            }
            return [minTotal, maxTotal]
    }



    useEffect(() => {
        if (userResponseData) {
            calculateFinalTotal()
        }
    }, [userResponseData])

    const generatePdfAndSend = async () => {
        try {
            if (!pageRef.current) return;
            setDisabled(true)
            // const canvas = await html2canvas(pageRef.current);
            // const imgData = canvas.toDataURL("image/png");

            // const pdf = new jsPDF();
            // pdf.addImage(imgData, "PNG", 10, 10, 180, 160);
            // const pdfBlob = new Blob([pdf.output("blob")], { type: "application/pdf" });

            const formData = new FormData();
            formData.append("email", userResponseData?.user_email);
            formData.append("user_response_id", userResponseData?.id);
            // formData.append("file", pdfBlob, `${userResponseData?.user_name}_estimator.pdf`);
    
            const { data, error } = await sendUserResponseEmailAPI.sendUserResponseEmailAPI(formData);
            setDisabled(false)
        } catch (error) {
            setDisabled(false)
            // setStatus("Failed to send email");
        }
      };


    const adminBreadCrumb = {
        trails: [
          {
            title: "Cost Calculator",
            path: PRIVATE_NAVIGATION.costcalculator.view,
          },
          {
            title: "User Responses",
            path: PRIVATE_NAVIGATION.costcalculator.userResponses.view,
            state: {id : userResponseData?.form_id}
          },
        ],
        title: "View",
    }

    const superAdminBreadCrumb = {
        trails: [
          {
            title: "Cost Calculator",
            path: PRIVATE_NAVIGATION.adminCostCalculator.costFormPermission.view,
          },
          {
            title: "User Responses",
            path: PRIVATE_NAVIGATION.adminCostCalculator.userResponsesPermission.view,
            state: {id : userResponseData?.form_id}
          },
        ],
        title: "View",
    }

    return (
        <>  
        {isDisabled && <PageLoader />}
        {userResponseData && !editQuestion && (
            <>
            {!isUser && !code && (
                <Breadcrumbs path={is_super_user ? superAdminBreadCrumb : adminBreadCrumb} />
            )}
            <div 
                className="w-full"
                style={{backgroundColor: themeData?.background_color ? themeData?.background_color: "#ffffff"}}
                >
            <div className="container mx-auto px-4 pt-4" ref={pageRef}>
                <div className="flex justify-end gap-2 mb-4">
                    {(isUser || code) && (
                      <>
                      <button
                        title="Email"
                        onClick={() => generatePdfAndSend()} 
                        className="w-8 h-8 rounded bg-[#6558f5] flex items-center justify-center"
                        style={{
                            backgroundColor:themeData && themeData?.button_color ? themeData?.button_color : "#6558f5",
                            color:themeData && themeData?.button_text_color ? themeData?.button_text_color : "#ffffff",
                          }}
                        >
                        <Icon.Send 
                            className="w-4 text-white" 
                            style={{
                                color:themeData && themeData?.button_text_color ? themeData?.button_text_color : "#ffffff",
                            }}
                          >
                        </Icon.Send>
                      </button>
                      <button 
                        title="Print" 
                        className="w-8 h-8 rounded bg-[#6558f5] flex items-center justify-center"
                        onClick={() => {window.print()}}
                        style={{
                            backgroundColor: themeData?.button_color ? themeData?.button_color : "#6558f5",
                            color: themeData?.button_text_color ? themeData?.button_text_color : "#ffffff",
                        }}
                      >
                        <DownloadIcon2 className="w-4" styleProps={
                             themeData?.button_text_color ? { fill:  themeData?.button_text_color } : { fill : "#ffffff"}
                          }></DownloadIcon2>
                      </button>
                      </>  
                    )}
                </div>
                <h1 
                    className="font-semibold text-3xl text-textDark text-center mb-4"
                    style={{color: themeData?.font_heading_color ? themeData?.font_heading_color: "#2d3849"}}
                    >
                    {userResponseData?.form?.intro_heading ? userResponseData?.form?.intro_heading : "Estimate Costs Details"}
                </h1>
                <h3 
                    className="font-medium text-md text-textDark text-center mb-3"
                    style={{color: themeData?.font_paragraph_color ? themeData?.font_paragraph_color: "#2d3849"}}
                    >
                    {userResponseData?.form?.intro_text ? userResponseData?.form?.intro_text : "You're on Bing"}
                </h3>
                <div className="max-w-[900px] mx-auto my-6">
                    <div className="flex gap-2 items-center justify-start py-2">
                        <div className="min-w-20">
                            <h3 className="text-base text-textDark text-left font-semibold">Name </h3>
                        </div>
                        <div>
                            <h3 className="text-base text-left text-gray-700"><span className="mr-2">:</span> {userResponseData?.user_name}</h3>
                        </div>
                    </div>
                    <div className="flex gap-2 items-center justify-start py-2">
                        <div className="min-w-20">
                            <h3 className="text-base text-textDark text-left font-semibold">Email </h3>
                        </div>
                        <div>
                            <h3 className="text-base text-left text-gray-700"><span className="mr-2">:</span> {userResponseData?.user_email}</h3>
                        </div>
                    </div>
                    <div className="flex gap-2 items-center justify-start py-2">
                        <div className="min-w-20">
                            <h3 className="text-base text-textDark text-left font-semibold">Phone </h3>
                        </div>
                        <div>
                            <h3 className="text-base text-left text-gray-700"><span className="mr-2">:</span> {userResponseData?.user_phone}</h3>
                        </div>
                    </div>
                    <div className="flex gap-2 items-center justify-start py-2">
                        <div className="min-w-20">
                            <h3 className="text-base text-textDark text-left font-semibold">Zip Code </h3>
                        </div>
                        <div>
                            <h3 className="text-base text-left text-gray-700"><span className="mr-2">:</span> {userResponseData?.user_zip}</h3>
                        </div>
                    </div>

                </div>
                <div className="max-w-[900px] mx-auto my-6">
                    <h4 className="text-lg text-textDark font-semibold mb-1">Estimate Costs</h4>
                    <div className="px-5 pt-3 pb-5 border border-textDark rounded-md">
                        <div className="flex gap-5 items-center justify-between py-3 border-b border-b-textDark">
                            <div>
                                <h3 className="text-base text-textDark text-right font-semibold">Item</h3>
                            </div>
                            <div>
                                <h3 className="text-base text-textDark text-right font-semibold">Price</h3>
                            </div>
                        </div>
                        {userResponseData?.user_response_services?.map((service: userResponseServicesDataType, index: number) => {
                            const [minTotal, maxTotal] = calculateServicesTotal(service)
                            return (
                                <>
                                <div className="flex gap-5 items-center justify-between py-3 border-b border-b-textDark">
                                    <div>
                                        <h3 className="text-base text-textDark text-right font-semibold">{service?.form_service?.service?.name}</h3>
                                    </div>
                                    <div>
                                        <h3 className="text-base text-textDark text-right font-semibold">{minTotal === maxTotal ? `$ ${minTotal}` : `$ ${minTotal} - $ ${maxTotal}`}</h3>
                                    </div>
                                </div>
                                <div className="flex gap-5 items-center justify-between py-3 border-b border-b-textDark">
                                    <div className="pl-4">
                                        <h3 className="text-base text-textDark text-right font-medium">Base Cost</h3>
                                    </div>
                                    <div className="flex justify-end gap-4">
                                        {/* <p className="text-base text-gray-600"><a href="" className="underline">edit</a></p> */}
                                        <h3 className="text-base text-textDark text-right font-medium min-w-20">$ {service?.form_service?.base_cost ? service?.form_service?.base_cost : 0}</h3>
                                    </div>
                                </div>
                                {userResponseData?.user_question_answers?.map((question_answer: userResponseQuestionAnswerType, index: number) => {
                                    const [answer_minTotal, answer_maxTotal] = calculateQuestionAnswerTotal(question_answer)
                                    return (
                                        <>
                                        { question_answer?.question?.form_services_id === service?.form_services_id ? (
                                            <>
                                                <div className="flex gap-5 items-center justify-between py-3 border-b border-b-textDark">
                                                    <div className="flex gap-2 items-center pl-4">
                                                        {/* <div className="w-14 h-10 border border-textDark rounded flex items-center justify-center overflow-hidden">
                                                            <img src="" className="w-full h-full object-cover" />
                                                        </div> */}
                                                        <div>
                                                            {question_answer?.question?.is_number_field ? (
                                                                 <h3 className="text-base text-textDark font-medium">
                                                                    {question_answer?.user_custom_value} {question_answer?.user_custom_value && (question_answer?.user_custom_value > 1) ? question_answer?.question?.plural_value : question_answer?.question?.singular_value}
                                                                </h3>
                                                            ) : (
                                                                <h3 className="text-base text-textDark font-medium">
                                                                    {question_answer?.question_option?.option_name}
                                                                </h3>
                                                            )}
                                                            <p className="text-xs text-textDark/75">{question_answer?.question?.question_name}</p>
                                                        </div>
                                                    </div>
                                                    <div className="flex justify-end gap-4">
                                                        {(isUser || code) && (
                                                            <p 
                                                                className="text-sm text-gray-800 cursor-pointer bg-gray-200 border rounded-lg py-1 px-2"
                                                                onClick={() => {
                                                                    setEditQuestion(true)
                                                                    setCurrentQuestionOption(question_answer)
                                                                }}
                                                            >
                                                                <a className="underline">Edit</a>
                                                            </p>
                                                        )}
                                                        {question_answer?.question?.is_multiply_all_cost ? (
                                                            <>
                                                                <h3 className="text-base text-textDark text-right font-medium min-w-20">$ {answer_minTotal === answer_maxTotal ? answer_minTotal : `${answer_minTotal} - ${answer_maxTotal}`}</h3>
                                                            </>
                                                        ) : question_answer?.question?.is_number_field ? (
                                                            <h3 className="text-base text-textDark text-right font-medium min-w-20">$ {Number(question_answer?.user_custom_value) * Number(question_answer?.question?.fixed_unit_price)}</h3>
                                                        ) : question_answer?.question_option?.question_value_type === "Cost" ? (
                                                            <h3 className="text-base text-textDark text-right font-medium min-w-20">$ {question_answer?.question_option?.other_value ? question_answer?.question_option?.other_value : 0}</h3>
                                                        ) : (
                                                            <h3 className="text-base text-textDark text-right font-medium min-w-20">$ {question_answer?.question_option?.min_value ? question_answer?.question_option?.min_value : 0} - $ {question_answer?.question_option?.max_value ? question_answer?.question_option?.max_value : 0}</h3>
                                                        )}
                                                    </div>
                                                </div>
                                            </>
                                        ) : ""}
                                        </>
                                    )
                                })}
                                </>
                            )
                        })}
                        {/* <div className="flex gap-5 items-center justify-between py-3 border-b border-b-gray-300">
                            <div>
                                <h3 className="text-base text-textDark text-right font-semibold">Dental Implants</h3>
                            </div>
                            <div>
                                <h3 className="text-base text-textDark text-right font-semibold">$5300 - $8300</h3>
                            </div>
                        </div>
                        <div>
                            <div className="flex gap-5 items-center justify-between py-3 border-b border-b-gray-300">
                                <div className="pl-4">
                                    <h3 className="text-base text-textDark text-right font-medium">Dental Implants</h3>
                                </div>
                                <div className="flex justify-end gap-4">
                                    <p className="text-base text-gray-600"><a href="" className="underline">edit</a></p>
                                    <h3 className="text-base text-textDark text-right font-medium min-w-48">$5300 - $8300</h3>
                                </div>
                            </div>
                            <div className="flex gap-5 items-center justify-between py-3 border-b border-b-gray-300">
                                <div className="flex gap-2 items-center pl-4">
                                    <div className="w-14 h-10 border border-textDark rounded flex items-center justify-center overflow-hidden">
                                        <img src="" className="w-full h-full object-cover" />
                                    </div>
                                    <div>
                                        <h3 className="text-base text-textDark font-medium">Dental Implants</h3>
                                        <p className="text-xs text-textDark/75">What type</p>
                                    </div>
                                </div>
                                <div className="flex justify-end gap-4">
                                    <p className="text-base text-gray-600"><a href="" className="underline">edit</a></p>
                                    <h3 className="text-base text-textDark text-right font-medium min-w-48">$5300 - $8300</h3>
                                </div>
                            </div>
                            <div className="flex gap-5 items-center justify-between py-3 border-b border-b-gray-300">
                                <div className="flex gap-2 items-center pl-4">
                                    <div>
                                        <h3 className="text-base text-textDark font-medium">Dental Implants</h3>
                                        <p className="text-xs text-textDark/75">What type</p>
                                    </div>
                                </div>
                                <div className="flex justify-end gap-4">
                                    <p className="text-base text-gray-600"><a href="" className="underline">edit</a></p>
                                    <h3 className="text-base text-textDark text-right font-medium min-w-48">$5300 - $8300</h3>
                                </div>
                            </div>
                        </div> */}
                        <div className="flex items-center justify-end gap-3 py-3">
                            <h3 className="text-lg text-textDark font-medium">Total Estimate: </h3>
                            <div 
                                className="text-white bg-indigo-600 p-2 rounded-3xl"
                                style={{
                                    backgroundColor: themeData?.button_color ? themeData?.button_color : "#6558f5",
                                    color: themeData?.button_text_color ? themeData?.button_text_color : "#ffffff",
                                }}
                                >
                                <p 
                                    className="text-white text-base"
                                    style={{
                                        color: themeData?.button_text_color ? themeData?.button_text_color : "#ffffff",
                                    }}
                                    >{totalAmount?.minTotal === totalAmount?.maxTotal ? `$ ${totalAmount?.minTotal}` : `$ ${totalAmount?.minTotal} - $ ${totalAmount?.maxTotal}` }</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className={themeData?.background_color ? `bg-[${themeData?.background_color}]` : ""}>
                    {userResponseData?.form?.explanation_content && (
                        <Note
                            content={userResponseData?.form?.explanation_content}
                            isEdit={false}
                            EditorWrapperClassName="w-full"
                            EditorContentClassName={`h-full shadow-none ${themeData?.background_color ? `bg-[${themeData?.background_color}]` : "" } `}
                            mainDivClassName="w-full h-full"
                            contentId={userResponseData?.form?.id}
                            hasUpdatePermission={true}
                        />
                    )}
                </div>
            </div>
            </div>
            </>
        )}
        {editQuestion && (
            <>
                <EditQuestionPage
                    editQuestion={editQuestion}
                    setEditQuestion={setEditQuestion}
                    currentQuestionOption={currentQuestionOption}
                    setCurrentQuestionOption={setCurrentQuestionOption}
                    userResponseData={userResponseData}
                    themeData={themeData}
                />
            </>
        )}
        </>
    )
}

export default EstimatorPage