// ** packages **
import { useEffect, useMemo, useState } from "react";
import { MoreVertical } from "react-feather";
import { format as Format } from "date-fns";

// ** components **
import TableCellRender from "components/DataTable/components/TableCellRender";
import TippyDropdown from "components/TippyDropDown";
// ** helper **
import { renderOptions } from "components/TippyDropDown/helper";
// **  constant **
import { PERMISSIONS } from "constants/Permissions.constant";
import usePermission from "hooks/usePermission";
import { firstCharUpperCase } from "utils";

// ** store **
import store from "redux/store";
import DateFormat from "components/DateFormat";
import { IndustryColumnPropsType } from "../types";

const IndustryColumn = (props: IndustryColumnPropsType) => {
  const { setIsOpen, onChangeStatus } = props;

  const { hasPermission } = usePermission();

  const storeData = store.getState();
  const is_super_user = storeData?.auth?.user?.is_super_administrator;
//   const { hasPermission } = usePermission();
  const customPromptUpdateRole = hasPermission(
    PERMISSIONS.CUSTOM_PROMPT.UPDATE
  );
  const customPromptDeleteRole = hasPermission(
    PERMISSIONS.CUSTOM_PROMPT.DELETE
  );

  const [windowSize, setWindowSize] = useState(window.innerWidth);

  const handleResize = () => {
    if (
      (window.innerWidth > 1100 && windowSize < 1100) ||
      (window.innerWidth < 1100 && windowSize >= 1100)
    ) {
      setWindowSize(window.innerWidth);
    }
  };

  useEffect(() => {
    window.addEventListener("resize", handleResize);

    // Cleanup function to remove event listener
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [windowSize]);

  const columnDefs = useMemo(
    () => [
      windowSize >= 1300
        ? {
            name: "Name",
            sortField: "name",
            // width: "12%",
            selector: (row: { name: string }) => row.name,
            sortable: true,
            cell: (row: { name: string | number; is_draft: boolean }) => (
              <TableCellRender
                key={row.name}
                className="py-3"
                cellRowData={
                  <div className="flex items-center gap-2">
                    {row.name}
                    {row.is_draft && (
                      <p className="text-[12px] font-normal text-white bg-[#1ac01a] py-1 px-2 rounded-full w-fit inline-block whitespace-nowrap">
                        Draft
                      </p>
                    )}
                  </div>
                }
              />
            ),
          }
        : {
            name: "Name",
            selector: (row: { name: string }) => row?.name,
            sortable: true,
            sortField: "name",
            cell: (row: { name: string | number; is_draft: boolean }) => (
              <TableCellRender
                className="flex overflow-hidden py-3"
                cellRowData={
                  <>
                    <div className="flex items-center gap-2">
                    {row.name}
                    {row.is_draft && (
                      <p className="text-[12px] font-normal text-white bg-[#1ac01a] py-1 px-2 rounded-full w-fit inline-block whitespace-nowrap">
                        Draft
                      </p>
                    )}
                  </div>
                  </>
                }
              />
            ),
          },
    //   windowSize >= 1300 && {
    //     name: "Created Date",
    //     // width: "10%",
    //     sortField: "created_at",
    //     selector: (row: { created_at: string }) => row.created_at,
    //     sortable: true,
    //     cell: (row: { created_at: string }) => (
    //       <TableCellRender
    //         key={row.created_at}
    //         className="py-3"
    //         cellRowData={Format(new Date(row.created_at), "MM/dd/yyyy")}
    //       />
    //     ),
    //   },
      windowSize >= 1300 && {
        name: "Created by",
        // width: "12%",
        sortField: "email",
        selector: (row: { created: { email: string | null } }) =>
          row?.created?.email,
        sortable: false,
        cell: (row: {
          created: { email: string | null };
          static: boolean | null;
        }) => (
          <TableCellRender
            key={row.created?.email}
            className="py-3"
            cellRowData={
              row?.static ? "System Default" : row?.created?.email || "-"
            }
          />
        ),
      },
      windowSize >= 1300 && {
        name: "Status",
        width: "10%",
        sortField: "is_deactivate",
        selector: (row: { is_deactivate: boolean }) => row.is_deactivate,
        sortable: true,
        cell: (row: {
          id: number | string;
          is_deactivate: boolean;
          is_draft: boolean;
        }) => (
          <TableCellRender
            key={row.id}
            className={
              row.is_deactivate ? "text-red-700 py-3" : "text-sky-600 py-3"
            }
            cellRowData={firstCharUpperCase(
              row.is_deactivate ? "Inactive" : "Active"
            )}
          />
        ),
      },
      {
        name: "Action",
        selector: (row: { id: number }) => row.id,
        sortable: false,
        width: "6%",
        cell: (row: {
          id: number;
          static: boolean | null;
          is_deactivate: boolean;
          is_draft: boolean;
          is_deleted: boolean;
        }) => {
          const industryAdd: {
            label: string;
            onClick: () => void;
          }[] = [];

          row?.static && !is_super_user && row.static ? (
            <></>
          ) : (
            industryAdd.push({
              label: customPromptUpdateRole ? "View Industry" : "",
              onClick: () => {
                setIsOpen({
                  open: true,
                  id: row.id,
                  isDefault: row?.static ?? false,
                  viewOnly: row.is_draft ? !customPromptUpdateRole : true,
                });
              },
            })
          );
          customPromptUpdateRole &&
            !row?.is_deleted &&
            !row.is_draft &&
            industryAdd.push({
              label: row.is_deactivate ? "Active" : "Inactive",
              onClick: () => {
                onChangeStatus(
                  String(row.id), 
                  "status",
                  row.is_deactivate ? "Active" : "Inactive",
                  row?.static
                );
              },
            });

          customPromptDeleteRole &&
            (!row?.static || is_super_user) &&
            !row?.is_deleted &&
            industryAdd.push({
              label: "Delete Industry",
              onClick: () => {
                onChangeStatus(row?.id, "delete", null, row?.static);
              },
            });
          return (
            <TippyDropdown
              content={({ close }) =>
                renderOptions({ close, filedArray: industryAdd })
              }
            >
              <MoreVertical />
            </TippyDropdown>
          );
        },
      },
    ],
    []
  );
  return columnDefs;
};

export default IndustryColumn;
