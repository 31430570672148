// ** hooks **
import { AxiosRequestConfig } from "axios";
import { useAxiosGet, useAxiosPost, useAxiosPut } from "hooks/useaxios";

const ADMIN_INDUSTRY_API_BASE_PATH = "/costcalculator";

// ================================= Industry APIs =================================

export const useAddIndustryAPI = () => {
  const [callApi, { isLoading, isError, isSuccess }] = useAxiosPost();
  const addIndustryAPI = async (
    data: object,
    config: AxiosRequestConfig<object> = {}
  ) => {
    return callApi(`${ADMIN_INDUSTRY_API_BASE_PATH}/industry/add`, data, config);
  };
  return { addIndustryAPI, isLoading, isError, isSuccess };
};


export const useIndustryListAPI = () => {
  // ** Custom Hooks **
  const [callApi, { isLoading, isError, isSuccess }] = useAxiosGet();

  const getIndustryListApi = async (data: object) => {
    return callApi(`${ADMIN_INDUSTRY_API_BASE_PATH}/industry/get_all`, data);
  };

  return { getIndustryListApi, isLoading, isError, isSuccess };
};


export const useGetIndustryAPI = () => {
  // ** Custom Hooks **
  const [callApi, { isLoading, isError, isSuccess }] = useAxiosGet();

  const getIndustryApi = async (id: number | string) => {
    return callApi(`${ADMIN_INDUSTRY_API_BASE_PATH}/industry/get/${id}`);
  };

  return { getIndustryApi, isLoading, isError, isSuccess };
};


export const useUpdateIndustryAPI = () => {
  const [callApi, { isLoading, isError, isSuccess }] = useAxiosPut();
    const updateIndustryAPI = async (
      data: object,
      id: number | string,
      config: AxiosRequestConfig<object> = {}
    ) => {
      return callApi(`${ADMIN_INDUSTRY_API_BASE_PATH}/industry/update/${id}`, data, config);
    };
  return { updateIndustryAPI, isLoading, isError, isSuccess };
};


export const useDeleteIndustryAPI = () => {
  const [callApi, { isLoading, isError, isSuccess }] = useAxiosPut();
  const deleteIndustryApi = async (id: number | string) => {
    const response = await callApi(
      `${ADMIN_INDUSTRY_API_BASE_PATH}/industry/delete/${id}`,
      {}
    );
    return response;
  };
  return { deleteIndustryApi, isLoading, isError, isSuccess };
};
