// ** packages **
import { useEffect, useRef, useState } from "react";
import { Plus, Search } from "react-feather";
import { debounce } from "lodash";
// ** components **
import Breadcrumbs from "components/Breadcrumbs";
import Button from "components/Theme/Button";
import AddPatientForm from "./AddPatientForm";
import DataTableReact, { TableRefType } from "components/DataTable";
// ** hooks **
import usePatientColumn from "../hooks/usePatientColumn";
// ** services **
import { useAllDeletePatientsAPI, usePatientListAPI } from "../services";
// ** constants **
import { BREAD_CRUMB } from "constants/breadcrumbs.constant";
import usePermission from "hooks/usePermission";
import { PERMISSIONS } from "constants/Permissions.constant";
import {
  PRIVATE_NAVIGATION,
  PUBLIC_NAVIGATION,
} from "constants/navigation.constant";
import { useNavigate } from "react-router-dom";
import SelectMenu, { optionsType } from "components/SelectMenu";
import { NoteOptions } from "modules/DashBoard/sub-modules/Notes";
import NoteSideBar from "modules/DashBoard/sub-modules/Notes/components/NoteSideBar";
import usePatientColumnSidebar from "../hooks/usePatientColumnSidebar";
import PatientAllNotes from "./PatientAllNotes";
import usePatientColumnNewSidebar from "../hooks/usePatientColumnNewSidebar";
import AlertModal from "components/modal/AlertModal";

const PatientTableSideBar = (props: any) => {
  const { setSearchValue, searchData } = props;
  const [openForm, setOpenForm] = useState<boolean>(false);
  const [isSideOpen, setIsSideOpen] = useState<boolean>(false);
  const [selectedPatientIdsArr, setSelectedPatientIdsArr] = useState<number[]>([]);
  const [open, setOpen] = useState(false);
  const [currentID, setCurrentID] = useState<number | string>(0);

  const tableRef = useRef<TableRefType>(null);
  // const columnDefs = usePatientColumnSidebar();
  const { getPatientListApi, isLoading } = usePatientListAPI();
  const { hasPermission } = usePermission();
  const navigate = useNavigate();
  const createPatientPermission = hasPermission(PERMISSIONS.PATIENT.CREATE);
  const createNotesPermission = hasPermission(PERMISSIONS.NOTES.CREATE);
  const hasPatientDeletePermission = hasPermission(PERMISSIONS.PATIENT.DELETE);

  const { allDeletePatientsAPI } = useAllDeletePatientsAPI();

  const getTableData = async (params: {
    page: number;
    perPage: number;
    sortField: string;
    sortOrder: string;
    search: string;
  }) => {
    let tableData = { rowData: [], rowCount: 10 };
    const { data, error } = await getPatientListApi({
      params: {
        page: params.page,
        per_page: params.perPage,
        sort_by: params?.sortField,
        sort_order: params?.sortOrder,
        search: searchData ?? params.search,
      },
    });
    if (data && !error) {
      tableData = {
        rowData: data?.data || [],
        rowCount: data?.count || 0,
      };
    }
    return tableData;
  };

  // const onSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
  //   tableRef.current?.onChange?.(e);
  // };

  useEffect(() => {
    setSearchValue(tableRef);
  }, [tableRef.current]);

  const refreshTable = () => {
    tableRef.current?.refreshData();
  };

  const onSelect = (value: optionsType) => {
    if (value?.title && value?.title?.toLowerCase() !== "patient") {
      navigate(PRIVATE_NAVIGATION.notes.view, {
        state: {
          note_type: value?.title?.toLowerCase(),
          navHighlighter: value?.title?.toLowerCase(),
        },
      });
    }
  };

  const onClose = () => {
    setOpen(false);
    setSelectedPatientIdsArr([]);
    refreshTable();
  };

  const columnDefs = usePatientColumnNewSidebar({
    selectedPatientIdsArr,
    setSelectedPatientIdsArr,
  });

  const onDeleteClickSidebar = async () => {
    setOpen(true);
  };

  const deleteHandler = async () => {
    if (selectedPatientIdsArr?.length) {
      await allDeletePatientsAPI(selectedPatientIdsArr);
      setSelectedPatientIdsArr([]);
      refreshTable();
      navigate("/")
    }
  };


  return (
    <>
    <div>
      {/* <div className="flex md:flex-col items-center md:items-start justify-between mt-[15px] mb-[15px]">
        <div className="flex sm:flex-col sm:items-start pr-[10px] ml-2 gap-2 items-end">
          {createPatientPermission && (
            <Button
              className="btn_with_color_fill"
              onClick={() => {
                setOpenForm(true);
              }}
            >
              Add Patient
            </Button>
          )}
        </div>
      </div>
      {openForm && hasPermission(PERMISSIONS.PATIENT.CREATE) && (
        <AddPatientForm setIsOpen={setOpenForm} refreshData={refreshTable} />
      )} */}

      <div className="content-table-data">
        <div
          className={`reactDataTable__DSD patientListNotes ${
            selectedPatientIdsArr.length ? "data-check" : ""
          }`}
        >
          <DataTableReact
            setTableLength={null}
            ref={tableRef}
            columns={columnDefs}
            loader={isLoading}
            getData={(params: {
              page: number;
              perPage: number;
              sortField: string;
              sortOrder: string;
              search: string;
            }) => getTableData(params)}
          />
        </div>
      </div>
      <AlertModal
          visible={open}
          onClose={onClose}
          onCancel={onClose}
          onSubmit={() => {
            deleteHandler();
            setOpen(false);
          }}
          submitButtonText="Yes"
        >
          <p className="text-[18px] font-Biotif__SemiBold text-textDark text-center w-[300px] max-w-full mx-auto">
            {`Are you sure you want to delete ${
              (selectedPatientIdsArr.length === 1 && "patient ? All your patient's record will be deleted.") ||
              (selectedPatientIdsArr.length > 1 && "patients ? All your patient's record will be deleted.")
            }`}
          </p>
        </AlertModal>
    </div>
    <div
      className={`${
        selectedPatientIdsArr?.length && "p-3 border-t border-black/15"
      }`}
    >
      {selectedPatientIdsArr?.length && hasPatientDeletePermission ? (
        <Button
          className="bg-white text-red-600 border border-red-600 w-48 hover:bg-red-400 hover:text-white hover:border-red-400"
          onClick={onDeleteClickSidebar}
        >
          Delete&nbsp;({selectedPatientIdsArr?.length})
        </Button>
      ) : (
        <></>
      )}
    </div>
  </>
  );
};
export default PatientTableSideBar;
