import Breadcrumbs from "components/Breadcrumbs";
import { BREAD_CRUMB } from "constants/breadcrumbs.constant";
import { useLocation } from "react-router-dom";
import DataTableReact, { isDefaultPromptType, TableRefType } from "components/DataTable";

import Button from "components/Theme/Button";
import { useRef, useState } from "react";

import ServicesColumn from "../hooks/ServicesColumn";
import { AlertModalType, servicesStatusType, isOpenStateType } from "../types";
import { useDeleteServicesAPI, useServicesListAPI, useUpdateServicesAPI } from "../services";
import ServicesForm from "./ServicesForm";
import AlertModal from "components/modal/AlertModal";
import store from "redux/store";
import { Search } from "react-feather";
import { debounce } from "lodash";


const ServicesListPage = () => {
    const { state } = useLocation();
    const tableRef = useRef<TableRefType>(null);
    
    const storeData = store.getState();
    const is_super_user = storeData?.auth?.user?.is_super_administrator;

    const { deleteServicesApi, isLoading: deleteLoading } = useDeleteServicesAPI();
    const { updateServicesAPI, isLoading: isLoadingUpdate } = useUpdateServicesAPI();
    const { getServicesListApi, isLoading } = useServicesListAPI();

    const [servicesStatus, setServicesStatus] = useState<servicesStatusType>({
        visible: false,
        id: null,
        method: null,
        status: null,
        currentState: null,
        lastDbState: null,
    });

    const [isDefaultPrompt, setIsDefaultPrompt] = useState<isDefaultPromptType>({
        id: null,
        isDefault: false,
    });

    const onChangeStatus = async (
        id: string | number,
        method: AlertModalType | null,
        status: string | null,
        isDefault: boolean | null
    ) => {
        setIsDefaultPrompt({ id, isDefault });
        setServicesStatus({
            visible: true,
            id: id,
            method: method,
            status: status,
            currentState: null,
            lastDbState: null,
        });
    };

    const [isOpen, setIsOpen] = useState<isOpenStateType>({
        open: false,
        id: null,
        isDefault: false,
        viewOnly: true,
    });

    const columnDefs = ServicesColumn({ onChangeStatus, setIsOpen });

    const refreshTable = () => {
        tableRef.current?.refreshData();
    };
    
    const onSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        tableRef.current?.onChange?.(e);
    };

    const getTableData = async (params: {
        page: number;
        perPage: number;
        sortField: string;
        sortOrder: string;
        search: string;
      }) => {
        let tableData = { rowData: [], rowCount: 10, has_next: false };
        const { data, error } = await getServicesListApi({
          params: {
            page: params.page,
            per_page: params.perPage,
            sort_by: params?.sortField,
            sort_order: params?.sortOrder,
            search: params.search,
          },
        });
        if (data && !error) {
          tableData = {
            rowData: data?.data || [],
            rowCount: data?.count || 0,
            has_next: data?.has_next,
          };
        }
        return tableData;
      };


    const onClose = () => {
        setServicesStatus({
            id: null,
            visible: false,
            method: null,
            status: null,
            currentState: null,
            lastDbState: null,
        });
        refreshTable();
    };

    const onCancel = async () => {
        setServicesStatus({
            id: null,
            visible: false,
            method: null,
            status: null,
            currentState: null,
            lastDbState: null,
        });
        refreshTable();
    };

    const onSubmit = async () => {
        if (servicesStatus?.id && servicesStatus?.method === "delete") {
            const { error } = await deleteServicesApi(servicesStatus?.id);
            if (!error) {
                setServicesStatus({
                    id: null,
                    visible: false,
                    method: null,
                    status: null,
                    currentState: null,
                    lastDbState: null,
                });
            }
        } else if (servicesStatus?.method === "status") {
            const formData = new FormData();
            if (servicesStatus.status === "Inactive") {
                formData.append('is_deactivate', "True")
            } else {
                formData.append('is_deactivate', "False")
            }
            if (servicesStatus.id) {
                const { data, error } = await updateServicesAPI(formData, servicesStatus?.id);
                if (!error) {
                    setServicesStatus({
                        id: null,
                        visible: false,
                        method: null,
                        status: null,
                        currentState: null,
                        lastDbState: null,
                    });
                }
            }
        }
        refreshTable();
    };

    return (
        <div>
        <Breadcrumbs path={BREAD_CRUMB.AdminServices} />
        <div className="bg-bgWhiteSD rounded-[10px] shadow-raiseShadow p-[24px] sm:p-[12px] pb-[16px] mt-[20px] h-[calc(100dvh-130px)] overflow-y-hidden">
            <div className="reactDataTable__DSD role__permission">
            <div className="flex justify-between">
                <div className="flex md:flex-col items-center md:items-start justify-between mt-[15px] mb-[15px]">
                    <div className="form__group">
                        <div className="field__wrapper mb-0">
                        <div className="field__inner__wrapper field__has__icon icon__position__left">
                            <input
                            className="input__DSD pl-11"
                            type="text"
                            placeholder="Search"
                            onChange={debounce(onSearchChange, 300)}
                            />
                            <div className="icon__wrapper">
                            <Search />
                            </div>
                        </div>
                        </div>
                    </div>
                </div>
                <div className="float-right ">
                    {/* {userAddRole && ( */}
                    <Button
                        className="bg-[#6558F5] text-white "
                        onClick={() =>
                            setIsOpen({
                            open: true,
                            id: null,
                            isDefault: false,
                            viewOnly: false,
                            })
                        }
                    >
                        Add Services
                    </Button>
                    {/* )} */}
                </div>
            </div>
            <DataTableReact
                setTableLength={null}
                ref={tableRef}
                columns={columnDefs}
                getData={(params: {
                    page: number;
                    perPage: number;
                    sortField: string;
                    sortOrder: string;
                    search: string;
                }) => getTableData(params)}
                paginationProps={false}
                loader={isLoading}
            />
            </div>
        </div>

        <ServicesForm
            isOpen={isOpen}
            setIsOpen={setIsOpen}
            refreshTable={refreshTable}
            setServicesStatus={setServicesStatus}
        />
        <AlertModal
            visible={servicesStatus?.visible}
            showCrossIcon
            onSubmit={onSubmit}
            onCancel={onCancel}
            onClose={onClose}
            submitButtonText="Yes"
            submitLoading={
            deleteLoading ||
            isLoadingUpdate
            }
            // secondaryLoading={updateServicesApi?.isLoading}
        >
            <p className="text-md mb-3 flex ">
            {servicesStatus?.method?.includes("draft") ? (
                <span>
                Do you want to save{" "}
                {servicesStatus?.method === "draft update" && "latest"} changes as
                draft?
                </span>
            ) : (
                <span>
                Are you sure you want to{" "}
                {servicesStatus?.method === "delete"
                    ? "delete"
                    : servicesStatus?.status}{" "}
                this Service ?
                </span>
            )}
            </p>
      </AlertModal>
        </div>
    );
};
export default ServicesListPage;