// ** packages **
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

// ** redux **
import { RootStateType } from "redux/store";

const initialState:any = {
  currentStep: 0,
  selectedServices: [],
  answers: {}, // { questionId: selectedOption }
  userDetails: { name: "", email: "", phone: "", zip: "" },
  // estimatorPage: false,
  // userSubmitedDetails: {},
};

const questionnaireSlice = createSlice({
  name: "questionnaire",
  initialState,
  reducers: {
    setSelectedServices: (state, action) => {
        state.selectedServices = action.payload;
    },
    setAnswer: (state, action) => {
      const { questionId, option, allowMultiple, isNumberField} = action.payload;

      if (!state.answers[questionId]) {
        state.answers[questionId] = []; // Initialize as array if not exists
      }

      if (isNumberField) {
        state.answers[questionId] = [option];
      } else if (allowMultiple) {
        const index = state.answers[questionId].indexOf(option);
        if (index === -1) {
          state.answers[questionId].push(option);
        } else {
          state.answers[questionId].splice(index, 1);
        }
      } else {
        // Single selection: Replace previous answer
        state.answers[questionId] = [option]; // Store as an array for consistency
      }
    },
    nextStep: (state) => {
      state.currentStep += 1;
    },
    prevStep: (state) => {
      if (state.currentStep > 0) state.currentStep -= 1;
    },
    setUserDetails: (state, action) => {
      state.userDetails = { ...state.userDetails, ...action.payload };
    },
    resetForm: () => initialState,
  },
});

export const { reducer } = questionnaireSlice;

export const { setSelectedServices, setAnswer, nextStep, prevStep, setUserDetails, resetForm } = questionnaireSlice.actions;

export const getUserCostForm = (state: RootStateType) => state.userCostForm;  

export default questionnaireSlice;

