// ** packages **
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

// ** redux **
import { RootStateType } from "redux/store";

export type CostFormSliceType = {
    currentFormType: string;
    currentStep: string;
    step1: any;
    step2: any;
    step3: any;
    step4: any;
};

const initialState: CostFormSliceType = {
  currentFormType : "",
  currentStep : "",
  step1: {},
  step2: {},
  step3: [],
  step4: [],
};

const slice = createSlice({
  name: "costForm",
  initialState,
  reducers: {
    setCurrentFormType( state: CostFormSliceType, action: PayloadAction<any>) {
      state.currentFormType = action.payload;
    },
    setStep1Data( state: CostFormSliceType, action: PayloadAction<any>) {
      state.step1 = action.payload;
    },
    setStep2Data(state: CostFormSliceType, action: PayloadAction<any>) {
      state.step2 = action.payload;
    },
    setStep3Data(state: CostFormSliceType, action: PayloadAction<any>) {
      state.step3 = action.payload;
    },
    setStep4Data(state: CostFormSliceType, action: PayloadAction<any>) {
      state.step4 = action.payload;
    },
  },
});

export const { reducer } = slice;

export const { 
  setCurrentFormType, 
  setStep1Data, 
  setStep2Data, 
  setStep3Data, 
  setStep4Data } = slice.actions;

export const getCostFormData = (state: RootStateType) =>
  state.costForm;

export default slice;
