// ** Packages **
import { combineReducers } from "@reduxjs/toolkit";

// ** Redux Slices **
import { reducer as authReducer } from "./slices/authSlice";
import { reducer as toastReducer } from "./slices/toastSlice";
import { reducer as commonReducer } from "./slices/commonSlice";
import { reducer as costFormReducer } from "./slices/formSlice";
import { reducer as userFormReducer } from "./slices/userFormSlice";

const rootReducer = combineReducers({
  auth: authReducer,
  commonToast: toastReducer,
  common: commonReducer,
  costForm: costFormReducer,
  userCostForm: userFormReducer,
});

export default rootReducer;
