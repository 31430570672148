import React, { useEffect, useState } from "react";
import { useAddCostFormAPI, useIndustryListForFormAPI, useUpdateCostFormAPI } from "../../../services";
import StepButton from "../StepButton";
import { costFormDataType, industryDataType } from "../../../types";
import InputField from "components/FormField/common/inputField";
// import { Button } from "@headlessui/react";
import Button from "components/Theme/Button";
import { useDispatch, useSelector } from "react-redux";
import { getCostFormData, setStep1Data } from "redux/slices/formSlice";
import { RootStateType } from "redux/store";
import _ from "lodash";
import * as Icon from "react-feather";
import { isOpenStateType } from "modules/Admin/sub-module/AdminCostCalculator/Industry/types";
import IndustryForm from "modules/Admin/sub-module/AdminCostCalculator/Industry/components/IndustryForm";
import CostFormPopup from "../CostFormPopup/CostFormPopup";
import TextAreaField from "components/FormField/common/TextAreaField";
import Note from "components/Note";
import { Editor } from "@tiptap/react";



interface FormStep5Props {
    currentFormStep?: string;
    setCurrentFormStep?: ((value: string) => void) | undefined | any;
    selectedFormData?: costFormDataType | null;
    setSelectedFormData?: any;
    isFormOpen?: any;
    setIsFormOpen?: any;
}


const FormStep5: React.FC<FormStep5Props> = ({
    currentFormStep,
    setCurrentFormStep,
    selectedFormData,
    setSelectedFormData,
    isFormOpen,
    setIsFormOpen
}) => {

    // const currentFormData = useSelector(getCostFormData);
    const dispatch = useDispatch()

    const [isOpen, setIsOpen] = useState(false)

    const [industryData, setIndustryData] = useState<industryDataType[]>([]);
    // const [selectedIndustryId, setSelectedIndustryId] = useState<industryDataType>(currentFormData.step1);
    const [selectedIndustryId, setSelectedIndustryId] = useState<number | any>(selectedFormData?.industry_id);
    const [searchQuery, setSearchQuery] = useState('');

    const [introHeading, setIntroHeading] = useState<string>(selectedFormData?.intro_heading ? selectedFormData?.intro_heading : "Thanks For Completing Our Pricing Estimator!");
    const [introText, setIntroText] = useState<string>(selectedFormData?.intro_text ? selectedFormData?.intro_text : `You're on the brink of gaining clarity on the Asbestos Removal services tailored just for you. Below is your personalized pricing table, crafted from the choices you've made.\n \nThis is your ballpark figure — feel free to tweak your options to see how it influences your estimated cost.`);
    const [explanationContent, setExplanationContent] = useState<string>(selectedFormData?.explanation_content ? selectedFormData?.explanation_content : `<h2>Understanding Your Asbestos Removal Quote</h2><h3>What's Included?</h3><ul><li><p><strong>Comprehensive Site Assessment:</strong> Our team will conduct a thorough inspection of your commercial property to identify areas affected by asbestos and assess the level of risk.</p></li><li><p><strong>Customized Removal Plan:</strong> We create a tailored plan to safely and efficiently remove asbestos, ensuring minimal disruption to your business operations.</p></li><li><p><strong>Safe Removal and Disposal:</strong> Using industry-approved methods, we carefully remove asbestos materials and dispose of them in compliance with environmental regulations.</p></li><li><p><strong>Post-Removal Inspection:</strong> After the removal process, we conduct a final inspection to ensure your property is free from asbestos and safe for occupancy.</p></li></ul><h3>What You Get for Your Money?</h3><ul><li><p><strong>Safety Assurance:</strong> Our services ensure that your commercial space is safe for employees and customers, reducing health risks associated with asbestos exposure.</p></li><li><p><strong>Expertise and Experience:</strong> Benefit from our team's extensive experience and specialized training in asbestos removal, ensuring a job well done.</p></li><li><p><strong>Regulatory Compliance:</strong> We handle all necessary permits and documentation, ensuring your business complies with local and federal regulations.</p></li><li><p><strong>Peace of Mind:</strong> With our professional services, you can focus on your business while we take care of the asbestos removal process.</p></li></ul>`);

    const updateCostFormApi = useUpdateCostFormAPI();


    const industryHandler = (item: industryDataType) => {
        setSelectedIndustryId(item?.id)
        dispatch(setStep1Data(item))
    }

    const handleSubmitFormData = async () => {
        const formData = new FormData();
        if (introHeading) {
            formData.append('intro_heading', introHeading)
        }
        if (introText) {
            formData.append('intro_text', introText)
        }
        if (explanationContent) {
            formData.append('explanation_content', explanationContent)
        }

        if (selectedFormData?.id) {
            const { data, error } = await updateCostFormApi.updateCostFormAPI(
                formData,
                selectedFormData?.id
            );
            setSelectedFormData(data)
        }

    }

    const onSave = async (editor: Editor | null) => {
    if (editor) {
        setExplanationContent(editor?.getHTML()) 
    }
    };

    return (
        <>
            <div className="size-full flex flex-col overflow-y-scroll md:py-5 py-10 md:px-0 px-10">
                <Button
                className="button__DSD w-fit h-9 px-1 mb-5 "
                onClick={() => {
                    setCurrentFormStep("Step 4")
                }}
                >
                <span className="flex items-center gap-2">
                    <Icon.ArrowLeft />
                    Back to Edit Questions
                </span>
                </Button>
                <div className="w-full md:text-2xl text-3xl font-medium mb-4">
                    Edit Your Results Page Content (Step 5 of 5)
                </div>
                <div className="w-full md:text-sm text-base text-gray-500 mb-8">
                    Welcome to the final step! Here, you can customize the text that your users will see after completing the estimator.
                </div>
                <div className="flex-grow flex flex-col gap-4 max-w-[992px] mx-auto w-full">
                    <div className="flex-grow  flex flex-col">
                    <div className="w-full md:text-xl text-2xl font-medium mb-4">
                        Customize
                    </div>
                    <div className="w-full md:text-sm text-base text-gray-500 mb-8">
                        Customize the message that greets your users after they complete the estimate.
                    </div>
                    <div className="bold-label">
                    <InputField
                        fieldWrapperClassName="mb-4"
                        name="introHeading"
                        value={introHeading}
                        label="Intro Heading"
                        labelClass="text-base text-gray-600"
                        placeholder="Thanks For Completing Our Pricing Estimator!"
                        type="text"
                        onChange={(e) => setIntroHeading(e.target.value)}
                    />
                    <TextAreaField
                        id="intro_text"
                        name="intro_text"
                        label="Intro text"
                        // wrapperClass="mb-[-10px]"
                        wrapperClass="mb-4"
                        // register={register}
                        defaultValue={introText}
                        value={introText}
                        placeholder="Intro Text"
                        required
                        // errors={errors.descriptions}
                        className={ "h-28 text-base text-gray-600 mb-2"}
                        onChange={(e) => setIntroText(e.target.value)}
                        // disabled={
                        //     (isOpen?.isDefault && !is_superAdmin) || isOpen?.viewOnly
                        // }
                        />
                        </div>
                    {selectedFormData?.id && (
                        <>
                        <div className="w-full text-md font-semibold text-gray-600">
                            Explanation content :
                        </div>
                        <Note
                            content={explanationContent}
                            isEdit={true}
                            EditorContentClassName="w-full"
                            mainDivClassName="w-full border-b-textSecondary"
                            EditorClassName=""
                            onSave={onSave}
                            contentId={selectedFormData?.id}
                            hasUpdatePermission={true}
                        />
                        </>
                    )}
                    </div>
                    <StepButton
                        prevButtonLable="Prev to Add Question"
                        nextButtonLable="Submit Data"
                        nextButtonDisable={false}
                        handlePrevClick={() => {
                          setCurrentFormStep("Step 4")
                        }}
                        handleNextClick={() => {
                            handleSubmitFormData()
                            setIsOpen(true)
                        }}
                    />
                </div>
            </div>
            {isOpen && (
                <>
                <CostFormPopup
                    isOpen={isOpen}
                    setIsOpen={setIsOpen}
                    selectedFormData={selectedFormData}
                    setSelectedFormData={setSelectedFormData}
                    setIsFormOpen={setIsFormOpen}
                />
                </>
            )}
        </>
    )
}

export default FormStep5
