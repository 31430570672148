// ** packages **
import { useEffect, useMemo, useState } from "react";
import { MoreVertical } from "react-feather";
import { format as Format } from "date-fns";

// ** components **
import TableCellRender from "components/DataTable/components/TableCellRender";
import TippyDropdown from "components/TippyDropDown";
// ** helper **
import { renderOptions } from "components/TippyDropDown/helper";
// **  constant **
import { PERMISSIONS } from "constants/Permissions.constant";
import usePermission from "hooks/usePermission";
import { firstCharUpperCase } from "utils";

// ** store **
import store from "redux/store";
import DateFormat from "components/DateFormat";
import { UserResponseColumnPropsType } from "../types";
import { useNavigate } from "react-router-dom";

const UserResponseColumn = (props: UserResponseColumnPropsType) => {
  const { setIsOpen } = props;

  const { hasPermission } = usePermission();
  const navigate = useNavigate()

  const storeData = store.getState();
  const is_super_user = storeData?.auth?.user?.is_super_administrator;
//   const { hasPermission } = usePermission();
//   const customPromptUpdateRole = hasPermission(
//     PERMISSIONS.CUSTOM_PROMPT.UPDATE
//   );
//   const customPromptDeleteRole = hasPermission(
//     PERMISSIONS.CUSTOM_PROMPT.DELETE
//   );

  const [windowSize, setWindowSize] = useState(window.innerWidth);

  const handleResize = () => {
    if (
      (window.innerWidth > 1100 && windowSize < 1100) ||
      (window.innerWidth < 1100 && windowSize >= 1100)
    ) {
      setWindowSize(window.innerWidth);
    }
  };

  useEffect(() => {
    window.addEventListener("resize", handleResize);

    // Cleanup function to remove event listener
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [windowSize]);

  const columnDefs = useMemo(
    () => [
      windowSize >= 1300 && {
          name: "Name",
          sortField: "user_name",
          // width: "12%",
          selector: (row: { user_name: string }) => row.user_name,
          sortable: true,
          cell: (row: { user_name: string | number; }) => (
            <TableCellRender
              key={row.user_name}
              className="py-2"
              cellRowData={
                <div className="flex items-center gap-2">
                  {row.user_name}
                </div>
              }
            />
          ),
      },
      windowSize >= 1300 && {
          name: "Email",
          sortField: "user_email",
          // width: "12%",
          selector: (row: { user_email: string }) => row.user_email,
          sortable: true,
          cell: (row: { user_email: string | number; }) => (
            <TableCellRender
              key={row.user_email}
              className="py-2"
              cellRowData={
                <div className="flex items-center gap-2">
                  {row.user_email}
                </div>
              }
            />
          ),
      },
      windowSize >= 1300 && {
          name: "Phone",
          // sortField: "name",
          // width: "12%",
          selector: (row: { user_phone: string }) => row.user_phone,
          sortable: false,
          cell: (row: { user_phone: string | number; }) => (
            <TableCellRender
              key={row.user_phone}
              className="py-2"
              cellRowData={
                <div className="flex items-center gap-2">
                  {row.user_phone}
                </div>
              }
            />
          ),
      },
        
      windowSize >= 1300 && {
        name: "Created Date",
        // width: "10%",
        sortField: "created_at",
        selector: (row: { created_at: string }) => row.created_at,
        sortable: true,
        cell: (row: { created_at: string }) => (
          <TableCellRender
            key={row.created_at}
            className="py-2"
            cellRowData={Format(new Date(row.created_at), "MM/dd/yyyy")}
          />
        ),
      },
      windowSize >= 1300 && {
        name: "Action",
        width: "10%",
        // sortField: "created_at",
        selector: (row: { id: number | null }) => row.id,
        sortable: false,
        cell: (row: { id: number | null }) => (
          <TableCellRender
            key={row.id}
            className="py-3 text-blue-500"
            cellRowData={
              <div 
                className="flex items-center cursor-pointer"
                onClick={() => {
                  is_super_user ? navigate(`/admin/costcalculator/form-responses/${row?.id}`) : navigate(`/costcalculator/form-responses/${row?.id}`)
                }}
              >
                View
              </div>
            }
          />
        ),
      },
    ],
    []
  );
  return columnDefs;
};

export default UserResponseColumn;
