// ** packages **
import { useEffect, useRef, useState } from "react";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import { Edit, Eye } from "react-feather";

// ** components **
import { Modal } from "components/Theme/Modal";
import SelectField from "components/FormField/common/SelectField";
import Label from "components/FormField/common/Label";
import InputField from "components/FormField/common/inputField";
import TextAreaField from "components/FormField/common/TextAreaField";

// ** services **
import { useAddServicesAPI, useGetServicesAPI, useIndustryListForServicesAPI, useUpdateServicesAPI } from "../services";

// ** schema **
import { createCustomPromptWithTypeSchema } from "modules/Patient/sub-modules/Summary/schema/createCustomPrompt.validation.schema";

// ** types **
import { currentDraftStatusType, currentStateType, ServicesFormType } from "../types";

import PageLoader from "components/Loaders/PageLoader";

// ** hooks **
import usePermission from "hooks/usePermission";
import { PERMISSIONS } from "constants/Permissions.constant";
import { isEqual } from "lodash";
import { getAuth } from "redux/slices/authSlice";
import { useSelector } from "react-redux";
import MultiSelect, {
  MultiSelectOptionTypes,
} from "components/FormField/common/MultiSelect";
import AlertModal from "components/modal/AlertModal";
import { useNavigate } from "react-router-dom";

import { createServicesTypeSchema } from "../schema";
import { useIndustryListAPI } from "../../Industry/services";
import FileUploadButton from "components/FileUploadButton";

const ServicesForm = (props: ServicesFormType) => {

  const { hasPermission } = usePermission();
  const { user } = useSelector(getAuth);
  const is_superAdmin = user?.is_super_administrator;

  const customPromptUpdateRole = hasPermission(
    PERMISSIONS.CUSTOM_PROMPT.UPDATE
  );

  const { isOpen, setIsOpen, refreshTable, setServicesStatus, industryId } = props;
  
  const getIndustryListApi = useIndustryListForServicesAPI();
  const getServicesApi = useGetServicesAPI();
  const updateServicesApi = useUpdateServicesAPI();
  const addServicesApi = useAddServicesAPI();

  const [servicesImage, setServicesImage] = useState<string | File>("");
  const [fileName, setFileName] = useState<string | null>(null);
  const [selectedIndustryId, setSelectedIndustryId] = useState<any>();
  const [industryData, setIndustryData] = useState<any[]>([]);
  const [servicesData, setServicesData] = useState<any>([]);

  const [isPlaybookCreatePermission, setIsPlaybookCreatePermission] =
    useState<boolean>(false);


  useEffect(() => {
    user?.current_plan?.payment_data?.plan?.specification?.forEach(
      (spec: any) => {
        if (
          spec.name_type?.toLowerCase() === "custom prompt" &&
          spec.limit === "false"
        ) {
          setIsPlaybookCreatePermission(true);
        }
      }
    );
  }, []);


  const getIndustryListApiData = async () => {
    const { data, error } = await getIndustryListApi.getIndustryListApi();
    if (!error && data) {
      // setIndustryData(data.data);
      const industryData = data?.data?.map((industry:any) => ({
        id: industry.id,
        value: industry.name,
        label: industry.name
      }));
      setIndustryData(industryData);
      if (!selectedIndustryId) {
        setSelectedIndustryId(industryData[0])
      }
    }
  }; 

  useEffect(() => { 
      getIndustryListApiData();
  }, [])

  const {
    register,
    handleSubmit,
    setValue,
    getValues,
    reset,
    control,
    formState: { errors },
  } = useForm<any>({
    mode: "all",
    reValidateMode: "onChange",
    resolver: yupResolver(createServicesTypeSchema),
    defaultValues: { name: "" },
  });

    const getServicesApiData = async () => {
      if (isOpen.id) {
        const { data, error } = await getServicesApi.getServicesApi(isOpen.id);
        if (!error && data) {
          setServicesData(data);
          setValue("name", data?.name);
          setFileName(data?.services_image_name)
          setSelectedIndustryId({id : data?.industry?.id, value: data?.industry?.name, label: data?.industry?.name})
        }
      }
    };

    useEffect(() => {
      if (isOpen.id) {
        getServicesApiData()
      }
    }, [isOpen])

    const stateFormReset = (error: any, data: any) => {
        if (!error && data) {
        setIsOpen({ open: false, id: null, isDefault: false, viewOnly: true });
        reset();
        refreshTable && refreshTable();
        }
    };


    const onSubmit = handleSubmit(async (submittedData) => {
        const formData = new FormData();
        for (const [key, value] of Object.entries(submittedData)) {
            formData.append(key, String(value));
        }
        if (servicesImage) {
            formData.append('services_image', servicesImage)
            if (fileName) {
              formData.append('services_image_name', fileName)
            }
        }
        if (industryId) {
          formData.append('industry_id', String(industryId))
        } else {
          if (selectedIndustryId) {
            formData.append('industry_id', selectedIndustryId?.id)
          }
        }
        if (isOpen?.id) {
            const { data, error } = await updateServicesApi.updateServicesAPI(
            formData,
            String(isOpen?.id)
            );
            stateFormReset(error, data);
            setServicesImage("")
            setFileName(null)
        } else {
            const { data, error } = await addServicesApi.addServicesAPI(formData);
            stateFormReset(error, data);
            setServicesImage("")
            setFileName(null)
        }
    });

    const onClose = () => {
      setServicesImage("")
      setFileName(null)
      setIsOpen({
        open: false,
        id: null,
        isDefault: false,
        viewOnly: true,
      });
      reset();
    };

    const [formData, setFormData] = useState({ name: "", image: null });

    const handleChange = (e:any) => {
        const { name, value, files } = e.target;
        setServicesImage(files ? files[0] : value);
        setFileName(files ? files[0]?.name : value?.name)
    };

  return (
    <div className="w-full bold-label">
      {isOpen.open && (
        <Modal
          onSubmit={onSubmit}
          headerName={
            isOpen?.id
              ? isOpen?.viewOnly || (isOpen?.isDefault && !is_superAdmin)
                ? "View Service"
                : "Edit Service"
              : "Add Service"
          }
          modalWidth="800px"
          isLoading={updateServicesApi.isLoading}
          onClose={onClose}
          onCancel={onClose}
        >
          {getServicesApi.isLoading ? (
            <PageLoader pageLoaderClassName="h-[604px]" />
          ) : (
            <>
              <form onSubmit={onSubmit}>
                {customPromptUpdateRole && isOpen?.id && (
                  <div className="flex justify-end">
                    {isOpen?.viewOnly && (
                      <Edit
                        className="text-xs cursor-pointer"
                        onClick={() => {
                          setIsOpen({ ...isOpen, viewOnly: false });
                        }}
                      />
                    )}
                  </div>
                )}
                <InputField
                  name="name"
                  label="Title"
                  register={register}
                  placeholder="Service Title"
                  required
                  type="text"
                  errors={errors.name}
                  disabled={
                    (isOpen?.isDefault && !is_superAdmin) || isOpen?.viewOnly
                  }
                  className={
                    (isOpen?.isDefault && !is_superAdmin) || isOpen?.viewOnly
                      ? "opacity-50"
                      : ""
                  }
                />
                {!industryId &&
                  <div className="form__group">
                    <div className="field__wrapper">
                      <Label required={true} label="Select Industry" />
                      <div
                        className={
                          (isOpen?.isDefault && !is_superAdmin) || isOpen?.viewOnly
                            ? "opacity-50 field__inner__wrapper"
                            : "field__inner__wrapper"
                        }
                      >
                        <SelectField
                          name="defaultValSelect"
                          onChange={(e: any) => {
                            setSelectedIndustryId(e);
                          }}
                          value={selectedIndustryId}
                          options={industryData}
                          disabled={
                            (isOpen?.isDefault && !is_superAdmin) || isOpen?.viewOnly
                          }
                        />
                        <div className="icon__wrapper"></div>
                      </div>
                    </div>
                  </div>
                }
                <div className="form__group">
                  <div className="field__wrapper">
                    <div className="relative w-fit">
                        <FileUploadButton
                          labelName="Service Image"
                          className={`${ isOpen?.viewOnly ? "bg-gray-500 text-white" : "bg-textDark text-white cursor-pointer" }`}
                          fileName={fileName}
                          handleChange={handleChange}
                          disabled={(isOpen?.isDefault && !is_superAdmin) || isOpen?.viewOnly}
                          handleDeleteFile={() => {
                            setFileName(null)
                            setServicesImage("")
                          }}
                        />                      
                    </div>
                  </div>
                </div>
              </form>
            </>
          )}
        </Modal>
      )}
    </div>
  );
};

export default ServicesForm;
