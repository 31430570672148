import React, { useState } from "react";
import Button from "components/Theme/Button";
import { useDispatch, useSelector } from "react-redux";
import { setCurrentFormType } from "redux/slices/formSlice";
import * as Icon from "react-feather";
import { useNavigate } from "react-router-dom";
import CopyToClipboard from "react-copy-to-clipboard";
import { costFormDataType } from "modules/CostCalculator/types";
import { format } from 'date-fns'
import SharePopup from "../SharePopup";
import { getAuth } from "redux/slices/authSlice";
import { DeleteIcon } from "components/Icon/SvgIcon";
import AlertModal from "components/modal/AlertModal";
import { useDeleteCostFormAPI } from "modules/CostCalculator/services";
import { resetForm } from "redux/slices/userFormSlice";
const { REACT_APP_FRONT_URL } = process.env;

interface FormCardProps {
    formDataItem?: costFormDataType; 
    selectedFormData?: any;
    setSelectedFormData?: any; 
    setIsFormOpen?: ((value: boolean) => void) | undefined | any; 
    deleteFormHandler?: any
}


const FormCard: React.FC<FormCardProps> = ({
    formDataItem,
    selectedFormData,
    setSelectedFormData,
    setIsFormOpen,
    deleteFormHandler,
}) => {

    const { user } = useSelector(getAuth);
    const [isOpen, setIsOpen] = useState(false)

    const dispatch = useDispatch()
    const navigate = useNavigate()

    const [alertModelVisible, setAlertModelVisible] = useState<boolean>(false);

    const onEditClickHandler = () => {
        setSelectedFormData(formDataItem)
        setIsFormOpen(true)
        dispatch(setCurrentFormType("Edit"));
    }

    const onListClickHandler = () => {
      if (user?.is_super_administrator) {
        navigate("/admin/costcalculator/form-responses/", { state : {id: formDataItem?.id}})
      } else {
        navigate("/costcalculator/form-responses/", { state : {id: formDataItem?.id}})
      }
    }

    const embedUrl = REACT_APP_FRONT_URL + "/cost/form/" +formDataItem?.code


    return (
        <>  
        {formDataItem?.is_draft ? (
           <>
           <div
             onClick={() => onEditClickHandler()}
             className="w-full bg-white relative border border-indigo-600 max-h-full min-h-[288px] rounded-md flex flex-col items-center justify-center cursor-pointer py-6 px-5 gap-4 hover:shadow-md lg:aspect-video"
           >
              <div
                title="Delete" 
                className="px-2 py-2 rounded-md absolute top-5 right-5 bg-gray-200 hover:bg-red-500 hover:text-white text-red-600"
                onClick={() => setAlertModelVisible(true)}
                >
                <Icon.Trash className="w-4 h-4 "></Icon.Trash>
              </div>
             <div className="w-full text-center text-indigo-600 text-2xl font-medium">
               Countinue Edit Your Form
             </div>
             <div className="w-full flex items-center justify-center text-indigo-600">
               <Icon.ArrowRight />
             </div>
         </div>
         
         </>
        ) : (
          <>
            {formDataItem &&
            <div className="w-full bg-white border border-gray-500 rounded-md flex flex-col items-center justify-center cursor-pointer py-5 px-5 gap-4 hover:shadow-md">
                <div className="flex w-full justify-end">
                  <div className="w-full text-xl font-medium">{formDataItem?.form_name}</div>
                  <div
                    title="Delete" 
                    className="px-2 py-2 rounded-md bg-gray-200 hover:bg-red-500 hover:text-white text-red-600"
                    onClick={() => setAlertModelVisible(true)}
                    >
                    <Icon.Trash className="w-4 h-4 "></Icon.Trash>
                  </div>
                </div>
                <div className="w-full flex flex-col">
                  <div className="w-full grid grid-cols-2 gap-2 py-3 border-b last:border-b-0 border-dashed border-gray-500 items-center">
                    <div className="w-full text-base font-medium">Services</div>
                    <div className="w-full flex items-center justify-end">
                        {formDataItem?.services?.length > 1 ? (
                          <>
                            <span className="w-fit flex items-center justify-center text-xs px-2 py-0.5 rounded-2xl font-medium border border-gray-500 text-gray-500 text-center">
                              {formDataItem?.services[0]?.service?.name} 
                            </span>
                            <span className="w-fit flex items-center justify-center text-xs px-2 py-0.5 mx-1 rounded-2xl font-medium border border-gray-500 text-blue-500 text-center whitespace-nowrap">
                              +  {formDataItem?.services?.length - 1}
                            </span>
                          </>
                        ) : formDataItem?.services?.length === 1 ? (
                          <>
                            <span className="w-fit flex items-center justify-center text-xs px-2 py-0.5 rounded-2xl font-medium border border-gray-500 text-gray-500 text-center">
                                {formDataItem?.services[0]?.service?.name}
                            </span>
                          </>
                        ) : <></>}
                        
                    </div>
                  </div>
                  <div 
                    className="w-full grid grid-cols-2 gap-2 py-3 border-b last:border-b-0 border-dashed border-gray-500 items-center"
                    onClick={() => onListClickHandler()}
                  >
                    <div className="w-full text-base font-medium">
                      Responses
                    </div>
                    <div className="w-full flex items-center justify-end">
                      View
                      {/* <span className="w-fit flex items-center justify-center text-xs px-2 py-0.5 rounded-2xl font-medium border border-gray-500 text-gray-500 text-center">
                        4
                      </span> */}
                    </div>
                  </div>
                  <div className="w-full grid grid-cols-2 gap-2 py-3 border-b last:border-b-0 border-dashed border-gray-500 items-center">
                    <div className="w-full text-base font-medium">
                      Created At
                    </div>
                    <div className="w-full flex items-center justify-end">
                      <span className="w-fit flex items-center justify-center text-xs px-2 py-0.5 rounded-2xl font-medium border border-gray-500 text-gray-500 text-center">
                        {format(new Date(formDataItem?.created_at), 'dd MMM yyyy')}
                      </span>
                    </div>
                  </div>
                </div>
                <div className="w-full flex items-center justify-end gap-3 overflow-hidden">
                  <Button 
                    className="primary__Btn w-fit h-9"
                    onClick={() => {
                      dispatch(resetForm());
                      navigate(`/cost/form/${formDataItem?.code}`)
                    }}
                  >Preview</Button>
                  <Button
                    className="button__DSD text-[#4c60e9] bg-white border border-[#4c60e9] h-9"
                    type="button"
                    onClick={() => onEditClickHandler()}
                  >
                    <div className="btn__text">Edit</div>
                  </Button>
                  {/* <CopyToClipboard text={embedUrl} onCopy={() => setEstimatorLinkCopied(true)}> */}
                    <Button 
                      className="button__DSD text-[#4c60e9] bg-white border border-[#4c60e9] h-9"
                      onClick={() => {setIsOpen(true)}}
                    >Share</Button>
                  {/* </CopyToClipboard> */}
                  
                </div>
              </div>
              }
          </>
         
        )}
        {isOpen && (
          <>
            <SharePopup
              isOpen={isOpen}
              setIsOpen={setIsOpen}
              selectedFormData={formDataItem}
            />
          </>
        )}
        <AlertModal
            visible={alertModelVisible}
            showCrossIcon
            onSubmit={() => {
              deleteFormHandler(formDataItem)
              setAlertModelVisible(false)
            }}
            onCancel={() => setAlertModelVisible(false)}
            onClose={() => setAlertModelVisible(false)}
            submitButtonText="Yes"
            // secondaryLoading={updateServicesApi?.isLoading}
          >
              <p className="text-md mb-3 flex ">
                  <span>
                  Are you sure you want to delete this form ?
                  </span>
              </p>
          </AlertModal>
             
        </>
    )
}

export default FormCard