// ** packages **
import { ServicesSchemaError } from "constants/formErrorMessage.constant";
import * as yup from "yup";

export const createServicesTypeSchema = yup.object().shape({
  name: yup
    .string()
    .trim()
    .min(3, ServicesSchemaError.name_min)
    .required(ServicesSchemaError.name)
    .max(50, ServicesSchemaError.name_max),

});
