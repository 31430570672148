import {
  JSXElementConstructor,
  ReactElement,
  ReactNode,
  ReactPortal,
} from "react";

type tableCellRenderPropsTypes = {
  cellRowData:
    | string
    | number
    | boolean
    | ReactElement<any, string | JSXElementConstructor<any>>
    | Iterable<ReactNode>
    | ReactPortal
    | null
    | undefined;
  className?: string;
};
const TableCellRender = (props: tableCellRenderPropsTypes) => {
  const { cellRowData, className } = props;

  return <div className={className ?? "w-full flex"}>{cellRowData}</div>;
};
export default TableCellRender;
