// ** packages **
import { useContext, useEffect, useRef, useState } from "react";
import DataTableReact, { TableRefType } from "components/DataTable";
// ** hooks **
import useRecordNotesColumn from "../hooks/useRecordNotesColumn";
import { useLocation, useNavigate, useParams } from "react-router-dom";
// ** services **
import { usePatientTranscriptListAPI } from "../services";
import { patientAllNotesTypeProps } from "../sub-modules/PatientDetails/types";
import { useAddAudioRecordingAPI } from "../sub-modules/Recording/Services";
import usePermission from "hooks/usePermission";
import {
  useAllDeleteTranscriptAPI,
  useDeleteTranscriptAPI,
  useRetryTranscriptAPI,
} from "../sub-modules/Transcription/services";
import { debounce } from "lodash";
import { ArrowLeft, ChevronLeft, Mic, Search, Upload } from "react-feather";
import AlertModal from "components/modal/AlertModal";
import { log } from "console";
import Button from "components/Theme/Button";
import { PRIVATE_NAVIGATION } from "constants/navigation.constant";
import { PERMISSIONS } from "constants/Permissions.constant";
import UploadDraggableFile from "modules/MeetingFolder/components/UploadDraggableFile";

const PatientAllNotes = (props: patientAllNotesTypeProps) => {
  const {
    patientId,
    tableRef,
    setSearchValue,
    searchData,
    setIsParamID,
    // setPatientName,
  } = props;
  const [currentRetryId, setCurrentRetryId] = useState<string | number | null>(
    null
  );
  const { hasPermission } = usePermission();
  const [open, setOpen] = useState<boolean>(false);
  const [selectedIdsArr, setSelectedIdsArr] = useState<number[]>([]);
  const [currentID, setCurrentID] = useState<number | string>(0);
  const { retryTranscriptAPI, isLoading: isRetryLoading } =
    useRetryTranscriptAPI();

  const { getPatientTranscriptGetAPI, isLoading } = usePatientTranscriptListAPI(
    patientId ?? ""
  );
  const { deleteTranscriptAPI, isLoading: isDeleteLoading } =
    useDeleteTranscriptAPI();
  const { allDeleteTranscriptAPI } = useAllDeleteTranscriptAPI();
  const navigate = useNavigate();
  const { state } = useLocation();

  const [backIsMic, setBackIsMic] = useState<boolean>(false);
  const [patientName, setPatientName] = useState<string | null>("");

  const hasNoteDeletePermission = hasPermission(PERMISSIONS.NOTES.DELETE);
  const [openUploadFile, setOpenUploadFile] = useState<boolean>(false);

  const getTableData = async (params: {
    page: number;
    perPage: number;
    sortField: string;
    sortOrder: string;
    search: string;
  }) => {
    let tableData = { rowData: [], rowCount: 10 };
    const { data, error } = await getPatientTranscriptGetAPI({
      params: {
        page: params.page,
        per_page: params.perPage,
        sort_by: params?.sortField,
        sort_order: params?.sortOrder,
        search: searchData ?? params.search,
      },
    });
    if (data && !error) {
      tableData = {
        rowData: data?.data || [],
        rowCount: data?.count || 0,
      };
    }

    if (data && !error) {
      setPatientName(data?.username?.name);
    }
    return tableData;
  };

  const refreshTable = () => {
    tableRef.current?.refreshData();
  };

  useEffect(() => {
    if (state?.mode === "patient") {
      refreshTable();
    }
  }, [state]);

  const onDeleteClick = async (id: number | string) => {
    setOpen(true);
    setCurrentID(id);
  };

  const onDeleteSubmit = async (id: number | string) => {
    await deleteTranscriptAPI(id);
    refreshTable();
  };

  const onClose = () => {
    setSelectedIdsArr([]);
    setOpen(false);
    refreshTable();
  };
  const onRetryTranscript = async (id: number | undefined) => {
    if (id) {
      setCurrentRetryId(id);
      await retryTranscriptAPI(id);
      refreshTable();
    }
  };

  const columnDefs = useRecordNotesColumn({
    onRetryTranscript,
    isRetryLoading,
    currentRetryId,
    onDeleteClick,
    selectedIdsArr,
    setSelectedIdsArr,
    patientName,
  });

  useEffect(() => {
    setSearchValue(tableRef);
  }, [tableRef.current]);

  const backButtonHandler = () => {
    if (setIsParamID) {
      setIsParamID({
        meetingId: 0,
        patientId: 0,
      });
    }
    // if (location.pathname.includes("transcription")) {
    //   if (setIsParamID) {
    //     setIsParamID({
    //       meetingId: 0,
    //       patientId: 0,
    //     });
    //   }
    //   navigate(-2);
    // }else if(backIsMic){
    //   if (setIsParamID) {
    //     setIsParamID({
    //       meetingId: 0,
    //       patientId: 0,
    //     });
    //   }
    //   setBackIsMic(false)
    //   navigate(-3);
    // }
    //  else {
    //   if (setIsParamID) {
    //     setIsParamID({
    //       meetingId: 0,
    //       patientId: 0,
    //     });
    //   }
    //   navigate(-1);
    // }
  };

  const onDeleteClickSidebar = () => {
    setOpen(true);
  };

  const deleteHandler = async () => {
    if (selectedIdsArr?.length) {
      await allDeleteTranscriptAPI(selectedIdsArr);
      setSelectedIdsArr([]);
      refreshTable();
      navigate(`/patient/${patientId}`);
    }
  };

  useEffect(() => {
    refreshTable();
  }, [patientId]);

  return (
    <div>
      <div className="flex flex-wrap justify-between mt-4 h-full">
        <div className={"w-full xl:mt-5 h-full"}>
          <div className="flex justify-between align-center flex-col-reverse">
            <div className="flex gap-4 align-center">
              <div
                className="w-[20px] flex items-center cursor-pointer"
                onClick={() => {
                  backButtonHandler();
                }}
              >
                <ChevronLeft />
              </div>
              <Button
                className="text-[#111827] bg-indigo-100 rounded-lg w-[calc(100%-70px)] max-w-full"
                onClick={() =>
                  navigate(
                    PRIVATE_NAVIGATION.patient.detail.view(String(patientId))
                  )
                }
                text_class={
                  "whitespace-nowrap text-ellipsis max-w-[140px] overflow-hidden"
                }
              >
                {patientName}
              </Button>

              <div className="flex lg:flex-wrap gap-4 items-center w-[20px]">
                <Mic
                  onClick={() => {
                    setBackIsMic(true);
                    return navigate(
                      PRIVATE_NAVIGATION.patient.recording(String(patientId))
                    );
                  }}
                  className="microphone w-5 h-5 cursor-pointer"
                />
              </div>
              <div className="flex lg:flex-wrap gap-4 items-center w-[20px]">
                <Upload
                  onClick={() => {
                    setOpenUploadFile(!openUploadFile);
                  }}
                  className="microphone w-5 h-5 cursor-pointer"
                />
              </div>
            </div>
          </div>
          <div className="content-table-data">
            <div
              className={`reactDataTable__DSD patientListNewNotes  ${
                selectedIdsArr.length ? "data-check" : " "
              }`}
            >
              {/* <div className="reactDataTable__DSD patientListNotes "> */}
              <DataTableReact
                setTableLength={null}
                ref={tableRef}
                columns={columnDefs}
                loader={isLoading}
                default_asc={false}
                default_sortColumn="Uploaded"
                getData={(params: {
                  page: number;
                  perPage: number;
                  sortField: string;
                  sortOrder: string;
                  search: string;
                }) => getTableData(params)}
              />
            </div>
          </div>
        </div>
      </div>
      <AlertModal
        visible={open}
        onClose={onClose}
        onCancel={onClose}
        onSubmit={() => {
          deleteHandler();
          setOpen(false);
        }}
        submitButtonText="Yes"
      >
        <p className="text-[18px] font-Biotif__SemiBold text-textDark text-center w-[300px] max-w-full mx-auto">
          {`Are you sure you want to delete ${
            (selectedIdsArr.length === 1 && "Recording") ||
            (selectedIdsArr.length > 1 && "Recordings")
          } ?`}
        </p>
      </AlertModal>

      {openUploadFile && (
        <UploadDraggableFile
          noteID={patientId}
          note_type="patient"
          refreshTable={() => refreshTable()}
          setOpenUploadFile={setOpenUploadFile}
          openUploadFile={openUploadFile}
          name={patientName ? patientName : ""}
        />
      )}

      <div className="p-3 border-t border-black/15 notes__delete">
        {selectedIdsArr?.length && hasNoteDeletePermission ? (
          <Button
            className="bg-white text-red-600 border border-red-600 w-48 hover:bg-red-400 hover:text-white hover:border-red-400"
            onClick={onDeleteClickSidebar}
          >
            Delete&nbsp;({selectedIdsArr?.length})
          </Button>
        ) : (
          <></>
        )}
      </div>
    </div>
  );
};
export default PatientAllNotes;
