import React, { useEffect, useState } from "react";
import { useAddCostFormAPI, useIndustryListForFormAPI, useUpdateCostFormAPI } from "../../../services";
import StepButton from "../StepButton";
import { costFormDataType, industryDataType } from "../../../types";
import InputField from "components/FormField/common/inputField";
// import { Button } from "@headlessui/react";
import Button from "components/Theme/Button";
import { useDispatch, useSelector } from "react-redux";
import { getCostFormData, setStep1Data } from "redux/slices/formSlice";
import { RootStateType } from "redux/store";
import _ from "lodash";
import * as Icon from "react-feather";
import { isOpenStateType } from "modules/Admin/sub-module/AdminCostCalculator/Industry/types";
import IndustryForm from "modules/Admin/sub-module/AdminCostCalculator/Industry/components/IndustryForm";
import { getAuth } from "redux/slices/authSlice";


interface FormStep1Props {
    currentFormStep?: string;
    setCurrentFormStep?: ((value: string) => void) | undefined | any;
    selectedFormData?: costFormDataType | null;
    setSelectedFormData?: any;
    isFormOpen?: any;
    setIsFormOpen?: any;
}


const FormStep1: React.FC<FormStep1Props> = ({
    currentFormStep,
    setCurrentFormStep,
    selectedFormData,
    setSelectedFormData,
    isFormOpen,
    setIsFormOpen
}) => {

    const { user } = useSelector(getAuth);
    const is_superAdmin = user?.is_super_administrator;
    // const currentFormData = useSelector(getCostFormData);
    const dispatch = useDispatch()

    const [industryData, setIndustryData] = useState<industryDataType[]>([]);
    // const [selectedIndustryId, setSelectedIndustryId] = useState<industryDataType>(currentFormData.step1);
    const [selectedIndustryId, setSelectedIndustryId] = useState<number | any>(selectedFormData?.industry_id);
    const [searchQuery, setSearchQuery] = useState('');

    const { getIndustryListApi, isLoading } = useIndustryListForFormAPI();
    const updateCostFormApi = useUpdateCostFormAPI();
    const addCostFormApi = useAddCostFormAPI();

    // ============ Industry State ==========
    const [isOpen, setIsOpen] = useState<isOpenStateType>({
        open: false,
        id: null,
        isDefault: false,
        viewOnly: true,
    });

    const getIndustryListApiData = async (searchQuery: string) => {
        const { data, error } = await getIndustryListApi({
            params: {
              'q' : searchQuery.trim()
            },
          });
        if (!error && data) {
            setIndustryData(data?.data);
        }
    }; 

    const industryHandler = (item: industryDataType) => {
        setSelectedIndustryId(item?.id)
        dispatch(setStep1Data(item))
    }

    const handleSubmitIndustry = async () => {
        const formData = new FormData();
        if (selectedIndustryId) {
            formData.append('industry_id', selectedIndustryId)
        }

        if (selectedIndustryId && selectedFormData === null) {
            if (is_superAdmin) {
                formData.append("is_template", String(true))    
            }
            formData.append("is_draft", String(true))
            const { data, error } = await addCostFormApi.addCostFormAPI(formData);
            setSelectedFormData(data)
        } else {
            if (selectedFormData?.id && selectedFormData?.industry_id !== selectedIndustryId) {
                const { data, error } = await updateCostFormApi.updateCostFormAPI(
                    formData,
                    selectedFormData?.id
                );
                setSelectedFormData(data)
            }
        }

    }
    
    useEffect(() => {
        getIndustryListApiData(searchQuery)
    }, [isOpen])

    const debouncedSearch = _.debounce((searchTerm:any) => {
        getIndustryListApiData(searchTerm);
    }, 500); 

    useEffect(() => {
        debouncedSearch(searchQuery);
        return () => debouncedSearch.cancel();
    }, [searchQuery]);

    return (
        <>
            <div className="size-full flex flex-col overflow-hidden md:py-5 py-10 md:px-0 px-10">
            <Button
                className="button__DSD w-fit h-9 px-1 mb-5 "
                onClick={() => {
                    setSelectedFormData(null)
                    setIsFormOpen(false);
                }}
                >
                <span className="flex items-center gap-2">
                    <Icon.ArrowLeft />
                    Back to Cost Dashboard
                </span>
                </Button>
                <div className="w-full md:text-2xl text-3xl font-medium mb-4">
                    Choose Your Category (Step 1 of 5)
                </div>
                <div className="w-full md:text-sm text-base text-gray-500 mb-8">
                    The AI will use your category to suggest relevant services and for
                    generating the content for your estimator. You can change this later.
                </div>
                <div className="flex-grow flex flex-col gap-4 max-w-[992px] mx-auto w-full overflow-hidden">
                    <div className="flex gap-4 items-center">
                    <span className="text-md font-medium text-gray-700">Select from below or</span>
                    <Button 
                        className="primary__Btn w-fit" 
                        onClick={() =>
                            setIsOpen({
                            open: true,
                            id: null,
                            isDefault: false,
                            viewOnly: false,
                            })
                        }>
                        Add Category
                    </Button>
                    </div>
                    <div className="flex-grow  flex flex-col gap-8 overflow-hidden">
                    <InputField
                        fieldWrapperClassName="mb-0"
                        name="name"
                        label="Begin typing to search your Categories..."
                        placeholder="e.g lawn care"
                        type="text"
                        onChange={(e) => setSearchQuery(e.target.value)}
                    />
                    <div className="w-full max-h-full grid md:grid-cols-1 lg:grid-cols-2 grid-cols-3 gap-4 overflow-auto pr-3">
                        {industryData && industryData.map((item: industryDataType, index) => (
                            <div
                                key={index}
                                className={`w-full group bg-white relative ${ selectedIndustryId === item?.id ? "border-[3px] border-primaryColor" : "border border-gray-500" } rounded-md flex flex-col items-center justify-center cursor-pointer py-8 px-5 gap-8 text-center hover:shadow-md`}
                                onClick={() => industryHandler(item)}
                            >
                                
                                <div className="w-24 h-24 shrink-0 flex items-center justify-center overflow-hidden group-hover:scale-110 transition-all duration-200">
                                    {item?.industry_image ? (
                                        <img
                                        src={item?.industry_image}
                                        alt="img"
                                        className="size-full object-contain object-center"
                                    />
                                    ) : (
                                        <>
                                        <svg className="w-16 h-16" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"><path d="M13 2H6a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V9z"></path><polyline points="13 2 13 9 20 9"></polyline></svg>
                                        </>
                                    )}
                                
                                </div>
                                <div
                                    title="Title text title text"
                                    className="w-full text-base font-medium truncate"
                                >
                                    {item && item?.name}
                                </div>
                                <div className="absolute top-3 right-3">
                                    {(item?.created?.email === user?.email || is_superAdmin) &&  (
                                    <button
                                        title="Edit"
                                        className="size-8 flex items-center justify-center shrink-0 border border-gray-500 text-gray-500 rounded"
                                        onClick={() => setIsOpen({
                                            open: true,
                                            id: Number(item?.id),
                                            isDefault: false,
                                            viewOnly: false,
                                            })
                                        }
                                    >
                                        <Icon.Edit className="size-4" />
                                    </button> 
                                    )}
                                </div>
                            </div>       
                        ))}
                        {industryData.length === 0 && (
                            <div className="text-center w-full col-span-3">
                                <img className="mx-auto mb-5 w-full max-w-[150px]" src="/images/no-data-found.gif" />
                                <p className="text-center"> No Industry Found</p>
                            </div>
                        )}
                    </div>
                    </div>
                    <StepButton
                        prevButtonVisible={true}
                        // selectedIndustryId={selectedIndustryId}
                        nextButtonLable="Next : Market"
                        nextButtonDisable={selectedIndustryId ? false : true}
                        handleNextClick={() => {
                            handleSubmitIndustry()
                            setCurrentFormStep("Step 2")
                        }}
                    />
                </div>
            </div>
            <IndustryForm
                isOpen={isOpen}
                setIsOpen={setIsOpen}
            />
        </>
    )
}

export default FormStep1
