import { X } from "react-feather";
import Button from "../Button";
type modalPropsType = {
  modalWidth?: string;
  modalClass?:string;
  modalBodyClass?:string;
  headerName?: string;
  children: React.ReactNode;
  onClose?: () => void;
  onCancel?: () => void;
  onSubmit?: () => void;
  submitButtonName?: string;
  isLoading?: boolean;
};
export const Modal = (props: modalPropsType) => {
  const {
    headerName,
    children,
    onClose,
    onCancel,
    onSubmit,
    submitButtonName,
    isLoading,
    modalWidth = "600px",
    modalClass = "",
    modalBodyClass = "",
  } = props;

  return (
    <div className="modal__wrapper__SD tag__modal">
      <div className="modal__overlay__SD"></div>
      <div
        className={`modal__content__wrapper ${modalClass}`}
        style={{ width: `${modalWidth}` }}
      >
        {headerName ? (
          <div className="modal__header">
            <h3 className="title">{headerName}</h3>
            {onClose ? (
              <button className="modal__close__btn" onClick={() => onClose()}>
                <X />
              </button>
            ) : (
              <></>
            )}
          </div>
        ) : (
          <></>
        )}
        <div className={`modal__body ${modalBodyClass}`}>{children}</div>
        <div className="modal__footer">
          <div className="modal__footer__right flex items-center flex-end">
            {onCancel ? (
              <button
                className="button__DSD bg-[#6558F5]  text-white false "
                onClick={() => onCancel()}
              >
                <div className="btn__text">Cancel</div>
              </button>
            ) : (
              <></>
            )}{" "}
            {onSubmit && (
              <Button
                className="button__DSD bg-[#6558F5] text-white ml-[10px]"
                type="submit"
                loading={isLoading}
                onClick={onSubmit}
              >
                <div className="btn__text">{submitButtonName ? submitButtonName : "Submit"}</div>
              </Button>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
