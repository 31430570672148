// ** hooks **
import { AxiosRequestConfig } from "axios";
import { useAxiosGet, useAxiosPost, useAxiosPut } from "hooks/useaxios";

const ADMIN_MARKET_API_BASE_PATH = "/costcalculator";

// ================================= Market APIs =================================

export const useAddMarketAPI = () => {
  const [callApi, { isLoading, isError, isSuccess }] = useAxiosPost();
  const addMarketAPI = async (
    data: object,
    config: AxiosRequestConfig<object> = {}
  ) => {
    return callApi(`${ADMIN_MARKET_API_BASE_PATH}/market/add`, data, config);
  };
  return { addMarketAPI, isLoading, isError, isSuccess };
};


export const useMarketListAPI = () => {
  // ** Custom Hooks **
  const [callApi, { isLoading, isError, isSuccess }] = useAxiosGet();

  const getMarketListApi = async (data: object) => {
    return callApi(`${ADMIN_MARKET_API_BASE_PATH}/market/get_all`, data);
  };

  return { getMarketListApi, isLoading, isError, isSuccess };
};


export const useGetMarketAPI = () => {
  // ** Custom Hooks **
  const [callApi, { isLoading, isError, isSuccess }] = useAxiosGet();

  const getMarketApi = async (id: number | string) => {
    return callApi(`${ADMIN_MARKET_API_BASE_PATH}/market/get/${id}`);
  };

  return { getMarketApi, isLoading, isError, isSuccess };
};


export const useUpdateMarketAPI = () => {
  const [callApi, { isLoading, isError, isSuccess }] = useAxiosPut();
    const updateMarketAPI = async (
      data: object,
      id: number | string,
      config: AxiosRequestConfig<object> = {}
    ) => {
      return callApi(`${ADMIN_MARKET_API_BASE_PATH}/market/update/${id}`, data, config);
    };
  return { updateMarketAPI, isLoading, isError, isSuccess };
};


export const useDeleteMarketAPI = () => {
  const [callApi, { isLoading, isError, isSuccess }] = useAxiosPut();
  const deleteMarketApi = async (id: number | string) => {
    const response = await callApi(
      `${ADMIN_MARKET_API_BASE_PATH}/market/delete/${id}`,
      {}
    );
    return response;
  };
  return { deleteMarketApi, isLoading, isError, isSuccess };
};
