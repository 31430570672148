// ** packages **
// import { ChevronRight, LogOut, ChevronLeft, Search, Plus } from "react-feather";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Ref, useEffect, useRef, useState } from "react";

// ** components **
// import SidebarNavItem, { IconType } from "./SidebarNavItems";

// ** CSS **
import "./style/sidebar.css";

// ** Redux **
import {
  getSidebarIsCollapse,
  setSidebarIsCollapse,
} from "redux/slices/commonSlice";
// import { getAuth, setLogoutData } from "redux/slices/authSlice";

import usePermission from "hooks/usePermission";
import { debounce } from "lodash";
// import PatientTable from "modules/Patient/components/PatientTable";
import SelectMenu, { optionsType } from "components/SelectMenu";
import { TableRefType } from "components/DataTable";
import PatientTableSideBar from "modules/Patient/components/PatientTableSIdeBar";
import PatientAllNotes from "modules/Patient/components/PatientAllNotes";
// import MeetingTable from "modules/MeetingFolder";
import MeetingSideBar from "modules/MeetingFolder/MeetingSideBar";
import MeetingFolderAllNotes from "modules/MeetingFolder/components/MeetingFolderAllNotes";
import NotesSidebar from "modules/DashBoard/sub-modules/Notes/NotesSidebar";
import Button from "components/Theme/Button";
import { PERMISSIONS } from "constants/Permissions.constant";
import AddMeetingForm from "modules/MeetingFolder/components/AddMeetingForm";
import AddPatientForm from "modules/Patient/components/AddPatientForm";
import { Search } from "react-feather";
import { navItem, noteOptionsType } from "../types";

type Props = {
  sidebarRef: Ref<HTMLDivElement>;
  headerTitle: string;
  profile?: string | null;
  setIsSidebarClicked: React.Dispatch<React.SetStateAction<boolean>>;
  setIsSecondSidebarClicked: React.Dispatch<React.SetStateAction<boolean>>;
  isSidebarClicked: boolean;
  isSecondSidebarClicked: boolean;
};

export const NoteOptionSidebar: noteOptionsType[] = [
  {
    id: 1,
    title: "Patient",
    permission: PERMISSIONS.PATIENT.READ,
  },
  {
    id: 2,
    title: "Content",
    permission: PERMISSIONS.NOTES.READ,
  },
  {
    id: 3,
    title: "Voice",
    permission: PERMISSIONS.NOTES.READ,
  },
  {
    id: 4,
    title: "Meeting",
    permission: PERMISSIONS.NOTES.READ,
  },
];

export type paramIdsType = {
  meetingId: number;
  patientId: number;
};


type OnChangeHandler = (e: React.ChangeEvent<HTMLInputElement>) => void;
type RefreshDataHandler = () => void;

type searchDataType  = {
  current: {
    onChange : OnChangeHandler;
    refreshData: RefreshDataHandler;
  }
}

const Sidebar2 = (props: Props) => {
  const {
    sidebarRef,
    headerTitle,
    profile,
    setIsSidebarClicked,
    isSidebarClicked,
    isSecondSidebarClicked,
    setIsSecondSidebarClicked,
  } = props;

  const { id } = useParams();
  const navigate = useNavigate();
  const sidebarIsCollapse = useSelector(getSidebarIsCollapse);
  const location = useLocation();
  const { hasPermission } = usePermission();
  const createFolderPermission = hasPermission(PERMISSIONS.MEETING.CREATE);
  const createPatientPermission = hasPermission(PERMISSIONS.PATIENT.CREATE);
  const currentPath = location.pathname;

  const [isSideOpen, setIsSideOpen] = useState<boolean>(false);
  const [isParamIDs, setIsParamIDs] = useState<paramIdsType>({
    meetingId: 0,
    patientId: 0,
  });
  const [selectValue, setSelectValue] = useState<string>("");
  const [openForm, setOpenForm] = useState<boolean>(false);
  const [openFormMeeting, setOpenFormMeeting] = useState<boolean>(false);
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const tableRef = useRef<TableRefType>(null);
  const previousRouteRef = useRef<string | null>(null);
  const { state } = useLocation();
 
  useEffect(() => {
    const currentPath = location.pathname;
    if (
      !currentPath.includes("folder") &&
      !currentPath.includes("patient") &&
      !currentPath.includes("transcription")
      // currentPath !== null
    ) {
      previousRouteRef.current = currentPath;
    }

    if (
      currentPath.includes("patient") &&
      !currentPath.includes("transcription") &&
      id
    ) {
      setIsParamIDs({ meetingId: 0, patientId: +id });
    } else if (
      currentPath.includes("folder") &&
      !currentPath.includes("transcription") &&
      id
    ) {
      setIsParamIDs({ patientId: 0, meetingId: +id });
    }
  }, [location]);

  const NoteSecondOption: noteOptionsType[] = NoteOptionSidebar?.filter(
    (item: navItem) => !item.permission || hasPermission(item.permission)
  );

  // If no selectValue, set it to the first option by default
  useEffect(() => {
    if (!selectValue && NoteSecondOption.length > 0) {
      setSelectValue(NoteSecondOption[0].title!.toLowerCase());
    }
  }, [selectValue, NoteSecondOption]);

  useEffect(() => {
    if (state?.note_type) {
      setSelectValue(state?.note_type.toLowerCase());
    }
    // if (state?.mode) {
    //   if (state?.mode === "patient") {
    //     setSelectValue(state?.mode.toLowerCase());
    //     setIsParamIDs({ meetingId: 0, patientId: +state?.id });
    //   }
    //   if (state?.mode === "meeting") {
    //     setSelectValue(state?.mode.toLowerCase());
    //     setIsParamIDs({ meetingId: +state?.id, patientId: 0 });
    //   }
    // }
  }, [state]);

  const onSelect = (value: optionsType) => {
    // uncomment below code if you wanted to stay on the patient/meeting while change the menu and comment setIsParamsIds
    // if(id){
    //   if(location.pathname.includes("patient")){
    //     setIsParamIDs({ meetingId: 0, patientId: +id});
    //   } else if (location.pathname.includes("meeting")) {
    //     setIsParamIDs({ meetingId: +id, patientId: 0});
    //   }
    // }

    // uncomment below code if you wanted to go the previos stored routes
    // if (id) {
    //   const newPath = location.pathname.split("/").slice(0, -1).join("/");
    //   if (previousRouteRef.current) {
    //     navigate(previousRouteRef.current);
    //   } else if (
    //     !["patient", "transcription", "folder"]?.filter((str) =>
    //       newPath.includes(str)
    //     )
    //   ) {
    //     navigate(newPath);
    //   } else {
    //     navigate(PRIVATE_NAVIGATION.dashboard.view);
    //   }
    // }

    const newValue = value?.title?.toLowerCase();
    setSelectValue(newValue!);
    setIsParamIDs({ patientId: 0, meetingId: 0 });
  };

  const [searchValue, setSearchValue] = useState<searchDataType>();
  const [searchData, setSearchData] = useState<string>("");

  const onSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchData(e?.target?.value);
    if (searchValue?.current) {
      searchValue?.current?.onChange?.(e);
    }
  };
  // const backButtonHandler = () => {
  //   if(currentPath.includes("transcription")){
  //   if(currentPath.includes("transcription")){
  //     navigate(-2);
  //   } else {
  //     navigate(-1);
  //   }
  // };

  const refreshTable = () => {
    searchValue?.current?.refreshData();
  };

  return (
    
    <aside className="second-sidebar-aside" ref={sidebarRef}>
      <div className={`menu__wrapper ${sidebarIsCollapse ? "" : "h-full"} `}>
        <div className="flex sm:flex-col items-center sm:items-start justify-between mb-[10px] w-full m-auto">
          <div className="form__group sm:mb-3 w-full">
            <div className="field__wrapper mb-0">
              <div className="field__inner__wrapper field__has__icon icon__position__left">
                <input
                  className="input__DSD pr-3 pl-11"
                  type="text"
                  placeholder="Search"
                  onChange={debounce(onSearchChange, 300)}
                />
                <div className="icon__wrapper">
                  <Search />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="flex gap-4 4xl:flex-col">
          <div className="patient-btn w-full">
            <div className="flex gap-4 xl:flex-col">
              <div className="w-full select__menu">
                <SelectMenu
                  publishingOptions={NoteSecondOption}
                  onSelect={onSelect}
                  title={selectValue}
                  needHover={false}
                  selected={{ id: null, title: selectValue }}
                  wantTickFromStatus={false}
                  wantCheckInTitle={false}
                  onTextClick={() => setIsSideOpen(true)}
                />
              </div>
              {/* {selectValue === "meeting" && isParamID !== 0 && (
              <div className=" w-1/2 max-w-full">
                <div className=" text-[#111827]  ">
                  {isFolderData?.length ? (
                    isFolderData?.map((folder: any, i: number) => {
                      if (folder.id === isParamID) {
                        return (
                          <p
                            key={folder.name}
                            className="whitespace-nowrap max-w-[160px] text-ellipsis overflow-hidden"
                          >
                            {folder.name}
                          </p>
                        );
                      }
                    })
                  ) : (
                    <></>
                  )}
                </div>
              </div>
            )}
            {selectValue === "patient" && isParamID !== 0 && (
              <div className=" ">
                {isPatientData?.length ? (
                  isPatientData?.map((patient: any, i: number) => {
                    if (patient.id === isParamID) {
                      return (
                        <div
                          className="text-[#111827] border border-[#c5ced6] rounded px-4 py-3.5 hover:border-indigo-600 hover:bg-indigo-600 hover:text-white"
                          key={patient.name}
                        >
                          <p key={patient.name}>{patient.name}</p>
                        </div>
                      );
                    }
                  })
                ) : (
                  <></>
                )}
              </div>
            )} */}

              {/* uncomment for folder name but don't forget to back prop drill */}
              {/* {selectValue === "meeting" && isParamIDs?.meetingId !== 0 && (
                <div className="w-full max-w-[calc(50%_-_16px)]">
                  {folderName && (
                    <div
                      className="text-[#111827] flex items-center bg-indigo-100 h-full rounded-lg"
                      key={folderName}
                    >
                      <p
                        className="font-medium text-[14px] truncate text-indigo-700 bg-indigo-100 text-center py-1 px-2 rounded-lg"
                        key={folderName}
                      >
                        {folderName}
                      </p>
                    </div>
                  )}
                </div>
              )} */}
              {/* uncomment for patient name but don't forget to back prop drill */}
              {/* {selectValue === "patient" && isParamIDs?.patientId !== 0 && (
                <div className="w-full max-w-[calc(50%_-_16px)]">
                  {patientName && (
                    <div
                      className="text-[#111827] flex items-center bg-indigo-100 h-full rounded-lg"
                      key={patientName}
                    >
                      <p
                        className="font-medium text-[14px] truncate text-indigo-700 bg-indigo-100 text-center py-1 px-2 rounded-lg"
                        key={patientName}
                      >
                        {patientName}
                      </p>
                    </div>
                  )}
                </div>
              )} */}
            </div>
          </div>
          {/* patient */}
          {createPatientPermission &&
            selectValue === "patient" &&
            isParamIDs?.patientId === 0 && (
              <Button
                className="btn_with_color_fill w-full p-3"
                onClick={() => {
                  setOpenForm(true);
                }}
              >
                Add&nbsp;Patient
              </Button>
            )}
          {openForm && hasPermission(PERMISSIONS.PATIENT.CREATE) && (
            <AddPatientForm
              setIsOpen={setOpenForm}
              refreshData={refreshTable}
            />
          )}

          {/* folder */}
          {/* <div className="flex md:flex-col items-center md:items-start justify-between mt-[15px] mb-[15px]"> */}
          {createFolderPermission &&
            selectValue === "meeting" &&
            isParamIDs?.meetingId === 0 && (
              <div className="flex sm:flex-col items-center sm:items-start gap-2 md:mt-3 w-full ">
                <Button
                  className="btn_with_color_fill !mt-0 p-3 w-full whitespace-nowrap"
                  onClick={() => {
                    setOpenFormMeeting(true);
                  }}
                >
                  Add Folder
                </Button>
              </div>
            )}
          {/* </div> */}
          {openFormMeeting &&
            createFolderPermission &&
            selectValue === "meeting" && (
              <AddMeetingForm
                setIsOpen={setOpenFormMeeting}
                refreshData={refreshTable}
              />
            )}
        </div>
        <>
          {selectValue === "patient" && isParamIDs?.patientId ? (
            <PatientAllNotes
              patientId={isParamIDs?.patientId ? isParamIDs?.patientId : id}
              tableRef={tableRef}
              setSearchValue={setSearchValue}
              searchData={searchData}
              setIsParamID={setIsParamIDs}
              // setPatientName={setPatientName}
            />
          ) : (
            selectValue === "patient" && (
              <PatientTableSideBar
                setSearchValue={setSearchValue}
                searchData={searchData}
              />
            )
          )}
        </>

        <>
          {selectValue === "content" && (
            <NotesSidebar
              currentNoteType="content"
              setSearchValue={setSearchValue}
              searchData={searchData}
            />
          )}
        </>

        <>
          {selectValue === "voice" && (
            <NotesSidebar
              currentNoteType="voice"
              setSearchValue={setSearchValue}
              searchData={searchData}
            />
          )}
        </>

        <>
          {selectValue === "meeting" && isParamIDs?.meetingId ? (
            <MeetingFolderAllNotes
              meetingId={isParamIDs?.meetingId ? isParamIDs?.meetingId : id}
              tableRef={tableRef}
              setSearchValue={setSearchValue}
              searchData={searchData}
              setIsParamID={setIsParamIDs}
            />
          ) : (
            selectValue === "meeting" && (
              <MeetingSideBar
                setSearchValue={setSearchValue}
                searchData={searchData}
              />
            )
          )}
        </>
      </div>
    </aside>
  );
};
export default Sidebar2;
