import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { 
    getUserCostForm,
} from "redux/slices/userFormSlice";
import { useGetPublicFormAPI } from "modules/CostCalculator/services";
import { useParams } from "react-router-dom";
import { costFormDataType, createdType, questionsDataType, servicesDataType} from "modules/CostCalculator/types";
import QuestionPage from "./components/QuestionPage";
import UserDetailPage from "./components/UserDetailPage";
import ServicesPage from "./components/ServicePage";

const UserCostForm = () => {
  const { currentStep } = useSelector(getUserCostForm);
  const { code } = useParams();

  const { getPublicFormApi, isLoading } = useGetPublicFormAPI();
  
  
  const [questionsWithOptions, setQuestionsWithOptions] = useState<questionsDataType[] | undefined | any>([]);
  const [servicesData, setServicesData] = useState<servicesDataType[] | undefined | any>([]);
  const [selectedFormData, setSelectedFormData] = useState<costFormDataType | null>(null);
  const [themeData, setThemeData] = useState<createdType | null>(null);


    const getPublicFormApiData = async () => {        
        if (code) {
            const { data, error } = await getPublicFormApi(code);

            if (!error && data) {
                if (data?.services) {
                  setSelectedFormData(data)
                  setServicesData(data?.services)
                  setThemeData(data?.created)
                }
            }
        }
    }; 
  
  useEffect(() => {
    getPublicFormApiData()
  }, []);

  return (
    <>
      <div
        className="h-full " 
        style={{
            backgroundColor:themeData?.background_color || "#ffffff",
        }}
      >
      
              {currentStep === 0 ? (
                <>
                  <div className="container  px-4 mx-auto cost-calculator-user">
                    <div className="size-full h-screen flex flex-col md:py-5 py-10 md:px-0 px-10">
                        <div className="flex-grow flex flex-col gap-6 max-w-[992px] mx-auto w-full">
                          <ServicesPage
                            servicesData={servicesData}
                            setServicesData={setServicesData}
                            setQuestionsWithOptions={setQuestionsWithOptions}
                            themeData={themeData}
                          />
                          </div>
                      </div>
                  </div>
                </>
          
              ) : currentStep <= questionsWithOptions.length ? (
                <>
                <div className="container h-screen px-4 mx-auto cost-calculator-user">
                  <div className="size-full  flex flex-col md:py-5 py-10 md:px-0 px-10">
                      <div className="flex flex-col gap-6 max-w-[992px] mx-auto w-full">
                        <QuestionPage
                          questionsWithOptions={questionsWithOptions}
                          themeData={themeData}
                        />
                      </div>
                    </div>
                  </div>
                </>
              ) : (
                <>
                  <UserDetailPage
                    selectedFormData={selectedFormData}
                    setSelectedFormData={setSelectedFormData}
                    themeData={themeData}
                  />
                </>
              )}
        
      </div>
    </>
  );
};

export default UserCostForm;