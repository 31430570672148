// ** packages **
import { useRef, useState } from "react";
import { Search } from "react-feather";
import { debounce } from "lodash";

// ** components **
import DataTableReact, {
  TableRefType,
  isDefaultPromptType,
} from "components/DataTable";
import PromptForm from "./PromptForm";
import AlertModal from "components/modal/AlertModal";
import Button from "components/Theme/Button";

// ** hooks **
import PromptColumn from "../hooks/PromptColumn";
import usePermission from "hooks/usePermission";

// ** services **
import {
  useDeletePromptAPI,
  usePromptListAPI,
  useStatusDefaultPromptAPI,
  useStatusPromptAPI,
} from "../services";

// ** types **
import {
  AlertModalType,
  isOpenStateType,
  promptStatusType,
} from "../types/prompt.types";

// ** constant **
import { PERMISSIONS } from "constants/Permissions.constant";
import {
  useDraftCustomPromptAPI,
  useUpdateCustomPromptAPI,
} from "modules/Patient/sub-modules/Summary/services";
import store from "redux/store";
import DraggableTable from "components/DataTable/components/DraggableTable";
import PromptColumnDraggable from "../hooks/PromptColumnDraggable";
import Tab from "components/Tab";

const PromptListPage = () => {
  const tableRef = useRef<TableRefType>(null);
  const { deletePromptApi, isLoading: deleteLoading } = useDeletePromptAPI();
  const { statusPromptApi, isLoading: isLoadingPrompt } = useStatusPromptAPI();
  const { statusDefaultPromptApi, isLoading: isLoadingDefaultPrompt } =
    useStatusDefaultPromptAPI();
  const { hasPermission } = usePermission();
  const draftPromptApi = useDraftCustomPromptAPI();
  const updatePromptApi = useUpdateCustomPromptAPI();
  const [promptStatus, setPromptStatus] = useState<promptStatusType>({
    visible: false,
    id: null,
    method: null,
    status: null,
    currentState: null,
    lastDbState: null,
  });
  const storeData = store.getState();
  const is_super_user = storeData?.auth?.user?.is_super_administrator;
  const [isDefaultPrompt, setIsDefaultPrompt] = useState<isDefaultPromptType>({
    id: null,
    isDefault: false,
  });
  const [currentTab, setCurrentTab] = useState<string>("adminprompt");
  const onChangeStatus = async (
    id: string | number,
    method: AlertModalType | null,
    status: string | null,
    isDefault: boolean | null
  ) => {
    setIsDefaultPrompt({ id, isDefault });
    setPromptStatus({
      visible: true,
      id: id,
      method: method,
      status: status,
      currentState: null,
      lastDbState: null,
    });
  };

  const deletePrompt = async () => {
    if (promptStatus?.id && promptStatus?.method === "delete") {
      const { error } = await deletePromptApi(promptStatus?.id);
      if (!error) {
        setPromptStatus({
          id: null,
          visible: false,
          method: null,
          status: null,
          currentState: null,
          lastDbState: null,
        });
        refreshTable();
      }
    }
  };

  const changePromptStatus = async () => {
    if (promptStatus?.id && promptStatus?.method === "status") {
      if (
        promptStatus?.id === isDefaultPrompt?.id &&
        !is_super_user &&
        isDefaultPrompt?.isDefault
      ) {
        const { error } = await statusDefaultPromptApi(promptStatus?.id);
        if (!error) {
          setPromptStatus({
            id: null,
            visible: false,
            method: null,
            status: null,
            currentState: null,
            lastDbState: null,
          });
          refreshTable();
        }
      } else {
        const { error } = await statusPromptApi(promptStatus?.id);
        if (!error) {
          setPromptStatus({
            id: null,
            visible: false,
            method: null,
            status: null,
            currentState: null,
            lastDbState: null,
          });
          refreshTable();
        }
      }
    }
  };

  const onCancel = async () => {
    if (promptStatus?.method === "draft") {
      if (promptStatus?.id) {
        await deletePrompt();
      }
    } else if (
      promptStatus?.method === "draft update" &&
      promptStatus?.lastDbState
    ) {
      const formData = new FormData();

      for (const [key, value] of Object.entries(promptStatus?.lastDbState)) {
        if (key === "types") {
          formData.append(key, String(promptStatus?.lastDbState?.types?.value));
        } else {
          formData.append(key, String(value));
        }
      }
      await updatePromptApi.updateCustomPromptApi(
        formData,
        String(promptStatus?.id)
      );
    }
    setPromptStatus({
      id: null,
      visible: false,
      method: null,
      status: null,
      currentState: null,
      lastDbState: null,
    });
    refreshTable();
  };
  const onClose = () => {
    setPromptStatus({
      id: null,
      visible: false,
      method: null,
      status: null,
      currentState: null,
      lastDbState: null,
    });
    refreshTable();
  };

  const [isOpen, setIsOpen] = useState<isOpenStateType>({
    open: false,
    id: null,
    isDefault: false,
    viewOnly: true,
  });

  const columnDefs = PromptColumn({
    setIsOpen,
    onChangeStatus,
  });

  const PromptColumnDrag = PromptColumnDraggable({ onChangeStatus, setIsOpen });
  const { getPromptListApi, isLoading } = usePromptListAPI();

  const getTableData = async (params: {
    page: number;
    perPage: number;
    sortField: string;
    sortOrder: string;
    search: string;
  }) => {
    let tableData = { rowData: [], rowCount: 10, has_next: false };
    const { data, error } = await getPromptListApi({
      params: {
        page: params.page,
        per_page: params.perPage,
        sort_by: params?.sortField,
        sort_order: params?.sortOrder,
        search: params.search,
        currentTab: currentTab
      },
    });
    if (data && !error) {
      tableData = {
        rowData: data?.data || [],
        rowCount: data?.count || 0,
        has_next: data?.has_next,
      };
    }
    return tableData;
  };

  const refreshTable = () => {
    tableRef.current?.refreshData();
  };

  const onSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    tableRef.current?.onChange?.(e);
  };
  const tabs: any[] = [
    { name: "Admin Prompt", value: "adminprompt", disabled: false },
    { name: "User Prompt", value: "userprompt", disabled: false},
  ];


  const onSubmit = async () => {
    if (promptStatus?.method === "delete") {
      deletePrompt();
    } else if (
      promptStatus?.method === "draft" ||
      promptStatus?.method === "draft update"
    ) {
      const rawData = promptStatus?.currentState;
      const id = promptStatus?.id;
     

      // if (rawData) {
      //   const formData = new FormData();
      //   for (const [key, value] of Object.entries(rawData)) {
      //     if (key === "types") {
      //       console.log("If *****************************",key,String(value))
      //       formData.append(key, String(rawData?.types?.value));
      //     } else {
      //       console.log("Else *****************************",key,String(value))
      //       formData.append(key, String(value));
      //     }
      //   }


      if (rawData) {
        const formData = new FormData();
        const notes_prompts_types: { value: string }[] = [];
      
        for (const [key, value] of Object.entries(rawData)) {
          if (key === "types" && Array.isArray(value)) {           
      
            value.forEach((type) => {
              if (typeof type === "object" && type?.value) {
                notes_prompts_types.push({ value: type.value }); // Push object with "type" key
              }
            });
          } else {           
            formData.append(key, String(value));
          }
        }
      
        if (notes_prompts_types.length > 0) {
          formData.append("notes_prompts_types", JSON.stringify(notes_prompts_types)); // Append array of objects
        }  
                
        
        if (id) {
          formData.append("id", String(id));
        }        
        
        await draftPromptApi.draftCustomPromptApi(formData);
      }
      setPromptStatus({
        id: null,
        visible: false,
        method: null,
        status: null,
        currentState: null,
        lastDbState: null,
      });
    } else {
      changePromptStatus();
    }
    refreshTable();
  };
  
  return (
    <div className="bg-bgWhiteSD rounded-[10px] shadow-raiseShadow p-[24px] sm:p-[12px] pb-[16px] mt-[20px]">
      <div className="block float-end md:float-none mt-[15px] mb-[15px]">
        {hasPermission(PERMISSIONS.CUSTOM_PROMPT.CREATE) && (
          <div className="px-2">
            <Button
              className="bg-[#6558F5] text-white md:mt-4"
              onClick={() =>
                setIsOpen({
                  open: true,
                  id: null,
                  isDefault: false,
                  viewOnly: false,
                })
              }
            >
              Add Prompt
            </Button>
          </div>
        )}
      </div>
      <div className="flex md:flex-col items-center md:items-start justify-between mt-[15px] mb-[15px]">
        <div className="form__group">
          <div className="field__wrapper mb-0">
            <div className="field__inner__wrapper field__has__icon icon__position__left">
              <input
                className="input__DSD pl-11"
                type="text"
                placeholder="Search"
                onChange={debounce(onSearchChange, 300)}
              />
              <div className="icon__wrapper">
                <Search />
              </div>
            </div>
          </div>
        </div>
      </div>
      
      
      {is_super_user ? (    
         
        <div className="reactDataTable__DSD promptslist__wrapper">
          <Tab
                tabs={tabs}
                currentTabValue={currentTab}
                onChangeTab={setCurrentTab}
            />
          
          {currentTab=="adminprompt" && <DraggableTable
            mainDivClassName="overflow-x-auto w-full"
            setTableLength={null}
            ref={tableRef}
            columns={PromptColumnDrag}
            loader={isLoading}
            getData={(params: {
              page: number;
              perPage: number;
              sortField: string;
              sortOrder: string;
              search: string;
             
            }) => getTableData(params)}
            setIsOpen={setIsOpen}
            onChangeStatus={onChangeStatus}
          /> } 
          
          {currentTab=="userprompt" && 
            
          <DataTableReact
          
          setTableLength={null}
          columns={columnDefs}
          ref={tableRef}
          
          loader={isLoading}
          getData={(params: {
            page: number;
            perPage: number;
            sortField: string;
            sortOrder: string;
            search: string;            
          }) => getTableData(params)}
          // setIsOpen={setIsOpen}
          // onChangeTab={onChangeStatus}
        /> 
            
          }

          {/* <DataTableReact
            setTableLength={null}
            ref={tableRef}
            columns={columnDefs}
            loader={isLoading}
            getData={(params: {
              page: number;
              perPage: number;
              sortField: string;
              sortOrder: string;
              search: string;
            }) => getTableData(params)}
          /> */}
        </div>
      ) : (
        <div className="">
          <DraggableTable
            mainDivClassName="overflow-x-auto w-full"
            setTableLength={null}
            ref={tableRef}
            columns={PromptColumnDrag}
            loader={isLoading}
            getData={(params: {
              page: number;
              perPage: number;
              sortField: string;
              sortOrder: string;
              search: string;

            }) => getTableData(params)}
            setIsOpen={setIsOpen}
            onChangeStatus={onChangeStatus}
          />
        </div>
      )}
      <PromptForm
      
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        refreshTable={refreshTable}
        setPromptStatus={setPromptStatus}
      />
      <AlertModal
        visible={promptStatus?.visible}
        showCrossIcon
        onSubmit={onSubmit}
        onCancel={onCancel}
        onClose={onClose}
        submitButtonText="Yes"
        submitLoading={
          draftPromptApi?.isLoading ||
          deleteLoading ||
          isLoadingDefaultPrompt ||
          isLoadingPrompt
        }
        secondaryLoading={updatePromptApi?.isLoading}
      >
        <p className="text-md mb-3 flex ">
          {promptStatus?.method?.includes("draft") ? (
            <span>
              Do you want to save{" "}
              {promptStatus?.method === "draft update" && "latest"} changes as
              draft?
            </span>
          ) : (
            <span>
              Are you sure you want to{" "}
              {promptStatus?.method === "delete"
                ? "delete"
                : promptStatus?.status}{" "}
              this prompt ?
            </span>
          )}
        </p>
      </AlertModal>
    </div>
  );
};
export default PromptListPage;
