// ** hooks **
import { AxiosRequestConfig } from "axios";
import { useAxiosGet, useAxiosPost, useAxiosPut } from "hooks/useaxios";

const ADMIN_SERVICES_API_BASE_PATH = "/costcalculator";

// ================================= Services APIs =================================

export const useAddServicesAPI = () => {
  const [callApi, { isLoading, isError, isSuccess }] = useAxiosPost();
  const addServicesAPI = async (
    data: object,
    config: AxiosRequestConfig<object> = {}
  ) => {
    return callApi(`${ADMIN_SERVICES_API_BASE_PATH}/services/add`, data, config);
  };
  return { addServicesAPI, isLoading, isError, isSuccess };
};


export const useServicesListAPI = () => {
  // ** Custom Hooks **
  const [callApi, { isLoading, isError, isSuccess }] = useAxiosGet();

  const getServicesListApi = async (data: object) => {
    return callApi(`${ADMIN_SERVICES_API_BASE_PATH}/services/get_all`, data);
  };

  return { getServicesListApi, isLoading, isError, isSuccess };
};


export const useGetServicesAPI = () => {
  // ** Custom Hooks **
  const [callApi, { isLoading, isError, isSuccess }] = useAxiosGet();

  const getServicesApi = async (id: number | string) => {
    return callApi(`${ADMIN_SERVICES_API_BASE_PATH}/services/get/${id}`);
  };

  return { getServicesApi, isLoading, isError, isSuccess };
};


export const useUpdateServicesAPI = () => {
  const [callApi, { isLoading, isError, isSuccess }] = useAxiosPut();
    const updateServicesAPI = async (
      data: object,
      id: number | string,
      config: AxiosRequestConfig<object> = {}
    ) => {
      return callApi(`${ADMIN_SERVICES_API_BASE_PATH}/services/update/${id}`, data, config);
    };
  return { updateServicesAPI, isLoading, isError, isSuccess };
};


export const useDeleteServicesAPI = () => {
  const [callApi, { isLoading, isError, isSuccess }] = useAxiosPut();
  const deleteServicesApi = async (id: number | string) => {
    const response = await callApi(
      `${ADMIN_SERVICES_API_BASE_PATH}/services/delete/${id}`,
      {}
    );
    return response;
  };
  return { deleteServicesApi, isLoading, isError, isSuccess };
};


export const useIndustryListForServicesAPI = () => {
  // ** Custom Hooks **
  const [callApi, { isLoading, isError, isSuccess }] = useAxiosGet();

  const getIndustryListApi = async () => {
    return callApi(`${ADMIN_SERVICES_API_BASE_PATH}/industry/get_all`);
  };

  return { getIndustryListApi, isLoading, isError, isSuccess };
};