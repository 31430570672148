import Breadcrumbs from "components/Breadcrumbs";
import { BREAD_CRUMB } from "constants/breadcrumbs.constant";
import { useLocation, useParams } from "react-router-dom";
import DataTableReact, { isDefaultPromptType, TableRefType } from "components/DataTable";

import Button from "components/Theme/Button";
import { useRef, useState } from "react";

import UserResponseColumn from "../hooks/UserResponseColumn";
import { AlertModalType, estimatorStatusType, isOpenStateType } from "../types";
// import { useDeleteIndustryAPI, useIndustryListAPI, useUpdateIndustryAPI } from "../services";
// import IndustryForm from "./IndustryForm";
import AlertModal from "components/modal/AlertModal";
import store from "redux/store";
import { useUserResponseListAPI } from "../services";
import { costFormDataType } from "modules/CostCalculator/types";
import EstimatorPage from "../../EstimatorPage";
import { getAuth } from "redux/slices/authSlice";
import { useSelector } from "react-redux";

interface UserResponseListPageProps {
    selectedFormData?: costFormDataType | null;
    setSelectedFormData?: any;
    isListPageOpen?: boolean;
    setIsListPageOpen?: any;
}



const UserResponseListPage: React.FC<UserResponseListPageProps> = ({
    selectedFormData, 
    setSelectedFormData,
    isListPageOpen,
    setIsListPageOpen
}) => {
    const { state } = useLocation();
    const { user } = useSelector(getAuth);
    const { id } = useParams();
    const tableRef = useRef<TableRefType>(null);
    const location = useLocation();
    const state_id = location.state?.id;
    
    const storeData = store.getState();
    const is_super_user = storeData?.auth?.user?.is_super_administrator;


    const [isOpen, setIsOpen] = useState<isOpenStateType>({
        open: false,
        id: null,
        isDefault: false,
        viewOnly: true,
    });

    const { getUserResponseListApi, isLoading } = useUserResponseListAPI();

    const columnDefs = UserResponseColumn({ setIsOpen });

    const getTableData = async (params: {
        page: number;
        perPage: number;
        sortField: string;
        sortOrder: string;
        search: string;
      }) => {
        let tableData = { rowData: [], rowCount: 10, has_next: false };
        const { data, error } = await getUserResponseListApi({
          params: {
            page: params.page,
            per_page: params.perPage,
            sort_by: params?.sortField,
            sort_order: params?.sortOrder,
            search: params.search,
            form_id: state_id ? state_id : id,
          },
        });
        if (data && !error) {
          tableData = {
            rowData: data?.data || [],
            rowCount: data?.count || 0,
            has_next: data?.has_next,
          };
        }
        return tableData;
      };

    return (
        <>
            <div>
              <Breadcrumbs path={ is_super_user ? BREAD_CRUMB.AdminCostCalculator : BREAD_CRUMB.CostCalculator} />
              <div className="bg-bgWhiteSD rounded-[10px] shadow-raiseShadow p-[24px] sm:p-[12px] pb-[16px] mt-[20px] h-[calc(100dvh-130px)] overflow-y-hidden">
                <div className="reactDataTable__DSD role__permission">
                  <DataTableReact
                      setTableLength={null}
                      ref={tableRef}
                      columns={columnDefs}
                      getData={(params: {
                          page: number;
                          perPage: number;
                          sortField: string;
                          sortOrder: string;
                          search: string;
                      }) => getTableData(params)}
                      paginationProps={false}
                      loader={isLoading}
                  />
                </div>
              </div>
            </div>
        </>
    );
};
export default UserResponseListPage;