// ** packages **
import { IndustrySchemaError } from "constants/formErrorMessage.constant";
import * as yup from "yup";

export const createIndustryTypeSchema = yup.object().shape({
  name: yup
    .string()
    .trim()
    .min(3, IndustrySchemaError.name_min)
    .required(IndustrySchemaError.name)
    .max(50, IndustrySchemaError.name_max),

});
